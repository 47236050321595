/*
  Created By Muhammad Hassaan
  Date 05 April 2023
*/
import PropTypes from "prop-types";
import MUIButton from "@mui/material/Button";
export default function Button(props: any) {
  const {
    classesNames,
    onClick,
    value,
    disable,
    style,
    endIcon,
    startIcon,
    fullWidth,
    type,
  } = props;
  return (
    <MUIButton
      className={classesNames}
      disabled={disable}
      variant="outlined"
      onClick={onClick}
      style={style}
      endIcon={endIcon}
      startIcon={startIcon}
      fullWidth={fullWidth}
      type={type}
    >
      {value}
    </MUIButton>
  );
}

Button.defaultProps = {
  value: "",
  classesNames: null,
  size: "small",
  variant: "outlined",
  onClick: () => {},
  disable: false,
  fullWidth: false,

  style: {},
  type: "button",
  startIcon: null,
};

Button.propTypes = {
  value: PropTypes.any,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["outlined", "standard",]),
  classesNames: PropTypes.any,
  onClick: PropTypes.func,
  disable: PropTypes.bool,
  fullWidth: PropTypes.bool,
  style: PropTypes.any,
  endIcon: PropTypes.any,
  startIcon: PropTypes.any,
  type: PropTypes.string,
};
