/*
  Created By Muhammad Hassaan
  Date 06 April 2023
*/
import {
  Checkbox,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Select,
  StyledEngineProvider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import { Circle, Close, Search } from "@mui/icons-material";
import { CheckIcon } from "assets/images/FrankIcons";
import classNames from "classnames";
import Button from "ui-components/Button";
import DateRangeFilter from "ui-components/DateRangeFilter";
import SelectField from "ui-components/SelectField";
import InputField from "ui-components/TextFeild";
import { defaultLabelColors } from "utils/constants";

export function Datatable(props: any) {
  const {
    classes,
    show_header,
    searchEnable,
    searchSize,
    rows,
    columns,
    enablePagination,
    // loading_activity,
    // previous_count,
    current_count,
    total_records,
    table_width,
    min_width,
    radioButton,
    checkBox,
    ariaLabel,
    count,
    onRowClick,
    onDoubleRowClick,

    filterByType,
    filterByTypeOptions,

    btn_label,
    btn_disable,
    show_button,
    onButtonClick,
    date_range,

    show_button2,
    btn2_label,
    btn2_disable,
    onButton2Click,

    isSelectAll,

    // hide_sn,
    CustomHeader,
    revert_header_color,
    btn_classes,
    btn_classes2,
    Tableheight,

    onCheckChange,
    CustomHeaderSeach,
  } = props as any;

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      value: "", // search value store in this state
      pageNumber: 1,
      pageLimit: 50,

      rows: props.rows,

      start_date: null,
      end_date: null,
      filter_type: "all_time",

      selectedRowIndex: null,

      selectedRows: props.selectedData || [],

      is_checked_all: false,
    }
  );

  React.useEffect(() => {
    setState({
      rows: props.rows ?? [],
    });
    // eslint-disable-next-line
  }, [JSON.stringify(props.rows)]);
  // eslint-disable-next-line

  React.useEffect(() => {
    if (props.selectedRow) {
      setState({
        selectedRowIndex: props.selectedRow?.index,
      });
    }
    // eslint-disable-next-line
  }, [props.selectedRow]);

  React.useEffect(() => {
    if (props.selectedRows) {
      setState({
        selectedRows: props.selectedRows,
      });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(props.selectedRows)]);

  const onClickRow = (event: any, row: any, index: any) => {
    try {
      if (state.selectedRowIndex === row[0]) {
        setState({
          selectedRowIndex: null,
        });
        onRowClick(event, [], null);

        return;
      }
      setState({
        selectedRowIndex: row[0],
      });
      onRowClick(event, row, index);
    } catch (error) {}
  };
  const onDoubleClickRow = (event: any, row: any, index: any) => {
    try {
      if (state.selectedRowIndex === row[0]) {
        setState({
          selectedRowIndex: null,
        });
        onDoubleRowClick(event, [], null);

        return;
      }
      setState({
        selectedRowIndex: row[0],
      });
      onDoubleRowClick(event, row, index);
    } catch (error) {}
  };

  const handleChangePage = (page: any) => {
    try {
      setState({
        pageNumber: page,
        value: "",
      });
      props.handleUpdateRecords(
        state.start_date as any,
        state.end_date as any,
        page as any,
        state.pageLimit as any,
        state.value as any
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeLimit = (event: any) => {
    try {
      setState({
        pageLimit: event.target.value,
      });
      props.handleUpdateRecords(
        state.start_date as any,
        state.end_date as any,
        state.pageNumber as any,
        event.target.value as any,
        state.value as any
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onRowCheck = (event: any, row: any, index: any) => {
    try {
      let arr = state.selectedRows;
      const isPresent = state.selectedRows?.findIndex(
        (item: any) => item.id === row[0]
      );
      if (isPresent < 0) {
        arr.push({ id: row[0], row, index });
        setState({ selectedRows: arr });
      } else {
        arr.splice(isPresent, 1);
        setState({ selectedRows: arr });
      }
      onCheckChange(arr);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckFromHead = (event: any, values: any) => {
    try {
      let arr = [];
      if (event.target.checked) {
        for (let index = 0; index < values.length; index++) {
          const _row = values[index];
          arr.push({ id: _row[0], row: _row, index });
        }
      } else {
        arr = [];
      }
      onCheckChange(arr, event.target.checked);
      setState({ is_checked_all: event.target.checked, selectedRows: arr });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeDateRange = ({ start_date, end_date }: any): void => {
    setState({
      start_date,
      end_date,
    });
    try {
      props.handleUpdateRecords(
        start_date as any,
        end_date as any,
        state.pageNumber as any,
        state.pageLimit as any,
        state.value as any
      );
    } catch (error) {
      console.log(error);
    }
  };

  const { value, pageLimit, pageNumber, selectedRows } =
    state;
  const handleValue = (valuee: any) => {
    try {
      setState({
        value: valuee,
      });
    } catch (err) {
      console.log(err, ".");
    }
  };
  // const DataTable = () => {
  return (
    <StyledEngineProvider injectFirst>
      {CustomHeaderSeach &&
        CustomHeaderSeach(pageNumber, pageLimit, value, handleValue)}
      <Paper style={{ background: "#fff", borderRadius: 5 }}>
        {show_header ? (
          <div style={{ padding: "10px 10px 0px 10px" }}>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              {searchEnable ? (
                <Grid
                  item
                  xs={12}
                  md={!date_range && !filterByType && !show_button ? 3.8 : 2}
                  sx={{
                    marginBottom:
                      !date_range && !filterByType && !show_button ? 2 : 1
                  }}
                >
                  <InputField
                    fullWidth
                    value={value}
                    size={searchSize}
                    variant={"outlined"}
                    placeholder="Search ..."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {value ? (
                            <Close
                              style={{ color: "gray", cursor: "pointer" }}
                              onClick={() =>
                                setState({ value: "", rows: props.rows })
                              }
                            />
                          ) : (
                            <Search style={{ color: "gray" }} />
                          )}
                        </InputAdornment>
                      ),
                      className: classNames(
                        classes?.colored_input_feild,
                        "Roboto-Medium"
                      ),
                    }}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      const filtered_rows = props?.rows?.filter((item: any) =>
                        item?.some((_row: any) =>
                          _row?.name
                            ? `${_row.name}`
                                ?.toLowerCase()
                                ?.indexOf(value?.toLowerCase()) !== -1
                            : `${_row}`
                                ?.toLowerCase()
                                ?.indexOf(value?.toLowerCase()) !== -1
                        )
                      );
                      setState({
                        value,
                        rows: value ? filtered_rows : props.rows,
                      });
                      //   handleSearch(e);
                    }}
                    inputProps={{
                      className: classNames(
                        classes?.colored_input_feild,
                        "Roboto-Medium"
                      ),
                    }}
                    classes={classes}
                  />
                </Grid>
              ) : null}

              {date_range ? (
                <Grid sm={6} md={2.5} item>
                  <DateRangeFilter
                    disableClearable={true}
                    placeholder={"Filter Type"}
                    onChange={handleChangeDateRange}
                  />
                </Grid>
              ) : (
                <Grid
                  sm={6}
                  md={2.5}
                  item
                  style={{
                    height: 59,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    className={classNames(classes.normal_text, "Roboto-Medium")}
                  >
                    {`${
                      rows.length < 1
                        ? "No Record(s) Found"
                        : `${rows?.length} Record(s) found`
                    }`}
                  </div>
                </Grid>
              )}

              {filterByType && (
                <Grid sm={6} md={2.5} item>
                  <SelectField
                    options={filterByTypeOptions || []}
                    value={filterByTypeOptions[0]}
                    placeholder={"Filter Type"}
                    handleSelect={(event, name, value) =>
                      console.log(event, name, value)
                    }
                    disableClearable={true}
                  />
                </Grid>
              )}

              {show_button && (
                <Grid
                  xs={12}
                  md={
                    filterByType ? 5 : !filterByType && searchEnable ? 7.5 : 9.5
                  }
                  // md={12}
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    // background:"red"
                    paddingBottom: 2,
                  }}
                >
                  {show_button2 && (
                    <Button
                      onClick={onButton2Click}
                      value={btn2_label}
                      disable={btn2_disable}
                      classesNames={
                        btn_classes2
                          ? btn_classes2
                          : classNames(
                              classes.button_width,
                              btn2_disable
                                ? classes.disabled_button
                                : classes.contained_button
                            )
                      }
                      style={{ marginRight: 20 }}
                    />
                  )}
                  <Button
                    onClick={()=>onButtonClick(state?.value,pageNumber, pageLimit)}
                    value={btn_label}
                    disable={btn_disable}
                    // onDoubleClick={()=>{console.log("hellow")}}
                    classesNames={
                      btn_classes
                        ? btn_classes
                        : classNames(
                            classes.button_width,
                            btn_disable
                              ? classes.disabled_button
                              : classes.contained_button
                          )
                    }
                  />
                </Grid>
              )}
            </Grid>
          </div>
        ) : (
          CustomHeader()
        )}
        <TableContainer
          style={
            rows?.length > 10
              ? { height: Tableheight ?? 500, marginTop: 10 }
              : {}
          }
          className="table-scrollbar"
        >
          <Table
            style={
              rows?.length > 0
                ? {
                    width: table_width,
                    margin: 0,
                    marginBottom: 15,
                    borderRadius: 10,
                  }
                : { width: table_width, margin: 0 }
            }
            sx={{ minWidth: min_width }}
            aria-label={ariaLabel}
            id={ariaLabel}
            className="table-scrollbar"
          >
            <TableHead
              className={
                revert_header_color
                  ? classes?.background_light_sand
                  : classes?.background_main_orange
              }
              // style={{border:"1px solid red",
              // borderRadius:100
              // }}
            >
              <TableRow style={{ background: "transparent" }}>
                {radioButton ? null : checkBox || isSelectAll ? (
                  <TableCell
                    align={"left"}
                    className={classNames(
                      classes?.normal_text,
                      isSelectAll ? "padding-0" : "padding-10"
                    )}
                    // style={{ width: 33 }}
                    style={{
                      width: 33,
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      color: defaultLabelColors?.color_white,
                      background: "#ee6931 !important",
                    }}
                    // sx={{background:`${defaultLabelColors?.color_white} !important`}}

                    // style={isSelectAll ? { width: 5 } : {}}
                  >
                    {isSelectAll && (
                      <Checkbox
                        style={{ padding: "0px 0px 0px 10px" }}
                        checked={state.is_checked_all}
                        onChange={(e) => handleCheckFromHead(e, rows)}
                        className={classNames(classes?.text_6)}
                        checkedIcon={
                          <CheckIcon classes={classNames(classes.check_icon)} />
                        }
                      />
                    )}
                  </TableCell>
                ) : null}
                {/* {hide_sn && (
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ marginRight: 10 }}>SN</span>
                  </TableCell>
                )} */}
                {/* {columns.length > 0 && */}
                {columns.length > 0 &&
                  columns.map((col: any, index: number) => (
                    <TableCell
                      className={classNames(
                        classes?.table_head,
                        "padding-10",
                        "Raleway-SemiBold"
                      )}
                      style={{
                        borderTopLeftRadius: radioButton && index === 0 ? 10 : 0,
                        borderBottomLeftRadius:    radioButton && index === 0 ? 10 : 0,
                        borderTopRightRadius:
                        index === columns?.length - 1 ? 10 : 0,
                        borderBottomRightRadius:
                         index === columns?.length - 1 ? 10 : 0,
                        color: revert_header_color ? "#000000c7": defaultLabelColors?.color_white,
                      }}
                      key={index}
                      align={col.alignment}
                    >
                      <span style={{ marginRight: 10 }}>{col?.name}</span>
                    </TableCell>
                  ))}
                {columns.length === 0 ? (
                  <TableCell className={"padding-10"} align={"center"}>
                    No Columns Found
                  </TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            {state.rows?.length > 0 ? (
              <TableBody>
                {state.rows?.map((row: any, index: any) => (
                  <TableRow
                    onClick={
                      checkBox
                        ? (event) => onRowCheck(event, row, index)
                        : (event) => onClickRow(event, row, index)
                    }
                    onDoubleClick={(event) =>
                      onDoubleClickRow(event, row, index)
                    }
                    key={index}
                    style={{
                      // backgroundColor: index % 2 === 0 ? "#fff" : "#eeeeee",
                      backgroundColor:
                        index % 2 === 0 ? "#fff" : defaultLabelColors?.light_sand,
                      cursor: "pointer",
                    }}
                    className={classNames(
                      // radioButton
                      //   ? row?.findIndex((a: any) =>
                      //       a?.name
                      //         ? a?.name === selectedRowIndex
                      //         : a === selectedRowIndex
                      //     ) > -1
                      //     ? classes.selected_row
                      //     : classes.unselected_row
                      //   :
                      classes.unselected_row
                    )}
                  >
                    {checkBox === true ? (
                      <TableCell
                        key={index}
                        align={"left"}
                        className={classNames(
                          classes?.normal_text,
                          "padding-0"
                        )}
                        sx={{background:`${defaultLabelColors?.color_white} !important`}}
                      >
                        <Checkbox
                          checked={
                            selectedRows?.findIndex(
                              (i: any) => i.id === row[0] || row[0]?.name
                            ) < 0
                              ? false
                              : true
                          }
                          onClick={(event) => {
                            event.stopPropagation();
                            onRowCheck(event, row, index);
                          }}
                          className={classNames(classes?.text_6)}
                          checkedIcon={
                            <CheckIcon
                              classes={classNames(classes.check_icon)}
                            />
                          }
                          style={{ padding: "0px 0px 0px 10px" }}
                        />
                      </TableCell>
                    ) : null}

                      {/* {radioButton === true ? (
                      <TableCell
                        key={index}
                        align={"left"}
                        style={{
                          padding: "5px 0px 0px 8px",
                        }}
                      >
                        {row?.findIndex((a: any) =>
                          a?.name
                            ? a?.name === selectedRowIndex
                            : a === selectedRowIndex
                        ) > -1 ? (
                          <CheckIcon classes={classNames(classes.check_icon)} />
                        ) : null}
                      </TableCell>
                    ) : null} */}

                      {/* {hide_sn && (
                      <TableCell
                        className={classNames(
                          classes?.normal_text,
                          "padding-10",
                          "Roboto-Medium"
                        )}
                        key={"0.001"}
                        align={"left"}
                      >
                        {index + 1}
                      </TableCell>
                    )} */}

                    {row?.map(
                      (item: any, cellIndex: any) =>
                        !item?.hidden && (
                          <TableCell
                            title={item?.hover ? item?.hover : null}
                            className={classNames(
                              classes?.normal_text,
                              "padding-10",
                              "Roboto-Medium"
                            )}
                            // sx={{}}
                            sx={{
                              borderTopLeftRadius: cellIndex === 0 ? 10 : 0,
                              borderBottomLeftRadius: cellIndex === 0 ? 10 : 0,
                              borderTopRightRadius:
                                cellIndex === row?.length - 1 ? 10 : 0,
                              borderBottomRightRadius:
                                cellIndex === row?.length - 1 ? 10 : 0,
                            }}
                            key={cellIndex}
                            style={item?.style ? item?.style : null}
                            align={item?.alignment ? item?.alignment : "left"}
                            onContextMenu={(e: any) => {
                              e.preventDefault();
                              const textToCopy =
                                item?.name || item?.ui?.props?.children || item;

                                if (
                                  navigator.clipboard &&
                                  navigator.clipboard.writeText
                                ) {
                                  // Clipboard API supported
                                  navigator.clipboard
                                    .writeText(textToCopy)
                                    .then(
                                      function () {
                                        alert(
                                          "Copied to clipboard successfully!"
                                        );
                                      },
                                      function (err) {
                                        alert(
                                          `Async: Could not copy text: ${err}`
                                        );
                                      }
                                    );
                                } else {
                                  // Fallback for Safari and other browsers
                                  const tempTextArea =
                                    document.createElement("textarea");
                                  tempTextArea.value = textToCopy;
                                  document.body.appendChild(tempTextArea);
                                  tempTextArea.select();
                                  try {
                                    document.execCommand("copy");
                                    alert("Copied to clipboard successfully!");
                                  } catch (err) {
                                    alert("Could not copy text.");
                                  }
                                  document.body.removeChild(tempTextArea);
                                }

                              // Optional: Hide alert after 3 seconds
                              setTimeout(() => {
                                const alertBox = document.querySelector(
                                  ".alert"
                                ) as HTMLElement | null;

                                if (alertBox) {
                                  alertBox.style.display = "none";
                                }
                              }, 3000);
                            }}
                          >
                            {
                            // eslint-disable-next-line

                            item?.ui ? (
                              // eslint-disable-next-line

                              item?.ui
                              // eslint-disable-next-line

                            ) : (
                              <>
                                {item?.is_bool ? (
                                  <Circle
                                    style={{
                                      color: item?.color || item?.style?.color,
                                      fontSize: 10,
                                      marginRight: 5,
                                    }}
                                  />
                                ) : null}
                                {item?.name ? item?.name : item}
                              </>
                            )}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            ) : revert_header_color ? (
              <TableBody>
                <TableRow className={classNames(classes.unselected_row)}>
                  <TableCell
                    colSpan={columns?.length + 1}
                    align={"center"}
                    style={{
                      padding: "15px 0px",
                    }}
                    className={classNames(
                      classes?.normal_text,
                      "Roboto-Medium"
                    )}
                  >
                    No record(s) found
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={columns?.length + 1}
                    align={"center"}
                    style={{
                      padding: "15px 0px",
                    }}
                    className={classNames(
                      classes?.normal_text,
                      "Roboto-Medium"
                    )}
                  >
                    No record(s) found
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {enablePagination && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: 10,
            }}
          >
            <div
              className={classNames(
                classes.pagination_per_page,
                "Roboto-Regular"
              )}
            >
              <Select
                disabled={rows?.length < 1 ? true : false}
                size="small"
                variant="standard"
                disableUnderline
                style={{ padding: 0, margin: 0, width: 100 }}
                className={classNames(classes.color_primary, "Roboto-Regular")}
                value={pageLimit}
                onChange={handleChangeLimit}
                sx={{
                  "& .MuiSelect-select": {
                    padding: 0,
                    color: "#ee6931",
                    fontSize: 14,
                  },
                  "& svg": {
                    fill: "#ee6931",
                    opacity: 0.8,
                  },
                }}
              >
                <MenuItem
                  className={classNames(
                    classes.normal_text,
                    "Roboto-Regular",
                    "padding-10"
                  )}
                  value={1}
                >
                  1 / page
                </MenuItem>
                <MenuItem
                  className={classNames(
                    classes.normal_text,
                    "Roboto-Regular",
                    "padding-10"
                  )}
                  value={10}
                >
                  10 / page
                </MenuItem>
                <MenuItem
                  className={classNames(
                    classes.normal_text,
                    "Roboto-Regular",
                    "padding-10"
                  )}
                  value={25}
                >
                  25 / page
                </MenuItem>
                <MenuItem
                  className={classNames(
                    classes.normal_text,
                    "Roboto-Regular",
                    "padding-10"
                  )}
                  value={50}
                >
                  50 / page
                </MenuItem>
                <MenuItem
                  className={classNames(
                    classes.normal_text,
                    "Roboto-Regular",
                    "padding-10"
                  )}
                  value={100}
                >
                  100 / page
                </MenuItem>
              </Select>
            </div>
            <Pagination
              count={count ?? 1} // should be total pages
              page={pageNumber}
              onChange={(event, page) => handleChangePage(page)}
              color="primary"
              size={"medium"}
              shape="rounded"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={classNames(classes.pagination_container)}
              variant="outlined"
              showFirstButton
              showLastButton
            />
            &emsp;
            <span className={classNames(classes.normal_text, "Roboto-Regular")}>
              {`${
                rows.length < 1
                  ? "No Record(s) Found"
                  : `Showing ${Number(current_count || 0)} of ${Number(
                      total_records || 0
                    )} Record(s)`
              }`}
            </span>
          </div>
        )}
      </Paper>
    </StyledEngineProvider>
  );
}

Datatable.defaultProps = {
  ariaLabel: "Table",
  table_width: "max-content",
  selectedRowColor: "",
  searchSize: "small",
  count: 1,
  caption: false,
  enablePagination: false,
  radioButton: false,
  searchEnable: true,
  checkBox: false,
  columns: [],
  rows: [],
  filterByTypeOptions: [],
  // loading_activity:false,
  selectedRow: { index: null },
  CaptionBody: () => <caption></caption>,
  onCheckChange: () => {},
  onRowClick: () => {},
  onDoubleRowClick: () => {},

  header_color: false,
  _height: 80,
  search_by_date: false,
  updateRecord: () => {},
  changePagination: () => {},
  min_width: "1350px",
  show_header: true,
  show_button: false,
  onButtonClick: () => {},
  btn_label: "",
  trim_id: false,
  btn_disable: false,
  sortable: true,
  date_range: true,
  filterByType: true,
  dateFormat: "DD-MMM-YYYY",
  classes: {},

  show_button2: false,
  btn2_label: "",
  btn2_disable: false,
  onButton2Click: () => {},
  CustomHeader: () => <></>,

  isSelectAll: false,
  hide_sn: true,
  revert_header_color: false,
  handleUpdateRecords: () => {},
  selectedData: [],
  CustomHeaderSeach: null,
  handleValue: () => {},
};

Datatable.propTypes = {
  classes: PropTypes.any,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  caption: PropTypes.bool,
  enablePagination: PropTypes.bool,
  radioButton: PropTypes.bool,
  searchEnable: PropTypes.bool,
  checkBox: PropTypes.bool,
  CaptionBody: PropTypes.func,
  onCheckChange: PropTypes.func,
  onRowClick: PropTypes.func,
  onDoubleRowClick: PropTypes.func,
  count: PropTypes.number,
  ariaLabel: PropTypes.string,
  searchSize: PropTypes.string,
  table_width: PropTypes.string,
  header_color: PropTypes.bool,
  search_by_date: PropTypes.bool,
  show_header: PropTypes.bool,
  updateRecord: PropTypes.func,
  show_button: PropTypes.bool,
  onButtonClick: PropTypes.func,
  changePagination: PropTypes.func,
  btn_label: PropTypes.string,
  btn_disable: PropTypes.bool,
  sortable: PropTypes.bool,
  dateFormat: PropTypes.string,
  previous_count: PropTypes.any,
  current_count: PropTypes.any,
  total_records: PropTypes.any,
  min_width: PropTypes.any,
  selectedRow: PropTypes.any,
  filterByTypeOptions: PropTypes.any,

  filterByType: PropTypes.bool,
  date_range: PropTypes.bool,

  show_button2: PropTypes.bool,
  btn2_label: PropTypes.string,
  btn2_disable: PropTypes.bool,
  onButton2Click: PropTypes.func,
  isSelectAll: PropTypes.bool,

  hide_sn: PropTypes.bool,
  CustomHeader: PropTypes.any,
  revert_header_color: PropTypes.bool,

  handleUpdateRecords: PropTypes.func,

  selectedData: PropTypes.any,
  Tableheight: PropTypes.any,

  btn_classes: PropTypes.any,
  btn_classes2: PropTypes.any,
  selectedRows: PropTypes.any,
  CustomHeaderSeach: PropTypes.any,
  // loading_activity:PropTypes?.bool,
  handleValue: PropTypes?.func,
};

export default Datatable;
