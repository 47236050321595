import {
  ExpandMore
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  StyledEngineProvider,
} from "@mui/material";
import classNames from "classnames";
import React, { useEffect } from "react";
import apiServices from "services/RequestHandler";
import GoogleAutocompleteField from "ui-components/GoogleAutocompleteField";
import ReactGoogleMapApi from "ui-components/ReactGoogleMapApi";
import InputField from "ui-components/TextFeild";
import Toast from "ui-components/Toast";
import { defaultLabelColors } from "utils/constants";

export default function Other(props: any) {
  const mapKey = "AIzaSyCHQclQrRUyxhjnamOPm4n1z09r-KWmcdQ";
  // const mapKey=  "AIzaSyDck8jd3P-F9dSPmSwzhKkIbCtvL4vzExs";

  const { classes, panel_head, details, shop_edit, id } = props;
  // const dispatch = useDispatch<any>();
  // const miniShop = useSelector((state: RootState) => state.transporterSlice);
  // const vendorSlice = useSelector((state: RootState) => state.vendors);
  // React.useEffect()
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),

    {}
  );

  useEffect(() => {
    setState({
      shop_address: details?.address?.address,
      shop_address_id: details?.address?.id,
      latitude: details?.address?.location?.coordinates[0],
      longitude: details?.address?.location?.coordinates[1],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.details]);
  const handlePlaceSelected = async (place: any) => {
    let pickupLocation = await apiServices.getFromApi(
      `helper/get-address?lat=${place?.geometry?.location?.lat()}&lng=${place?.geometry?.location?.lng()}`,
      ""
    );
    // if(p)
    setState({
      shop_address: pickupLocation?.result[0]?.formattedAddress,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    });
  };
  const handleClickMap = async (latLng: any) => {
    if (latLng) {
      // console.log(`Latitude: ${latLng.lat}, Longitude: ${latLng.lng}`);

      let pickupLocation = await apiServices.getFromApi(
        `helper/get-address?lat=${latLng.lat}&lng=${latLng.lng}`,
        ""
      );
      setState({
        shop_address: pickupLocation?.result[0]?.formattedAddress,
        address_detail: pickupLocation?.result[0],
        latitude: latLng.lat,
        longitude: latLng.lng,
      });
    } else {
      console.log("No coordinates available.");
    }
  };
  const updateAddres = async () => {
    try {
      // console.log(state?.address_detail);
      if (!state?.shop_address_id) {
        return Toast.showError("Id is missing");
      }

      let send_data = {
        id: state?.shop_address_id,
        resource_id: Number(id),
        location: {
          type: "Point",
          coordinates: [
            state?.address_detail?.latitude,
            state?.address_detail?.longitude,
          ],
        },
        address_title: state?.address_detail?.streetName ?? "home",
        address:
          state?.address_detail?.formattedAddress ?? "green town karachi",
        short_address: state?.address_detail?.formattedAddress ?? "green town",
        building: state?.address_detail?.streetName ?? "Hamza",
        door: state?.address_detail?.door ?? "",
        digital_code: state?.address_detail?.zipcode ?? "70120",
        floor: state?.address_detail?.floor ?? "",
        additional_info: state?.address_detail?.streetName ?? "nothing",
        city: state?.address_detail?.city ?? "Karachi",
        country: state?.address_detail?.country ?? "Pakistan",
      };
      const resp = await apiServices.patchFromApi(
        "/c2c-shop-configure/addresses/admin",
        send_data,
        ""
      );
      if (resp.status === 201) {
        Toast.showSuccess("Address updated successfully.");
        props.getMiniShop();
      } else {
        Toast.showError("Something went wrong while updating address.");
        props.getMiniShop();
      }
    } catch (err) {
      console.error("Error Updating shop detail :", err);
      Toast.showError("Something went wrong while processing your request.");
    }
  };
  return (
    <StyledEngineProvider injectFirst>
      <Accordion
        defaultExpanded
        // expanded={
        //   from_vendors ? vendorSlice.panels.status : miniShop.panels.status
        // }
        className={classNames(classes.accordion_container)}
      >
        <AccordionSummary
          className={classNames(classes.accordion_head_container)}
          expandIcon={
            <ExpandMore
              className={classes.accordion_icons}
              style={{ fontSize: 24 }}
            />
          }
        >
          <span
            className={classNames(classes.accordion_heading)}
            style={{ flex: 1 }}
          >
            {panel_head}
          </span>
        </AccordionSummary>

        <AccordionDetails className={classes.accordion_detail_padding}>
          <Grid container spacing={4}>
            <Grid xs={12} md={12} item>
              <InputField
                fullWidth
                value={state?.shop_address ?? "- - - "}
                input_label={"Shop Address"}
                variant={"outlined"}
                multiline
                rows={2}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                  title: details?.shop_desc,
                }}
                classes={classes}
              />
            </Grid>

            {shop_edit === true && (
              <>
                <Grid xs={12} md={12} item>
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 12,
                      padding: 10,
                    }}
                  >
                    <GoogleAutocompleteField
                      placeholder="Enter a location"
                      onPlaceSelected={handlePlaceSelected}
                      key={"originAddress"}
                      styles={{
                        background: "#fff",
                        width: "100%",
                        borderRadius: "4px",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        fontSize: "16px",
                        float: "left",

                        fontFamily: "Roboto-Medium",
                        padding: "10px",
                        zIndex: 1111,
                        color: "#000",
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{ height: "calc(50vh - 120px)", padding: "5px" }}
                    >
                      <ReactGoogleMapApi
                        height={"100%"}
                        width={"100%"}
                        borderRadius={"16px"}
                        lat={state?.latitude}
                        lng={state?.longitude}
                        apiKey={mapKey}
                        handleClick={(latLng: any) => {
                          handleClickMap(latLng);
                        }}
                        show_search={true}
                      />
                    </div>
                  </div>
                </Grid>
              </>
            )}
            <Grid xs={12} md={12} item>
              <div className={classes.justify_end}>
                <Button
                  sx={{ color: defaultLabelColors.main_orange }}
                  className={"Roboto-Medium"}
                  disabled={shop_edit ? false : true}
                  onClick={() => {
                    updateAddres();
                  }}
                >
                  Update
                </Button>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </StyledEngineProvider>
  );
}
