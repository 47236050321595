import axios from "axios";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
import CryptoJS from "crypto-js";

export const BASE_URL = "https://p-server.frankme.com/api/v1/";
// export const BASE_URL = "http://192.168.18.59:4001/api/v1/";
// export const BASE_URL = "http://192.168.18.54:5000/api/v1/";

const secretKey = "FR@NK-FR_2024!";
export const encrypt = (text: string): string => {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
};

export const decrypt = (ciphertext: string): string => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const getApiRequestHeader = async () => {
  let authtoken = getDataFromLocalStorage("user");
  authtoken = authtoken?.token;
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Encrypt": "true", // New apps send this
    Authorization: authtoken ? `Bearer ${authtoken}` : "",
  };
};

let instance = axios.create({
  baseURL: BASE_URL,
  timeout: 6000000,
}) as any;

export const updateHeaders = async () => {
  const header = await getApiRequestHeader();
  instance.defaults.headers = header as any;
};

export const request = async ({ method, url, data, headers }: any) => {
  if (headers === undefined) {
    await updateHeaders();
  }
  const promise = instance[method](url, data);
  let response;
  try {
    response = await promise;
  } catch (error: any) {
    let decryptData = decrypt(error.response?.data);
    decryptData = JSON.parse(decryptData);
    if (typeof decryptData === "string") {
      decryptData = JSON.parse(decryptData);
    }
    return decryptData as any;
    // return error.response?.data;
  }

  let decryptData = decrypt(response?.data);
  decryptData = JSON.parse(decryptData);
  if (typeof decryptData === "string") {
    decryptData = JSON.parse(decryptData);
    // console.log(decryptData);
  }

  return decryptData as any;
};

export const get = (url: string, permission_name: string, config: any) => {
  return request({
    method: "get",
    url,
    data: {},
    ...config,
  });
};

export const post = (
  url: string,
  data: any,
  permission_name: string,  
  config: any
) => {
  let encrypted_data = encrypt(JSON.stringify(data));
  // console.log(data, "post-payload");

  return request({
    method: "post",
    url,
    data: {
      en_data: encrypted_data,
    },
    ...config,
  });
};

export const patch = (
  url: string,
  data: any,
  permission_name: string,
  config: any
) => {
  let encrypted_data = encrypt(JSON.stringify(data));
  // console.log(data, "patch-payload");

  return request({
    method: "patch",
    url,
    data: {
      en_data: encrypted_data,
    },
    ...config,
  });
};
