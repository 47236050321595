/*
  Created By Muhammad Hassaan
  Date 26 April 2022
*/
import React from "react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CalendarTodayOutlined } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";

export default function DatePicker(props: any) {
  const {
    classes,
    input_label,
    required,
    value,
    error,
    textFeildClasses,
    disabled,
    height,
    placeholder,
    readOnly,
    handleDateChange,
    disablePast,
    minDate,
    disableFuture,
    maxDate,
  } = props;
  
  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {input_label && (
        <label
          className={classNames(
            classes.input_label_style,
            "RobotoCondensed-Regular",
            error && classes.color_red
          )}
          style={{ paddingBottom: 0}}
        >
          {input_label}
          {required && <span style={{ color: "red" }}> *</span>}
        </label>
      )}

      <MobileDatePicker
        value={value ? moment(value) : null}
        format="DD/MM/YYYY"
        disablePast={disablePast}
        disableFuture={disableFuture}
        minDate={minDate}
        maxDate={maxDate}
        slotProps={{
          textField: {
            style: {
              background: "white",
            },
            placeholder,
            inputProps: {
              className: textFeildClasses,
              style: {
                height: 0,
                borderRadius:"10px"
              },
            },
            InputProps: {
              endAdornment: (
                <InputAdornment position="end" style={{ background: "white", }}>
                  <CalendarTodayOutlined style={{ fontSize: 24 }} />
                </InputAdornment>
              ),
              style: {
                height,
                borderRadius:10,
              },
              readOnly,
            },
          },
        }}
        sx={{
          borderRadius:"10px"
        }}
        readOnly={readOnly}
        disabled={disabled}
        onChange={() => {}}
        onAccept={handleDateChange}
      />
    </form>
  );
}

DatePicker.defaultProps = {
  value: null,
  input_label: "",
  fullWidth: true,
  required: false,
  error: false,
  classes: {},
  size: "small",
  variant: "outlined",
  textFeildClasses: "",
  disabled: false,
  height: 43,
  placeholder: "",
  readOnly: false,
  handleDateChange: () => {},
  minDate: null,
  maxDate: null,
  disableFuture: false,
};

DatePicker.propTypes = {
  input_label: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  classes: PropTypes.any,
  value: PropTypes.any,
  size: PropTypes.string,
  variant: PropTypes.string,
  textFeildClasses: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.any,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,

  handleDateChange: PropTypes.func,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
};
