import {
  ExpandMore
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  InputAdornment,
  StyledEngineProvider
} from "@mui/material";
import {
  EditPen,
  FrankCloseIcon,
  FrankSaveIcon,
} from "assets/images/FrankIcons";
import classNames from "classnames";
import moment from "moment";
import { useEffect, useReducer } from "react";
import apiServices from "services/RequestHandler";
import SwitchFeild from "ui-components/SwitchFeild";
import WebTimePicker from "ui-components/TimePicker";
import Toast from "ui-components/Toast";
import { defaultLabelColors } from "utils/constants";

export default function ShopTiming(props: any) {
  const { classes, panel_head, details } = props;
  let [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      loader: false,
      hours: [],
    }
  );
  // const weekDays = [
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  //   "Sunday",
  // ];
  useEffect(() => {
    if (details?.shop_timings) {
      setState({
        hours: details.shop_timings.map((item: any) => ({
          ...item, // Spread other properties like id, shop_id, etc.
          opening_time: moment(item.opening_time, "HH:mm").format("HH:mm"),
          closing_time: moment(item.closing_time, "HH:mm").format("HH:mm"),
        })),
      });
    }
  }, [details]);

  // function getDayName(weekDayNumber: any) {
  //   return weekDays[weekDayNumber - 1];
  // }

  // const handleBusinessTiming = (name: string, value: any) => {
  //   setState({
  //     [name]: value,
  //   });
  // };
  const getDayOfWeek = (week_day_number: number) => {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    return days[week_day_number - 1] ?? ""; // Adjust for zero-based index
  };

  const handleChange = (
    event: any,
    name: string,
    index: number,
    type?: string
  ): void => {
    try {
      try {
        let { hours } = state;

        let item: any = {
          ...hours[index],
        };
        if (type === "switch") {
          item["is_close"] = !event.target.checked;
        }
        console.log(moment(event).format("HH:mm"), "check");

        if (type === "time") {
          item[name] = moment(event).format("HH:mm");
        }

        hours[index] = item;
        setState({
          hours,
          send_obj: item,
        });
      } catch (error) {
        console.log(error);
      }
      return;

      // let { hours } = state;
      // console.log(name, index);
      // let new_obj = {
      //   ...hours[index],
      //   ["is_close"]: !event.target.checked,
      // };
      // console.log(new_obj);
      // hours[index] = new_obj;

      // setState({
      //   hours,
      //   send_obj: new_obj,
      // });

      // if (type === "switch") {
      //   new_obj.is_close = !event.target.checked;
      // }
      // hours[index] = new_obj;

      // setState({
      //   hours,
      // });
      // if (type === "time") {
      //   let new_obj = {
      //     ...hours[index],
      //   };
      //   new_obj[name] = moment(event).format("HH:mm"); // Update the time field (either opening_time or closing_time)
      // }

      // hours[index] = new_obj;

      // // console.log();
    } catch (error) {
      console.log(error);
    }
  };
  const updateTiming = async (index: number) => {
    try {
      // let {hours}=state;
      let { hours } = state;

      if (!hours) {
        return Toast.showError("Some went wrong try to refresh.");
      }
      console.log(hours[index]);

      const resp = await apiServices.patchFromApi(
        "/c2c-timings",
        hours[index],
        ""
      );
      if (resp?.status === 201) {
        Toast.showSuccess("Business hours updated successfully.");
        setState({ send_obj: null });
        props.getMiniShop();
      } else {
        Toast.showError("Something went wrong while updating business hours.");
        // getServiceMethod();

        props.getMiniShop();
      }
    } catch (err) {
      console.error("Error updating business hours :", err);
      Toast.showError("Something went wrong while processing your request.");
    }
  };
  return (
    <StyledEngineProvider injectFirst>
      <Accordion
        defaultExpanded
        // expanded={
        //   from_vendors ? vendorSlice.panels.status : miniShop.panels.status
        // }
        className={classNames(classes.accordion_container)}
      >
        <AccordionSummary
          className={classNames(classes.accordion_head_container)}
          expandIcon={
            <ExpandMore
              className={classes.accordion_icons}
              style={{ fontSize: 24 }}
            />
          }
        >
          <span
            className={classNames(classes.accordion_heading)}
            style={{ flex: 1 }}
          >
            {panel_head}
          </span>
        </AccordionSummary>

        <AccordionDetails className={classes.accordion_detail_padding}>
          <Grid xs={12} item>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  marginBottom: 20,
                }}
              >
                <div style={{ flex: 1 }}>
                  {/* <div
                    style={{
                      fontFamily: "Roboto-Medium",
                      color: "black",
                      fontSize: 16,
                    }}
                  >
                    Enable
                  </div> */}
                  <div
                    style={{
                      color: defaultLabelColors?.main_orange,
                      fontFamily: "Raleway-Bold",
                      fontSize: 22,
                    }}
                  >
                    Business Hours
                  </div>
                </div>
              </div>
              <Grid container spacing={2}>
                {state?.hours?.map((item: any, index: number) => (
                  <Grid xs={12} item container spacing={2} key={index}>
                    <Grid
                      xs={4}
                      item
                      className={
                        state?.ParentRow !== index ? classes.disable_box : ""
                      }
                    >
                      <SwitchFeild
                        classes={classes}
                        input_label={getDayOfWeek(item?.week_day_number ?? "")}
                        check={!item.is_close}
                        onChange={(event) =>
                          handleChange(event, `is_closed`, index, "switch")
                        }
                      />
                    </Grid>
                    {item.is_close ? (
                      <>
                        <Grid
                          xs={6}
                          item
                          className={
                            state?.ParentRow !== index
                              ? classes.disable_box
                              : ""
                          }
                        >
                          {/* <div
                            style={{
                              height: 43,
                              background: "#e9e9e9",
                              borderRadius: 6,
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: 20,
                            }}
                          >
                            <Nightlight
                              style={{
                                color: "gray",
                                cursor: "pointer",
                              }}
                            />
                            <span
                              style={{
                                fontFamily: "Roboto-Medium",
                                color: "gray",
                                fontSize: 16,
                                marginLeft: 5,
                              }}
                            >
                              Closed
                            </span>
                          </div> */}
                          <div
                            style={{
                              color: defaultLabelColors?.main_orange,
                              fontFamily: "Raleway-Bold",
                              fontSize: 18,
                              padding: 2,
                            }}
                            className={classes.displayFlex}
                          >
                            Closed
                          </div>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid
                          /*xs={3.5}*/ xs={3}
                          item
                          className={
                            state?.ParentRow !== index
                              ? classes.disable_box
                              : ""
                          }
                        >
                          <WebTimePicker
                            value={
                              moment(`2024-01-01T${item.opening_time}`)
                              // moment(item.opening_time).format("HH:mm")
                            }
                            slotProps={{
                              textField: {
                                InputProps: {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <span
                                        style={{
                                          fontSize: 16,
                                          fontFamily: "Roboto-Regular",
                                        }}
                                      >
                                        From
                                      </span>
                                    </InputAdornment>
                                  ),
                                  style: {
                                    padding: "0px 10px",
                                    height: 43,
                                  },
                                },
                                inputProps: {
                                  style: {
                                    textAlign: "right",
                                    fontFamily: "Roboto-Medium",
                                    fontSize: 16,
                                  },
                                },
                              },
                            }}
                            onChange={(event: any) =>
                              handleChange(event, `opening_time`, index, "time")
                            }
                          />
                        </Grid>
                        <Grid
                          /*xs={3.5}*/ xs={3}
                          item
                          className={
                            state?.ParentRow !== index
                              ? classes.disable_box
                              : ""
                          }
                        >
                          <WebTimePicker
                            value={moment(`2022-01-01T${item.closing_time}`)}
                            slotProps={{
                              textField: {
                                InputProps: {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <span
                                        style={{
                                          fontSize: 16,
                                          fontFamily: "Roboto-Regular",
                                        }}
                                      >
                                        To
                                      </span>
                                    </InputAdornment>
                                  ),
                                  style: {
                                    padding: "0px 10px",
                                    height: 43,
                                  },
                                },
                                inputProps: {
                                  style: {
                                    textAlign: "right",
                                    fontFamily: "Roboto-Medium",
                                    fontSize: 16,
                                  },
                                },
                              },
                            }}
                            onChange={(event: any) =>
                              handleChange(event, `closing_time`, index, "time")
                            }
                            // minTime={moment(`2022-01-01T${item.opening_time}`)}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid xs={2} item>
                      {state?.ParentRow === index ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "min-content",
                              cursor: "pointer",
                              marginRight: 10,
                            }}
                            title="Save"
                            // aria-disabled={state?.verify}
                            onClick={() => {
                              updateTiming(index);
                              setState({
                                ParentRow: null,
                                childRow: null,
                              });
                            }}
                          >
                            <FrankSaveIcon
                              height={20}
                              width={20}
                              stroke={defaultLabelColors.green ?? "gray"}
                              // disable={true}
                            />
                          </div>
                          <div
                            style={{
                              width: "min-content",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setState({
                                ParentRow: null,
                                childRow: null,
                              })
                            }
                            title="Close"
                          >
                            <FrankCloseIcon
                              height={20}
                              width={20}
                              stroke={defaultLabelColors.red}
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "max-content",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setState({
                              ParentRow: index,
                            });
                          }}
                          title="Edit"
                        >
                          <EditPen
                            height={20}
                            width={20}
                            stroke={defaultLabelColors.main_blue}
                          />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </StyledEngineProvider>
  );
}
