import {
  ExpandMore
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid
} from "@mui/material";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import InputField from "ui-components/TextFeild";
import ViewImageModal from "ui-components/ViewImageModal";

export default function Profile(props: any) {
  const { classes, panel_head,  details } = props;
  // const dispatch = useDispatch<any>();
  // const miniShop = useSelector((state: RootState) => state.vendors);
  // const vendorSlice = useSelector((state: RootState) => state.vendors);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      show_image_modal: false,
    }
  );

  return (
    <StyledEngineProvider injectFirst>
      <Accordion
        defaultExpanded
        // expanded={
        //   from_vendors
        //     ? vendorSlice.panels.status
        //     : miniShop.panels.status
        // }
        className={classNames(classes.accordion_container)}
      >
        <AccordionSummary
          className={classNames(classes.accordion_head_container)}
          expandIcon={
            <ExpandMore
              className={classes.accordion_icons}
              style={{ fontSize: 24 }}
              // onClick={() =>
              //   dispatch(
              //     dispatch(
              //       from_vendors
              //         ? toggleStatus(!vendorSlice.panels.status as any)
              //         : handleChangeStatus(!miniShop.panels.status as any)
              //     )
              //   )
              // }
            />
          }
        >
          <span
            className={classNames(classes.accordion_heading)}
            style={{ flex: 1 }}
          >
            {panel_head}
          </span>
          {/* <ContentCopyOutlined
            style={{ marginRight: 10 }}
            className={classNames(classes.accordion_icons)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <PictureAsPdfOutlined
            style={{ marginRight: 10 }}
            className={classNames(classes.accordion_icons)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          /> */}
        </AccordionSummary>

        <AccordionDetails className={classes.accordion_detail_padding}>
          <Grid container spacing={4}>
         
            <Grid xs={12} md={12} item 
            // sx={{display:"flex",justifyContent:"center"}}
            >
            <Grid xs={12} md={6} item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "",
                  alignItems: "",
                  flexDirection: "column",
                }}
              >
                <div>
                  <p
                    style={{
                      color: "#666666",
                      fontFamily: "RobotoCondensed-Regular",
                      paddingLeft: 10,
                      fontSize: "17px",
                    }}
                  >
                    Profile Picture
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: 4,
                  }}
                >
                  <img
                    src={
                      details?.profile_pic
                        ? details?.profile_pic
                        : "https://media.istockphoto.com/id/1131164548/vector/avatar-5.jpg?s=612x612&w=0&k=20&c=CK49ShLJwDxE4kiroCR42kimTuuhvuo2FH5y_6aSgEo="
                    }
                    height={100}
                    width={100}
                    style={{objectFit:"contain"}}

                    alt="not-found"

                  ></img>
                </div>
              </div>
            </Grid>
            </Grid>

            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={`${details?.name ?? "- - -"}`}
                input_label={"Name"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>

            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={details?.title ?? "- - -"}
                input_label={"Gender"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
           

            
            {/* desx */}

           
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={
                  `${details?.phone ?? "- -"}` 
                }
                input_label={"Phone"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                input_label={"Email"}
                value={details?.email ?? "- - -"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={
                  details?.created_at
                    ? moment(details?.created_at).format("DD/MM/YYYY  hh:mm")
                    : "- - -" 
                }
                input_label={"Registered at"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={
                  details?.date_of_birth
                    ? moment(details?.date_of_birth).format("DD/MM/YYYY")
                    : "- - -"
                }
                input_label={"Date of Birth "}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>

            {/* <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={details?.created_at? moment(details?.created_at).format("DD-MM-YYYY"):"- - -"}
                input_label={"Created at"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid> */}

            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={
                  details?.channel ?? "-"
                }
                input_label={"Platform"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            {/* <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={details?.is_shop ?? "- --" ? "Yes " : "No"}
                input_label={"Shop Configure"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid> */}
           
          </Grid>
        </AccordionDetails>
      </Accordion>
      <ViewImageModal
        activity={state.show_image_modal}
        handleCloseModal={() => setState({ show_image_modal: false })}
        image_source={details?.profile_pic ?? ""}
      />
    </StyledEngineProvider>
  );
}
