/*
  Created By Muhammad Hassaan
  Date 10 April 2023
*/
import { makeStyles } from "@mui/styles";
import { defaultLabelColors } from "utils/constants";
const useStyles = makeStyles((theme: any) => ({
  displayFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  align_center: {
    display: "flex",
    alignItems: "center",
  },
  align_end: {
    display: "flex",
    alignItems: "flex-end",
  },
  justify_center: {
    display: "flex",
    justifyContent: "center",
  },
  justify_end: {
    display: "flex",
    justifyContent: "flex-end",
  },
  justify_space_btw: {
    display: "flex",
    justifyContent: "space-between",
  },
  direction_row: {
    flexDirection: "row",
  },
  direction_column: {
    flexDirection: "column",
  },
  display_flex_baseline: {
    display: "flex",
    alignItems: "baseline",
  },

  breadcrumb_container: {
    padding: "20px 20px",
    background: theme.palette.frank.primary,

    // boxShadow:
    //   "0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001f",
    // // position: "fixed",
    // width: "100%",
    // marginBottom: 10,
  },
  breadcrumb_head: {
    color: theme.palette.frank.appPrimary,
    fontFamily: "Raleway-ExtraBold",
    fontSize: 44,
    // fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: 32, // Smaller font size for mobile view
    },
  },

  breadcrumb_time: {
    fontFamily: "Raleway-Variable",
    color: theme.palette.frank.appSecondary,
    fontSize: 24,
    fontWeight: "600",
    lineHeight: "normal",
    letterSpacing: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16, // Smaller font size for mobile view
    },
  },
  breadcrumb_description: {
    fontFamily: "Raleway-Variable",
    color: theme.palette.frank.appSecondary,
    fontSize: 24,
    fontWeight: "600",
    lineHeight: "normal",
    letterSpacing: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16, // Smaller font size for mobile view
    },
  },

  main_layout_padding: {
    padding: "20px 20px 50px 20px",
  },

  colored_input_feild: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.grey2,
    background: "#fff",
    // fontFamily: "Raleway-Variable !important",
    // border:`1px solid ${defaultLabelColors?.main_orange}`

  },
  input_feild: {
    fontSize: 16,
    // fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "normal",
    background: "#fff",
    // color: theme.palette.frank.grey2,
    color:"rgb(0,0,0,0.8)",
    fontFamily: "Raleway-SemiBold",
    // border:`1px solid ${defaultLabelColors?.main_orange}`
  },

  info_table: {
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.primary.color_f,
  },

  background_sand: {
    background: theme.palette.frank.sand,
  },
  background_main_orange: {
    background: theme.palette.frank.appPrimary,
  },


  background_light_gray: {
    background: "#e0e0e0",
  },
  background_light_sand: {
    background: "#FEF5F1",
  },

  table_head: {
    fontSize: 16,
    // fontWeight: "normal",
    fontFamily:"Raleway-SemiBold",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.black,
 
  },

  normal_text: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.black,
  },
  simple_normal_text: {
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.black,
  },
  pagination_per_page: {
    padding: 5,
    borderRadius: 4,
    fontSize: 14,
    border: "1px solid #b2b5b8",
    color:
      // theme.palette.frank.appPrimary
      "red",
    background: "#ffff",
    marginRight: 30,
  },
  color_secondary: {
    color: `${theme.palette.frank.appSecondary} !important`,
  },
  color_primary: {
    color: `${theme.palette.frank.appPrimary} !important`,
  },
  color_white: {
    color: "#fff",
  },
  bg_gray: {
    backgroundColor: "#f6f6f6",
    background: "#f6f6f6",
  },
  primary_divider: {
    borderColor: theme.palette.frank.appPrimary,
    borderBottomWidth: "medium",
  },
  dashboard_primary_divider: {
    border: `2px solid ${theme.palette.frank.appPrimary}`,
  },
  secondary_divider: {
    borderColor: theme.palette.frank.appSecondary,
    borderBottomWidth: "medium",
  },
  gray_divider: {
    borderColor: theme.palette.frank.grey1,
    borderBottomWidth: "medium",
    borderLeftWidth: "medium",
    borderRadius: 10,
  },
  //pagination btn
  pagination_container: {
    color: theme.palette.frank.appSecondary,
    "& ul": {
      "& li": {
        "& .Mui-selected": {
          background: theme.palette.frank.appPrimary,
          border: `1px solid ${theme.palette.frank.appPrimary}`,
          color: theme.palette.frank.sand,
          fontFamily: "Roboto-Regular",
          fontSize: 14,
          borderRadius: 3,
          "&:hover": {
            background: theme.palette.frank.appPrimary,
            color: theme.palette.frank.sand,
          },
        },
        "& button": {
          background:
            // theme.palette.frank.white
            "#fff",
          color: theme.palette.frank.appPrimary,
        },
      },
    },
  },
  uncheck_icon: {
    color: `#a29dff !important`,
    height: 24,
    width: 24,
  },

  check_icon: {
    color: `${theme.palette.frank.appSecondary} !important`,
    height: 24,
    width: 24,
  },

  selected_row: {
    border: `2px solid ${theme.palette.frank.appSecondary}`,
  },
  unselected_row: {
    borderBottom: `none`,
    borderTop: `2px solid transparent`,
    borderLeft: `2px solid transparent`,
    borderRight: `2px solid transparent`,
  },
  input_label_style: {
    padding: "0px 0px 4px 10px",
    fontSize: 16,
    // fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.appPrimary,
    fontFamily:"Raleway-SemiBold !important"
  },
  color_red: {
    color: `red !important`,
  },
  button_width: {
    // maxWidth: 150,
    minWidth: 140,
    maxHeight: 43,
    minHeight: 39,
    textTransform: "none",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      minWidth: "0px",
      marginBottom: 5,
    },
  },
  contained_button: {
    background: theme.palette.frank.appPrimary,
    color: defaultLabelColors.color_h,
    transition: "0.5s",
    borderRadius:10,
    border:"none",
    fontFamily:"Raleway-SemiBold",

    "&:hover": {
      background: theme.palette.frank.appPrimary,
      color: defaultLabelColors.color_h,
      transition: "1s",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
  contained_button_red: {
    background: "red",
    color: defaultLabelColors.color_h,
    transition: "0.5s",
    borderRadius:10,
    border:"none",
    fontFamily:"Raleway-SemiBold",

    "&:hover": {
      background: "red",
      color: defaultLabelColors.color_h,
      transition: "1s",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
  contained_button_blue: {
    background: theme.palette.frank.appSecondary,
    color: defaultLabelColors.color_h,
    transition: "0.5s",
    borderRadius:10,
    border:"none",
    fontFamily:"Raleway-SemiBold",

    "&:hover": {
      background: theme.palette.frank.appSecondary,
      color: defaultLabelColors.color_h,
      transition: "1s",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },
  
  outlined_button: {
    background: defaultLabelColors.color_g1,
    color: theme.palette.frank.appPrimary,
    borderColor: theme.palette.frank.appPrimary,
    transition: "0.5s",
    borderRadius:10,
    border:"none",
    fontFamily:"Raleway-SemiBold",
    "&:hover": {
      background: defaultLabelColors.color_g1,
      transition: "1s",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      borderColor: theme.palette.frank.appPrimary,
    },
  },
  borderNone_button: {
    // background: defaultLabelColors.color_h,
    color: theme.palette.frank.appPrimary,
    border: "none", // Set no border
    transition: "0.5s",
    "&:hover": {
      background: defaultLabelColors.color_h,
      transition: "1s",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      border: "none", // No border on hover
      borderRadius:10,
    },
  },

  disabled_button: {
    background: theme.palette.frank.grey1,
    color: theme.palette.frank.grey3,
    borderColor: theme.palette.frank.grey1,
    borderRadius:10,
    transition: "0.5s",
  },

  modal_background: {
    backgroundColor: theme.palette.frank.appPrimary,
    background: theme.palette.frank.appPrimary,
    padding: "10px 20px",
  },
  modal_title: {
    fontSize: 22,
    fontWeight: "normal",
    lineHeight: "normal",
    color: defaultLabelColors.color_h,
    letterSpacing: "0.015em",
  },
  card_label_style: {
    fontSize: 16,
    color: theme.palette.frank.black,
    fontFamily: "Roboto-Medium",
    padding: 5,
  },
  card_value_style: {
    fontSize: 16,
    color: theme.palette.frank.black,
    fontFamily: "Roboto-Medium",
    padding: 5,
    opacity: 0.8,
  },
  profile_name: {
    fontSize: 20,
    color: theme.palette.frank.black,
    fontFamily: "Roboto-Bold",
    letterSpacing: 1.5,
  },
  text_1_with_background: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    background: theme.palette.frank.appLightPrimary,
    padding: 10,
    paddingLeft: 20,
    color: theme.palette.frank.appSecondary,
    textTransform: "capitalize",
  },
  active_tab: {
    borderRadius: "8px",
    // color: `${theme.palette.frank.appLightPrimary} !important`,
    color: `#ffff !important`,
    letterSpacing: "0.05em",
    fontSize: 18,
    minWidth: 250,
    fontFamily: "Raleway-Variable",
    background: `${defaultLabelColors?.main_orange} !important`,
  },
  inactive_tab: {
    // color: `${theme.palette.frank.appLightPrimary} !important`,
    color: `${defaultLabelColors?.main_orange} !important`,
    borderRadius: "8px",
    letterSpacing: "0.05em",
    fontSize: 18,
    minWidth: 250,
    fontFamily: "Raleway-Variable",
    background: `${defaultLabelColors?.sand} !important`,
  },
  accordion_head_container: {
    backgroundColor: `${theme.palette.frank.appPrimary} !important`,
    background: `${theme.palette.frank.appPrimary} !important`,
    width: "100%",
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: "10px",
    minHeight: `38px !important`,
    height: `38px !important`,
  },
  accordion_heading: {
    fontSize: 20,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "0.015em",
    color: "#ffff",
    fontFamily: "Roboto-Medium",
  },
  accordion_icons: {
    fontSize: 20,
    // color: theme.palette.frank.grey3,
    color: "#ffff",


  },
  accordion_container: {
    border: `1px solid ${theme.palette.frank.sand}`,
    borderRadius:"10px !important",
    margin: `0px !important`,
    background: "#fff",
  },
  accordion_detail_padding: {
    padding: "24px 32px 30px",
  },
  toggle_sections: {
    color: theme.palette.frank.appSecondary,
    fontSize: 16,
    paddingLeft: 15,
    fontFamily: "Roboto-Regular",
    cursor: "pointer",
  },
  view_image_text: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    fontFamily: "RobotoCondensed-Regular",
    color: theme.palette.frank.appSecondary,
  },
  dialog_footer_info: {
    fontSize: 14,
    fontFamily: "RobotoCondensed-Regular",
    color: theme.palette.frank.grey3,
    width: "max-content",
  },
  dialog_description: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.frank.grey3,
    fontFamily: "Roboto-Medium",
  },
  dialog_description_2: {
    fontSize: 16,
    color: theme.palette.frank.appSecondary,
    fontFamily: "RobotoCondensed-Regular",
  },
  radio_label: {
    fontSize: 16,
    fontFamily: "Roboto-Medium",
    color: theme.palette.frank.appSecondary,
  },

  unselect_radio_label: {
    fontSize: 16,
    fontFamily: "Roboto-Medium",
    color: theme.palette.frank.grey3,
  },
  setting_icons: {
    color: theme.palette.frank.appSecondary,
    height: 60,
    width: "100%",
  },
  setting_container: {
    background: "#ffffff",
    margin: 30,
    padding: 20,
    border: `2px solid ${theme.palette.frank.appLightPrimary}`,
    borderRadius: 5,

    [theme.breakpoints.down("sm")]: {
      // margin: 50,
    },
  },
  dashboard_setting_container: {
    // background: "#ffffff",f
    padding: "0px 0px 0px 10px",
    marginTop:10,
    borderRadius: 16,
    display: "flex",
    gap: "20px",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      // margin: 50,
    },
  },
  setting_heading: {
    fontSize: 18,
    fontFamily: "Roboto-Medium",
  },
  setting_description: {
    fontSize: 15,
    fontFamily: "Roboto-Regular",
    color: theme.palette.frank.grey3,
  },
  setting_hover: {
    transition: "0.5s",
    padding: 10,
    borderRadius: 10,
    "&:hover": {
      background: theme.palette.frank.sand,
      transition: "0.5s",
    },
  },
  switch_label: {
    fontSize: 16,
    fontFamily: "Roboto-Regular",
    color: theme.palette.frank.appSecondary,
  },
  rate_dialog_label: {
    fontSize: 16,
    fontFamily: "Roboto-Medium",
    color: theme.palette.frank.grey3,
  },
  responsive_width: {
    width: "30%",
    [theme.breakpoints.down("xl")]: {
      width: "50%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  commission_label: {
    fontSize: 16,
    color: theme.palette.frank.grey3,
    fontFamily: "RobotoCondensed-Medium",
  },
  dashboard_card_head: {
    fontSize: "4.74rem",
    color: theme.palette.frank.appPrimary,
    fontFamily: "Raleway-Variable",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.45rem",
    },
  },
  dashboard_card_title: {
    fontSize: "1.2rem",
    color: theme.palette.frank.appPrimary,
    fontFamily: "RobotoCondensed-Medium",
  },
  dashboard_card_container: {
    padding: "10px 15px",
    // border: `1px solid ${theme.palette.frank.appPrimary}`,
    borderRadius: 16,
    position: "relative",
    boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.1), 0px 1px 5px 0px rgba(0,0,0,0.01)`,
    display: "flex",
    flexDirection: "column",
    height: 148,
    // minWidth:240,
    width: "243px",

    // [theme.breakpoints.down("md")]: {
    //   width: "200px",
    // },
    "@media (max-width: 1100px) and (min-width: 900px)": {
      // width: 250,
      width: "100%",
      // Your desired width for this specific range
    },
    background: defaultLabelColors?.sand,
  },
  Dashboard_parent_box: {
    display: "flex",
    gap: 20,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column", // Stack the divs on small screens
    },
  },
  background_image_position: {
    backgroundPosition: "top",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
  },
  background_primary: {
    background: theme.palette.frank.appPrimary,
  },
  login_card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    width: "65%",
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
  },
  opening: {
    color: theme.palette.frank.appPrimary,
    fontFamily: "RobotoCondensed-Medium",
    width: "100%",
    textAlign: "center",
    fontSize: 20,
    "& h1": {
      display: "block",
      margin: 0,
      lineHeight: 1.3,
      color: "#fafafa",
      [theme.breakpoints.down("md")]: {
        fontSize: 32,
        lineHeight: "48px",
      },
    },
    "& p": {
      color: theme.palette.frank.appPrimary,
      fontSize: 18,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
  },
  signin_image_container: {
    width: "70%",
    background: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      // width: "100%",
      // width: "70%",
      background: "#ffffff",
      // display: "flex",
      // alignItems: "center",
      // justifyContent: "center",
      // height: "100vh",
    },
  },
  signin_head: {
    fontFamily: "RobotoCondensed-Medium",
    fontSize: 34,
    // letterSpacing: 1.2,
    color: theme.palette.frank.appPrimary,
    textTransform: "uppercase",
  },

  dashboard_icon: {
    fill: defaultLabelColors.main_orange, //theme.palette.frank.appPrimary,
    height: 25,
    width: 25,
    marginRight: 10,
    verticalAlign: "center",
    "&:hover": {
      fill:
        // theme.palette.frank.appPrimary
        "red",
    },
  },

  rate_toolbar_labels: {
    fontFamily: "Roboto-Medium",
    color: theme.palette.frank.appSecondary,
  },

  font_14: {
    fontSize: 14,
    fontFamily: "Roboto-Medium",
  },
  font_xl: {
    fontSize: 36,
    fontFamily: "Raleway-Variable",
    fontWeight: 700,
  },
  font_color_main_orange: {
    color: defaultLabelColors.main_orange,
  },
  resp_div: {
    [theme.breakpoints.up("sm")]: {
      width: "25%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  banner_info: {
    background: "biege",
    borderRadius: 4,
    boxShadow:
      " 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },

  banner_info_header: {
    background: defaultLabelColors.main_orange,
    height: 45,
    display: "flex",
    width: "100%",
    borderRadius: "10px 10px 10px 10px ",
    color:defaultLabelColors?.color_white,
  },
  product_info_header: {
    background: defaultLabelColors.main_orange,
    height: 40,
    display: "flex",
    width: "100%",
    borderRadius: "10px 10px 10px 10px ",
    color:defaultLabelColors?.color_white,
  },
  contained_blue_button: {
    background: "#3026f1",
    fontFamily: "Roboto-Bold",
    border: "1px solid #3026f1",
    color: "white",
    transition: "1s",
    borderRadius:10,
    "&:hover": {
      background: "#3026f1",
      color: "white",
      transition: "1s",
    },
  },
  input_field_height: {
    height: 10,
  },
  left_right_padding: {
    padding: "0px 15px 0px 15px",
  },
  order_status_box: {
    // background:"pink",
    borderRadius: "4px",
    padding: "0px 33px 0px 33px",
    // width:"133px",
    fontFamily: "Raleway-Variable",
  },
  paragraph_text: {
    fontSize: 18,
    // fontFamily: "Roboto-Medium",
    fontFamily: "Raleway-Variable",
    fontWeight: 500,

    // fontSize: 500,
  },
  heading_one: {
    fontSize: 46,
    fontWeight: 800,
    fontFamily: "Raleway-Variable",
    color: "black",
  },
  login_side_image: {
    height: "90vh",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "@media (max-width: 900px) and (min-width: 600px)": {
      display: "none",
    },
  },
  padding_main: {
    [theme.breakpoints.down("sm")]: {
      padding: "25px 25px 25px 25px",
    },

    // [theme.breakpoints.down("xl")]: {
    //   padding: "10px 120px 10px 30px",
    // },
    padding: "10px 30px 10px 30px",
  },
  more_detail_head: {
    fontFamily: "Roboto-Medium",
  },
  disable_box: {
    pointerEvents: "none",
    cursor: "not-allowed", 
    opacity: 0.6,
  },
  header_modal: {
    borderRadius: "24px 24px 11px 11px",
    background: "#ffff",
    boxShadow:  "0px 0px 5px 2px #00000025",
  },
  modal_title_new: {
    fontSize: 22,
    lineHeight: "25.83px",
    color: defaultLabelColors.color_h,
    letterSpacing: "0.015em",
    padding: "10px 20px",
    textTransform: "capitalize",
    background: defaultLabelColors?.main_orange,
    borderRadius: "24px 24px 0px 0px",
    fontWeight: 700,
    fontFamily: "Raleway-Variable",
  },
  order_heading_title: {
    fontFamily: "Raleway-Variable",
    lineHeight: "21.13px",
    fontSize: 18,
    fontWeight: 600,
    padding: "5px 0px",
    color: `${theme.palette.frank.appPrimary} !important`,
  },
  order_heading_para: {
    fontFamily: "Raleway-Variable",
    lineHeight: "21.13px",
    fontSize: 18,
    fontWeight: 600,
    color: `${theme.palette.frank.appPrimary} !important`,
  },
  order_heading_para2: {
    fontFamily: "Raleway-Variable",
    lineHeight: "21.13px",
    fontSize: 18,
    color: `#484848`,
    padding: 5,
    fontWeight: 600,
  },
  order_primary_divider: {
    borderColor: theme.palette.frank.appPrimary,
    borderBottomWidth: "small",
  },
  order_table_img: {
    height: "8vh",
    border: `1px solid ${theme.palette.frank.appPrimary}`,
    borderRadius: "25px",
    width: "100%",
    overflow: "hidden",
  },
  order_channel_style: {
    color: "white",
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 0.8,

    "@media (max-width: 1290px) and (min-width: 901px)": {
      fontSize: 20,
      lineHeight: 0.8,
    },
  },
  dashboard_status_circle_box: {
    // background: defaultLabelColors?.sand,
    background: defaultLabelColors?.light_sand,
    boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.1), 0px 1px 5px 0px rgba(0,0,0,0.01)`,
    width: 124,
    height: 124,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    // "@media (max-width: 1350px) and (min-width: 900px)": {
    //   width: "100%",
    //   height: "100%",
    // },
  },
  dasbhboard_order_channel_parent: {
    position: "relative",
    height: "100%",
    "@media (max-width: 900px)": {
      height: "",
    },
  },
  dasbhboard_order_channel_child: {
    position: "absolute",
    bottom: "15%",
    "@media (max-width: 900px)": {
      position: "static",
      // bottom:,
    },
  },

  blog_preview_width: {
    width: "50%",

    "@media (min-width: 1630px)": {
      width: "40%",
    },
  },
  error_402: {
    color: "#EB6A19",
    fontSize: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0",
    fontFamily: '"BebasNeue-Bold"',
    textTransform: "uppercase",
    filter: "drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4))",
  },
  link_error: {
    fontWeight: "bold",
    color: "#000000",
    fontSize: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "BebasNeue-Book",
    textTransform: "uppercase",
  },
}));

export default useStyles;
