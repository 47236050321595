import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "services/RequestHandler";

export interface Commentstate {
  all_Comments: any;
  all_Comments_data: any;
  all_Comments_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  selected_customer: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
}

const initialState: Commentstate = {
  all_Comments: [],
  all_Comments_data: [],
  all_Comments_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  selected_customer: {},
  activity: true,
};

export const allComments = createAsyncThunk(
  "Comments",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "Comments");
      // console.log(response,"response")
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const orderSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    setSelectedComments: (state: Commentstate, action: any) => {
      if (state.selected_customer) {
        state.selected_customer = action.payload;
      } else {
        state.selected_customer = {};
      }
    },
    clearAllComments: (state: Commentstate) => {
      state.all_Comments = [];
      state.all_Comments_data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allComments.pending, (state: Commentstate, { payload }) => {
        state.activity = true;
      })
      .addCase(allComments.fulfilled, (state: Commentstate, { payload }) => {
        let Comments = [] as any;
       
        state.all_Comments = Comments;
        state.all_Comments_data = payload?.data;
      

        state.activity = false;
      });
  },
});

export const { setSelectedComments, clearAllComments } = orderSlice.actions;

export default orderSlice.reducer;
