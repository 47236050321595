import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSubTitle, setTitle } from "store/breadcrumbReducer";
// import { error_401_translation } from 'translation';
// import { set_localize_content } from 'utils/helper';
import AccessDenied from "assets/images/AccessDenied-Updated.svg";
export default function ErrorPage(props: any) {
  // const {  } = props;
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(setTitle("Access Denied" as any));
    dispatch(setSubTitle("" as any));
    // eslint-disable-next-line
  }, []);
  return (
    <div style={{display: "flex",
      alignItems: "center",
      justifyContent: "center", }}>
     
        <div 
        style={{
          
          height: "80vh",
          width:"80wv"
        }}
        >
         
          <img src={AccessDenied} height={"100%"} width={"100%"} alt="not-found"></img>
        </div>
    </div>
  );
}
