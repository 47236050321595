import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Button,
  Grid,
} from "@mui/material";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import noShopImg from "assets/images/noShopimg.jpg";
import axios from "axios";
import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { BASE_URL } from "services/HttpProvider";
import apiServices from "services/RequestHandler";
import Button from "ui-components/Button";
import InputField from "ui-components/TextFeild";
import Toast from "ui-components/Toast";
import ViewImageModal from "ui-components/ViewImageModal";
import { defaultLabelColors } from "utils/constants";
export default function Profile(props: any) {
  const { classes, panel_head, details, shop_edit } = props;

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),

    {}
  );
  // const navigate = useNavigate();

  useEffect(() => {
    setState({ shop_detail: details, image_url: details?.image });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.details]);

  // handle input change
  const handleInputChange = (event: any): void => {
    try {
      const { name, value } = event.target;
      let { shop_detail } = state;

      let new_obj = {
        ...shop_detail,
        [name]: value,
      };
      shop_detail = new_obj;

      setState({
        shop_detail,
      });
      //   try {
      //     const { name, value } = event.target;
      //     setState({
      //       [name]: value,
      //     });
      //   } catch (error) {
      //     console.log(error);
      //   }
    } catch (error) {
      console.log(error);
    }
  };
  // uplaod file to s3
  const uploadFiles = async (files: (File | null | undefined)[] = []) => {
    const formData = new FormData();
    const timestamp = new Date().getTime();

    // Append files to FormData with dynamic naming based on timestamp
    files.forEach((file: File | any, index: number) => {
      if (file) {
        const fileName = `${index === 0 ? "frank" : "frank"}_shop_${timestamp}`;
        const fileToUpload = new File([file], fileName, {
          type: file?.type,
        });
        formData.append("files", fileToUpload);
      }
    });

    try {
      const response = await axios({
        baseURL: BASE_URL,
        url: "/upload/upload-multiple",
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      });
      return response;
    } catch (error) {
      console.error("Error uploading files:", error);
      Toast.showError("Something went wrong while processing your request.");
      return null;
    }
  };
  //
  const updateShopDetail = async () => {
    try {
      let send_data: any = {
        id: Number(state?.shop_detail?.id),
        first_name: state?.shop_detail?.first_name,
        last_name: state?.shop_detail?.last_name,
        phone: state?.shop_detail?.phone,
        phone_code: state?.shop_detail?.phone_code,
        email: state?.shop_detail?.email,
        shop_name: state?.shop_detail?.shop_name,
        shop_desc: state?.shop_detail?.shop_desc,
        // image: state?.shop_detail?.image ,
      };
      if (!state?.shop_detail?.first_name) {
        return Toast.showError("Please fill all fields");
      }

      if (!state?.shop_detail?.last_name) {
        return Toast.showError("Please fill all fields");
      }
      if (!state?.shop_detail?.phone) {
        return Toast.showError("Please fill all fields");
      }
      if (!state?.shop_detail?.phone_code) {
        return Toast.showError("Please fill all fields");
      }
      if (!state?.shop_detail?.email) {
        return Toast.showError("Please fill all fields");
      }
      if (!state?.shop_detail?.shop_name) {
        return Toast.showError("Please fill all fields");
      }
      if (!state?.shop_detail?.shop_desc) {
        return Toast.showError("Please fill all fields");
      }

      if (state?.image_url) {
        let resp = await uploadFiles([state?.image]);
        if (resp?.status === 201) {
          send_data.image = resp?.data?.data[0]?.uri;
        }
      } else {
        console.log("image not updated");
      }

      const resp = await apiServices.patchFromApi(
        "/c2c-shop-configure/admin",
        send_data,
        ""
      );
      if (resp?.status === 201) {
        Toast.showSuccess("Shop details updated successfully");
        // navigate(`/vendors/${state?.shop_detail?.id}`);
      } else {
        console.error("Error Updating shop detail");
        Toast.showError("Something went wrong while processing your request.");
      }
    } catch (err) {
      console.error("Error Updating shop detail :", err);
      Toast.showError("Something went wrong while processing your request.");
    }
  };

  // image chnage
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return; // No file selected, do nothing
    }
    const { name } = event.target;

    if (name === "web_image") {
      const file = event.target.files[0];
      // const fileSize = file.size;
      //   if (fileSize > 5e6) {
      //     // 5 MB size limit
      //     alert("Please upload a file smaller than 5 MB");
      //     return false; // Prevent further processing
      //   }

      const image = new Image();
      image.src = URL.createObjectURL(file);

      //   image.onload = () => {
      //     // Ensure dimensions are checked after image loads
      //     const allowedWidth = 1360; // Replace with your desired width
      //     const allowedHeight = 350; // Replace with your desired height
      //     if (
      //       image.naturalWidth !== allowedWidth ||
      //       image.naturalHeight !== allowedHeight
      //     ) {
      //       window.alert(
      //         `Image dimensions must be ${allowedWidth}x${allowedHeight} pixels.`
      //       );
      //       return false;
      //     }

      //   };

      setState({
        [name]: file,
        [`${name}_url`]: URL.createObjectURL(file),
      });
    } else {
      const file = event.target.files[0];
      // const fileSize = file.size;
      //   if (fileSize > 5e6) {
      //     // 5 MB size limit
      //     alert("Please upload a file smaller than 5 MB");
      //     return false;
      //   }

      const image = new Image();
      image.src = URL.createObjectURL(file);

      //   image.onload = () => {
      //     const allowedWidth = 1200;
      //     const allowedHeight = 600;

      //     if (
      //       image.naturalWidth !== allowedWidth ||
      //       image.naturalHeight !== allowedHeight
      //     ) {
      //       window.alert(
      //         `Image dimensions must be ${allowedWidth}x${allowedHeight} pixels.`
      //       );
      //       return false;
      //     }

      //   };
      setState({
        [name]: file,
        [`${name}_url`]: URL.createObjectURL(file),
      });
    }
  };
  const fileInputRef = useRef<any>(null);
  const openFileDialog = () => {
    // Programmatically trigger the file input click
    fileInputRef.current?.click();
  };
  return (
    <StyledEngineProvider injectFirst>
      <Accordion
        defaultExpanded
        className={classNames(classes.accordion_container)}
      >
        <AccordionSummary
          className={classNames(classes.accordion_head_container)}
          expandIcon={
            <ExpandMore
              className={classes.accordion_icons}
              style={{ fontSize: 24 }}
            />
          }
        >
          <span
            className={classNames(classes.accordion_heading)}
            style={{ flex: 1 }}
          >
            {panel_head}
          </span>
        </AccordionSummary>

        <AccordionDetails className={classes.accordion_detail_padding}>
          <Grid container spacing={4}>
            <Grid xs={12} md={12} item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "",
                  alignItems: "",
                  flexDirection: "column",
                }}
              >
                <div className={classes.displayFlex}>
                  <p
                    style={{
                      fontFamily: "Raleway-SemiBold",
                      paddingLeft: 10,
                      color: defaultLabelColors?.main_orange,
                      fontSize: "16px",
                    }}
                  >
                    Shop Image
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 0px",
                  }}
                >
                  <div className="container">
                    <img
                      src={state?.image_url ? state?.image_url : noShopImg}
                      height={200}
                      width={200}
                      style={{
                        objectFit: "contain",
                        borderRadius: 100,
                        position: "relative",
                        border: `2px solid ${defaultLabelColors?.main_orange}`,
                      }}
                      alt="not-found"
                      className="frank-shop-image"
                    ></img>

                    {/* <div
                      style={{ position: "absolute", width: "25px", left: 94 }}
                    >
                      <div>
                        <label htmlFor="file">
                          <AddPhotoAlternateIcon
                            sx={{
                              color: shop_edit
                                ? defaultLabelColors.main_orange
                                : defaultLabelColors.color_g,
                              cursor: "pointer",
                            }}
                            titleAccess="change image"
                          />
                        </label>
                      </div>
                      <input
                        style={{
                          visibility: "hidden",
                        }}
                        type="file"
                        name="image"
                        accept="image/*"
                        id="file"
                        onChange={handleImageChange}
                        disabled={shop_edit ? false : true}
                      />
                    </div> */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 40,
                    justifyContent: "center",
                  }}
                >
                  <label htmlFor="file">
                    <Button
                      value={"Change Image"}
                      classesNames={classNames(
                        classes.button_width,
                        !shop_edit
                          ? classes.disabled_button
                          : classes.contained_button
                      )}
                      onClick={openFileDialog} // Open the file input dialog
                      disable={shop_edit ? false : true}
                    />
                  </label>

                  <Button
                    value={"Delete Image"}
                    classesNames={classNames(
                      classes.button_width,
                      // classes.outlined_button
                      classes.disabled_button
                    )}
                    disable={true}

                    // onClick={() => navigate(-1)}
                  />
                  <input
                    type="file"
                    ref={fileInputRef} // Attach ref to the input
                    style={{ display: "none" }} // Hide the file input
                    accept="image/*"
                    name="image"
                    onChange={handleImageChange}
                    disabled={false} // Change the disabled state based on your requirements
                  />
                </div>
              </div>
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={details?.id ?? "- - -"}
                input_label={"Frank Shop ID"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={state?.shop_detail?.shop_name ?? "- - - "}
                input_label={"Shop Name"}
                variant={"outlined"}
                name={"shop_name"}
                InputProps={{
                  readOnly: shop_edit ? false : true,
                }}
                onChange={handleInputChange}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={state?.shop_detail?.business_id_siret ?? "- - -"}
                input_label={"Business Siret ID"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                  title: details?.shop_desc,
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={state?.shop_detail?.type_name ?? "- - -"}
                input_label={"Shop Type"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>

            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={state?.shop_detail?.first_name ?? "- - -"}
                input_label={"First Name"}
                variant={"outlined"}
                name={"first_name"}
                InputProps={{
                  readOnly: shop_edit ? false : true,
                }}
                onChange={handleInputChange}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                  title: details?.shop_desc,
                }}
                classes={classes}
              />
            </Grid>

            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={state?.shop_detail?.last_name ?? "- -"}
                input_label={"Last Name"}
                variant={"outlined"}
                name={"last_name"}
                InputProps={{
                  readOnly: shop_edit ? false : true,
                }}
                onChange={handleInputChange}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                  title: details?.shop_desc,
                }}
                classes={classes}
              />
            </Grid>

            {/* desx */}
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={`${details?.phone_code ?? "- -"} ${
                  details?.phone ?? "- -"
                }`}
                input_label={"Phone"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                input_label={"Email"}
                value={state?.shop_detail?.email ?? "- - -"}
                variant={"outlined"}
                name={"email"}
                InputProps={{
                  readOnly: shop_edit ? false : true,
                }}
                onChange={handleInputChange}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>

            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={
                  details?.created_at
                    ? moment(details.created_at).format("DD/MM/YYYY HH:mm")
                    : "- -"
                }
                input_label={"Registered on  "}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={12} item>
              <InputField
                fullWidth
                // value={details?.categories?.map((i:any)=>i?.category_name?.en?.join("|"))?? "- - -"}
                value={
                  details?.categories
                    ?.map((i: any) => i?.category_name?.en)
                    ?.join(" | ") ?? "- - -"
                }
                input_label={"Categories"}
                variant={"outlined"}
                multiline
                rows={details?.categories?.length < 5 ? 2 : 4}
                name={"shop_desc"}
                InputProps={{
                  readOnly: shop_edit ? false : true,
                }}
                onChange={handleInputChange}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                  // title: details?.shop_desc,
                }}
                classes={classes}
              />
            </Grid>

            <Grid xs={12} md={12} item>
              <InputField
                fullWidth
                value={state?.shop_detail?.shop_desc ?? "- - -"}
                input_label={"Description"}
                variant={"outlined"}
                multiline
                rows={details?.shop_desc?.length < 30 ? 2 : 4}
                name={"shop_desc"}
                InputProps={{
                  readOnly: shop_edit ? false : true,
                }}
                onChange={handleInputChange}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                  title: details?.shop_desc,
                }}
                classes={classes}
              />
            </Grid>

            <Grid xs={12} md={12} item>
              <div className={classes.justify_end}>
                <Button
                  value={"Update"}
                  classesNames={classNames(
                    "Raleway-Bold",
                    shop_edit
                      ? classes.contained_button
                      : classes.disabled_button,

                    classes.button_width
                  )}
                  disable={shop_edit ? false : true}
                  onClick={() => {
                    updateShopDetail();
                    props.getMiniShop();
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <ViewImageModal
        activity={state.show_image_modal}
        handleCloseModal={() => setState({ show_image_modal: false })}
        image_source={details?.image ?? ""}
      />
    </StyledEngineProvider>
  );
}
