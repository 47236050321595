import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "services/RequestHandler";
import { numberFormatWithoutCurrency } from "utils/helper";

export interface ProductState {
  all_product: any;
  all_product_data: any;
  all_product_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  selected_customer: any;
  activity: boolean;
}


const initialState: ProductState = {
  all_product: [],
  all_product_data: [],
  all_product_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  selected_customer: {},
  activity: true,
};

export const allProduct = createAsyncThunk(
  "/shop-products",
  async (data: any) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        {
          shop_id:data?.shop_id,
          page: data.page,
          limit: data.limit,
          start_date:data.start_date,
          end_date:data.end_date,
        },
        "product-by-shop"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const orderSlice = createSlice({
  name: "Products",
  initialState,
  reducers: {
    setSelectedProduct: (state: ProductState, action: any) => {
      if (state.selected_customer) {
        state.selected_customer = action.payload;
      } else {
        state.selected_customer = {};
      }
    },
    clearAllProduct: (state: ProductState) => {
      state.all_product = [];
      state.all_product_data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allProduct.pending, (state: ProductState, { payload }) => {
        state.activity = true;
      })
      .addCase(allProduct.fulfilled, (state: ProductState, { payload }) => {
        let products = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          // if (item.is_deleted) continue;
          products.push([
            // eslint-disable-next-line no-use-before-define
            item?.id || "- - -",
            item?.product_title || "- - -",
            {// eslint-disable-next-line no-use-before-define
              ui: (
                <div
                  className="Roboto-Medium"
                  // className={classNames(
                  //   classes.card_value_style,
                  //
                  // )}
                  title={item?.product_desc??""}
                  style={{
                    flex: 1,
                    display: "-webkit-box",
                    maxWidth: 200,
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {item?.product_desc ?? "- - -"}
                </div>
              ),
            },
            item?.prod_brand??"- - -",
            item?.cat_name??"- - -",
            item?.prod_size??"- - -",

            item?.price?numberFormatWithoutCurrency(item?.price):"- - -" ,
            item?.color??"- - -",
 {
              ui: (
                <div>
                  <img
                    src={item?.images[0]??"frank.jpeg"}
                    alt="frank.jpeg"
                    height={50}
                    width={100}
                    style={{ objectFit: "contain", cursor: "pointer" }}
                    className="banner-img"
                  ></img>
                </div>
              ),
            },
           
          ]);
        }
        state.all_product = products;
        state.all_product_data = payload?.data;
        state.all_product_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };

        state.activity = false;
      });
  },
});

export const { setSelectedProduct, clearAllProduct } = orderSlice.actions;

export default orderSlice.reducer;
