import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import storeMarker from "assets/images/map-pin.png";
import React from "react";
import GoogleAutocompleteField from "ui-components/GoogleAutocompleteField";
import { GOOGLE_API_KEY } from "utils/constants";

interface ReactGoogleMapAPi {
  apiKey: string;
  placeholder?: string;
  handleClick: (latLng: google.maps.LatLngLiteral | null) => void;
  styles?: React.CSSProperties;
  show_search?: boolean;
}
// eslint-disable-next-line
function ReactGoogleMapAPi(props: any) {
  // eslint-disable-next-line
  const { show_search = false } = props;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY,
  });
  const containerStyle = {
    width: props?.width,
    height: props?.height,
    borderRadius: props?.borderRadius,
  };

  const center = {
    lat: props?.lat ?? 48.8575,
    lng: props?.lng ?? 2.3514,
  };

  // const markerIcon = {
  //   url: storeMarker,
  //   // scaledSize: new window.google.maps.Size(25, 30),
  // };
// eslint-disable-next-line react-hooks/exhaustive-deps
// eslint-disable-next-line
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);

    setMap(map);
  }, []);

  const handleClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const latLng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      // console.log("Clicked at:", latLng);
      props.handleClick(latLng); // Pass latLng to the parent component or any handler
    } else {
      props.handleClick(null);
    }
  };
  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);
  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  return isLoaded ? (
  
    <>

      <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl:true,
      }}
      onClick={handleClick}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <>
        <Marker
          position={{
            lat: props?.lat ?? 48.8575,
            lng: props?.lng ?? 2.3514,
          }}
          // onClick={() => {
          //   let { store } = state;
          //   store[index] = {
          //     ...store[index],
          //     open: true,
          //   };
          //   setState({
          //     store,
          //   });
          //   // props.handleGetSpaceByID(item?.id);
          // }}
          // icon={markerIcon}
          icon={{ url: storeMarker, scaledSize: new google.maps.Size(25, 30) }}
          // animation={
          //   markerSlicer.hoveredIndex === item?.id
          //     ? google.maps.Animation.BOUNCE
          //     : undefined
          // }
        />
      </>

      {show_search && (
        <GoogleAutocompleteField
          placeholder="Enter a location"
          onPlaceSelected={(data) => console.log(data)}
          key={"originAddress"}
          styles={{
            background: "#fff",
            width: "100%",
            borderRadius: "4px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            fontSize: "16px",
            float: "left",

            fontFamily: "Roboto-Medium",
            padding: "20px",
            zIndex: 1111,
            color: "#000",
          }}
        />
      )}
    </GoogleMap>
    </>
    
  ) : null;
}

export default React.memo(ReactGoogleMapAPi);
