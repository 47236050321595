import MainLayout from "layout/MainLayout";
import PropTypes from "prop-types";
import ErrorPage from "ui-components/PermissionDenied";
import { can_perform_action } from "utils/helper";
import BlogCategory from "views/Blogs/BlogCategory";
import BusCustomerById from "views/Customers/BusinessCustomer/BusCustomerById";
import NotFound from "views/NotFound";
import Statement from "views/Payments/Statement";
import MiniShopByID from "views/Vendors/MiniShop/MiniShopByID";
import ProductDetail from "views/Vendors/MiniShop/MiniShopByID/ProductDetail";
import {
  AVS,
  AirRiderByID,
  AllAirRider,
  AllD2C,
  AllEmerchants,
  AllMiniShop,
  AllOrders,
  AllPayments,
  AllRiders,
  AllSpaceOrders,
  AllSpaces,
  AllSubRiders,
  Annoucement,
  AnnoucementById,
  AppCustomers,
  AssignAtt,
  AssignCatg,
  Attributes,
  AttributesByName,
  BannerManagement,
  BannerManagementById,
  Blogs,
  BlogsById,
  BulkImage,
  BusinessCustomer,
  C2CAllOrders,
  C2COrdersById,
  C2CPricing,
  C2CShippment,
  C2CShippmentById,
  Client,
  ClientById,
  CrmCustOrder,
  CrmProduct,
  CustomerById,
  Customers,
  Customers3P,
  D2CBusinessByID,
  Dashboard,
  EMerchantByID,
  FleetByID,
  FleetManagers,
  FrankCommissions,
  FrankIncoming,
  FrankWebsite,
  ItemByid,
  ListItem,
  ManageRoles,
  ManageUsers,
  MondialPricing,
  NotificationReport,
  Orders,
  OrdersById,
  ParticularProdDetail,
  PayOut,
  PayOutHistory,
  PayOutHistoryById,
  PayoutAnnouncement,
  PayoutsReqHistory,
  PlatFormFee,
  ProductBrand,
  ProductBrandById,
  PromoCode,
  PromoCodeById,
  RegularPricing,
  Reporting,
  ReqHistById,
  RiderByID,
  RoleDetails,
  SendNotification,
  ServiceMethod,
  ShippingRateTypes,
  ShippingRates,
  SpaceByID,
  SpaceCustomers,
  Specification,
  SpecificationById,
  Suppliers,
  SuppliersById,
  SuppliersOrder,
  SuppliersOrderById,
  SupportAndChat,
  TmsManageRoutes,
  TmsSenderOrder,
  TmsTransporter,
  TransportFee,
  UserAcitvity,
  UserDetails,
  V_categories,
  V_categoriesById
} from "./PageAsync";
// ==============================|| MAIN ROUTING ||============================== //

// export default MainRoutes;

export function MainAppRoutes({ classes, user }: any) {
  const MainRoutes = {
    path: "/",
    element: <MainLayout classes={classes} />,
    children: [
      {
        path: "/",
        element: <Dashboard classes={classes} />,
      },
      {
        path: "/dashboard",
        element: (
          <PrivateRoute
            WrappedComponent={Dashboard}
            classes={classes}
            module_name="Dashboard"
          />
        ),
      },
      {
        path: "/orders/e-merchant-orders",
        element: <AllOrders classes={classes} />,
      },
      {
        path: "/orders/frank-shop-orders",
        element: <C2CAllOrders classes={classes} />,
      },
      {
        path: "/orders/frank-shop-orders/:id",
        element: <C2COrdersById classes={classes} />,
      },

      {
        path: "/orders/space-orders",
        element: <AllSpaceOrders classes={classes} />,
      },
      {
        path: "/customers/3p-customers",
        element: <Customers3P classes={classes} />,
      },

      {
        path: "/customers/space-customers",
        element: <SpaceCustomers classes={classes} />,
      },

      {
        path: "/customers/app-customers",
        element: <AppCustomers classes={classes} />,
      },
      {
        path: "/customers",
        element: <BusinessCustomer classes={classes} />,
      },
      {
        path: "/customers/:id",
        element: <BusCustomerById classes={classes} />,
      },

      {
        path: "/transporters/fleet-managers",
        element: <FleetManagers classes={classes} />,
      },
      {
        path: "/transporters/fleet-managers/:id",
        element: <FleetByID classes={classes} />,
      },
      {
        path: "/transporters/sub-riders",
        element: <AllSubRiders classes={classes} />,
      },
      {
        path: "/transporters/riders",
        element: <AllRiders classes={classes} />,
      },
      {
        path: "/transporters/riders/:id",
        element: <RiderByID classes={classes} />,
      },

      {
        path: "/transporters/air-riders",
        element: <AllAirRider classes={classes} />,
      },
      {
        path: "/transporters/air-riders/:id",
        element: <AirRiderByID classes={classes} />,
      },
      // &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&7
      {
        path: "/vendors/e-merchants",
        element: <AllEmerchants classes={classes} />,
      },
      {
        path: "/vendors/e-merchants/:id",
        element: <EMerchantByID classes={classes} />,
      },
      {
        path: "/vendors/spaces",
        element: <AllSpaces classes={classes} />,
      },
      {
        path: "/vendors/spaces/:id",
        element: <SpaceByID classes={classes} />,
      },
      {
        path: "/vendors/d2c-business",
        element: <AllD2C classes={classes} />,
      },
      {
        path: "/vendors/d2c-business/:id",
        element: <D2CBusinessByID classes={classes} />,
      },
      {
        path: "/vendors/frank-shops",
        element: (
          <PrivateRoute
            WrappedComponent={AllMiniShop}
            classes={classes}
            module_name="Vendors"
          />
        ),
        // element: <AllMiniShop classes={classes} />,
      },
      {
        path: "/vendors/frank-shops/:id",
        // element: <MiniShopByID classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={MiniShopByID}
            classes={classes}
            module_name="Vendors"
          />
        ),
      },

      {
        path: "/payments/all-payments",
        element: <AllPayments classes={classes} />,
      },
      {
        path: "/payments/statements",
        element: <Statement classes={classes} />,
      },

      {
        path: "/payments/shipping-rates",
        element: <ShippingRates classes={classes} />,
      },
      {
        path: "/payments/shipping-rates/:type/:name?/:rt_id?",
        element: <ShippingRateTypes classes={classes} />,
      },
      // {
      //   path: "/payments/commissions",
      //   element: <Commission classes={classes} />,
      // },
      

      {
        path: "/management/notifications", //PUT s before push
        element: <SendNotification classes={classes} />,
      },
      {
        path: "/notifications/notification-tracking",
        element: <NotificationReport classes={classes} />,
      },
      {
        path: "/reporting",
        element: <Reporting classes={classes} />,
      },
      {
        path: "/support-chat",
        element: <SupportAndChat classes={classes} />,
      },

      {
        path: "/management/users",
        // element: <ManageUsers classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={ManageUsers}
            classes={classes}
            module_name="Users"
          />
        ),
      },
      {
        path: "/management/users/details/:id?",
        // element: <UserDetails classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={UserDetails}
            classes={classes}
            module_name="Users"
          />
        ),
      },
      {
        path: "/management/roles",
        // element: (<ManageRoles classes={classes} />),
        element: (
          <PrivateRoute
            WrappedComponent={ManageRoles}
            classes={classes}
            module_name="Roles"
          />
        ),
      },
      {
        path: "/management/roles/details/:id?",
        // element: <RoleDetails classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={RoleDetails}
            classes={classes}
            module_name="Roles"
          />
        ),
      },
      {
        path: "/management/banners",
        // element: <BannerManagement classes={classes} />,

        element: (
          <PrivateRoute
            WrappedComponent={BannerManagement}
            classes={classes}
            module_name="Banners"
          />
        ),
      },
      {
        path: "/management/banners/create",
        // element: <BannerManagementById classes={classes} />,

        element: (
          <PrivateRoute
            WrappedComponent={BannerManagementById}
            classes={classes}
            module_name="Banners"
          />
        ),
      },
      {
        path: "/management/banners/manage/:id?",
        // element: <BannerManagementById classes={classes} />,

        element: (
          <PrivateRoute
            WrappedComponent={BannerManagementById}
            classes={classes}
            module_name="Banners"
          />
        ),
      },

      {
        path: "/payments/announcements-pricing",
        // element: <C2CPricing classes={classes} />,

        element: (
          <PrivateRoute
            WrappedComponent={C2CPricing}
            classes={classes}
            module_name="Commissions"
          />
        ),
      },
      {
        path: "/payments/transport-fees",
        // element: <TransportFee classes={classes} />,

        element: (
          <PrivateRoute
            WrappedComponent={TransportFee}
            classes={classes}
            module_name="Transport Fees"
          />
        ),
      },
      {
        path: "/payments/platform-fees",
        // element: <PlatFormFee classes={classes} />,

        element: (
          <PrivateRoute
            WrappedComponent={PlatFormFee}
            classes={classes}
            module_name="Platform Fees"
          />
        ),
      },
      {
        path: "/payments/commissions",
        // element: <FrankCommissions classes={classes} />,

        element: (
          <PrivateRoute
            WrappedComponent={FrankCommissions}
            classes={classes}
            module_name="Commissions"
          />
        ),
      },

      {
        path: "/management/blogs",
        element: <Blogs classes={classes} />,
      },
      {
        path: "/management/blogs/create",
        element: <BlogsById classes={classes} />,
      },
      {
        path: "/management/blogs/manage/:id?",
        element: <BlogsById classes={classes} />,
      },
      {
        path: "/blogs-category",
        element: <BlogCategory classes={classes} />,
      },
      {
        path: "/orders/announcements",
        element: <C2CShippment classes={classes} />,
      },
      {
        path: "/orders/announcements/:id?",
        element: <C2CShippmentById classes={classes} />,
      },

      {
        path: "/management/items-list",
        // element: <ListItem classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={ListItem}
            classes={classes}
            module_name="Items List"
          />
        ),
      },
      {
        path: "/management/items-list/create",
        // element: <ItemByid classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={ItemByid}
            classes={classes}
            module_name="Items List"
          />
        ),
      },

      {
        path: "/management/items-list/manage/:id?",
        // element: <ItemByid classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={ItemByid}
            classes={classes}
            module_name="Items List"
          />
        ),
      },
      {
        path: "/bulk-images",
        element: <BulkImage classes={classes} />,
      },
      {
        path: "/management/users-activity",
        element: <UserAcitvity classes={classes} />,
      },
      /// new route for v2
      {
        path: "/oms/announcements",
        // element: <Annoucement classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={Annoucement}
            classes={classes}
            module_name="Announcements"
          />
        ),
      },
      {
        path: "/oms/announcements/:id?",
        // element: <AnnoucementById classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={AnnoucementById}
            classes={classes}
            module_name="Announcements"
          />
        ),
      },
      {
        path: "/oms/orders",
        // element: <Orders classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={Orders}
            classes={classes}
            module_name="Orders"
          />
        ),
      },
      {
        path: "/oms/orders/:id?",
        // element: <OrdersById classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={OrdersById}
            classes={classes}
            module_name="Orders"
          />
        ),
      },
      {
        path: "/oms/address-validation",
        // element: <AVS classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={AVS}
            classes={classes}
            module_name="Address Validation"
          />
        ),
      },
      {
        path: "/wms/customers",
        element: <Customers classes={classes} />,
      },
      {
        path: "/wms/customers/:id?",
        element: <CustomerById classes={classes} />,
      },
      {
        path: "/wms/suppliers",
        element: <Suppliers classes={classes} />,
      },
      {
        path: "/wms/suppliers/:id?",
        element: <SuppliersById classes={classes} />,
      },
      {
        path: "/wms/supplier-orders",
        element: <SuppliersOrder classes={classes} />,
      },
      {
        path: "/wms/supplier-orders/:id?",
        element: <SuppliersOrderById classes={classes} />,
      },

      {
        path: "/crm/clients",
        // element: <Client classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={Client}
            classes={classes}
            module_name="Clients"
          />
        ),
      },

      {
        path: "/crm/clients/:id?",
        // element: <ClientById classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={ClientById}
            classes={classes}
            module_name="Clients"
          />
        ),
      },
      {
        path: "/crm/customer-orders",
        element: <CrmCustOrder classes={classes} />,
      },
      {
        path: "/crm/products.",
        element: <CrmProduct classes={classes} />,
      },
      {
        path: "/tms/transporters",
        element: <TmsTransporter classes={classes} />,
      },

      {
        path: "/tms/sender-orders",
        element: <TmsSenderOrder classes={classes} />,
      },
      {
        path: "/tms/manage-routes",
        element: <TmsManageRoutes classes={classes} />,
      },

      {
        path: "/wms/products",
        element: <CrmProduct classes={classes} />,
      },
      {
        path: "/payments/regular-pricing",
        element: <RegularPricing classes={classes} />,
      },
      {
        path: "/payments/mondial-pricing",
        element: <MondialPricing classes={classes} />,
      },
      {
        path: "/management/catalog/categories",
        // element: <V_categories classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={V_categories}
            classes={classes}
            module_name="Categories"
          />
        ),
      },
      {
        path: "/management/catalog/categories/manage/:id?",
        // element: <V_categoriesById classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={V_categoriesById}
            classes={classes}
            module_name="Categories"
          />
        ),
      },
      {
        path: "/management/catalog/categories/create",
        // element: <V_categoriesById classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={V_categoriesById}
            classes={classes}
            module_name="Categories"
          />
        ),
      },
      {
        path: "/management/catalog/attributes",
        // element: <Attributes classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={Attributes}
            classes={classes}
            module_name="Attributes"
          />
        ),
      },
      {
        path: "/management/catalog/attributes/create",
        // element: <AttributesByName classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={AttributesByName}
            classes={classes}
            module_name="Attributes"
          />
        ),
      },
      {
        path: "/management/catalog/attributes/manage/:id?",
        // element: <AttributesByName classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={AttributesByName}
            classes={classes}
            module_name="Attributes"
          />
        ),
      },

      {
        path: "/management/catalog/specifications",
        // element: <Specification classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={Specification}
            classes={classes}
            module_name="Specifications"
          />
        ),
      },

      {
        path: "/management/catalog/specifications/create",
        // element: <SpecificationById classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={SpecificationById}
            classes={classes}
            module_name="Specifications"
          />
        ),
      },
      {
        path: "/management/catalog/specifications/manage/:id?",
        // element: <SpecificationById classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={SpecificationById}
            classes={classes}
            module_name="Specifications"
          />
        ),
      },
      {
        path: "/management/catalog/specification-categories",
        // element: <AssignCatg classes={classes} />,
        element: (
          <PrivateRoute
            WrappedComponent={AssignCatg}
            classes={classes}
            module_name="Specification Categories"
          />
        ),
      },
      {
        path: "particular-product-detail/:id?/:idx?",
        // element: <AssignCatg classes={classes} />,
        element: <ParticularProdDetail classes={classes} />,

      },
      {
        path: "/management/catalog/attribute-categories",
        element: <AssignAtt classes={classes} />,
      },
      {
        path: "/management/service-method",
        element: <ServiceMethod classes={classes} />,
      },
      {
        path: "/management/frank-website",
        element: <FrankWebsite classes={classes} />,
      },
      {
        path: "/management/catalog/brands",
        element: <ProductBrand classes={classes} />,
      },
      {
        path: "/management/catalog/brands/manage/:id?",
        element: <ProductBrandById classes={classes} />,
      },
      {
        path: "/management/catalog/brands/create",
        element: <ProductBrandById classes={classes} />,
      },
      {
        path: "/payments/payouts/:id?",
        element: <PayOut classes={classes} />,
      },
      {
        path: "/payments/payouts-history",
        element: <PayOutHistory classes={classes} />,
      },
      {
        path: "/payments/payouts-history/:id?",
        element: <PayOutHistoryById classes={classes} />,
      },
      {
        path: "/vendors/frank-shops/manage/:id?/:idx?",
        element: <ProductDetail classes={classes} />,
      },
      {
        path: "/vendors/frank-shops/create/:id?",
        element: <ProductDetail classes={classes} />,
      },
      {
        path: "/payments/payouts/:id?",
        element: <PayOut classes={classes} />,
      },
      {
        path: "/payments/payouts-history",
        element: <PayOutHistory classes={classes} />,
      },
      {
        path: "/payments/payouts-history/:id?",
        element: <PayOutHistoryById classes={classes} />,
      },
      {
        path: "/management/promo-codes",
        element: <PromoCode classes={classes} />,
      },
      {
        path: "/management/promo-code/create",
        element: <PromoCodeById classes={classes} />,
      },
      {
        path: "/management/promo-code/manage/:id?",
        element: <PromoCodeById classes={classes} />,
      },
      {
        path: "/payments/bank-transfer",
        element: <PayoutAnnouncement classes={classes} />,
      },
      {
        path: "/payments/bank-transfer-history",
        element: <PayoutsReqHistory classes={classes} />,
      },
      
      {
        path: "/payments/history-detail/:id?",
        element: <ReqHistById classes={classes} />,
      },
      {
        path: "/sale/incoming",
        element: <FrankIncoming classes={classes} />,
      },
      {
        path: "/*",
        element: <NotFound classes={classes} />,
      },
    ],
  };
  return MainRoutes;
}

const PrivateRoute = (props: any) => {
  const { WrappedComponent, classes, module_name } = props;
  if (can_perform_action(module_name)) {
    return <WrappedComponent classes={classes} module_name={module_name} />;
  } else {
    return <ErrorPage classes={classes} />;
  }
};

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};
