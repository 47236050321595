import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// material-ui
import { ListItemButton, ListItemIcon, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports

// assets
import ViewCarouselOutlinedIcon from "@mui/icons-material/ViewCarouselOutlined";
import {
  FrankAboutIcon,
  FrankCustomerIcon,
  FrankDashboard,
  FrankSettingIcon,
  SiderBarDashboard
} from "assets/images/FrankIcons";
import classNames from "classnames";
import { RootState } from "store";
import { handleClose, handleMenuClick } from "store/drawerReducer";
import { defaultLabelColors } from "utils/constants";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level, classes }: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customization = useSelector((state: RootState) => state.drawer);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref as any} {...props} to={item.url} target={itemTarget} />
    )),
    href: "",
    target: null,
  };
  if (item?.external) {
    listItemProps = {
      component: "a" as any,
      href: item.url as any,
      target: itemTarget as any,
    };
  }
  const itemHandler = (id: any) => {
    dispatch(handleMenuClick(id));

    if (matchesSM) dispatch(handleClose());
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch(handleMenuClick(item.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps

  ]);

  const getDashboardIcons = (key: string) => {
    // console.log(key,"");
    
    switch (key) {
      case "dashboard":
        return (
          // <FrankDashboard
          //   className={classNames(classes?.dashboard_icon, "frank-icon")}
          // />
          <SiderBarDashboard
          className={classNames(classes?.dashboard_icon, "frank-icon")}
          />
        );
      case "customers":
        return (
          <FrankCustomerIcon
            className={classNames(classes?.dashboard_icon, "frank-icon")}
          />
        );

      case "settings":
        return (
          <FrankSettingIcon
            className={classNames(classes?.dashboard_icon, "frank-icon")}
          />
        );
      case "about":
        return (
          <FrankAboutIcon
            className={classNames(classes?.dashboard_icon, "frank-icon")}
          />
        );
      case "support-chat":
        return (
          <FrankAboutIcon
            className={classNames(classes?.dashboard_icon, "frank-icon")}
          />
        );
      case "banner-management":
        return (
          <ViewCarouselOutlinedIcon
            className={classNames(classes?.dashboard_icon, "frank-icon")}
          />
        );

      default:
        return (
          <FrankDashboard
            style={{ marginRight: 10 }}
            height={20}
            width={20}
            stroke={defaultLabelColors.color_h}
          />
        );
    }
  };

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: `${customization.borderRadius}px`,
        mb: 1.5,
        color: defaultLabelColors?.main_orange,
        "&:hover": {
          background: `#f6b497 !important`, // Add !important here
          color: `white !important`, // Add !important here
        },
        alignItems: "flex-start",
        py:
          item.id === "dashboard" ||
          item.id === "support-chat" ||
          // item.id === "customers" ||
          item.id === "about" ||
          item.id === "banner-management"
            ? `${level * 5}px`
            : `${level * 3}px`,
        pl:
          item.id === "dashboard" ||
          item.id === "support-chat" ||
          // item.id === "customers" ||
          item.id === "about" ||
          item.id === "banner-management"
            ? 1
            : `${level * 20}px`,
      }}
      selected={
        customization.isOpen.findIndex((id: any) => id === item.id) > -1
      }
      onClick={() => itemHandler(item.id)}
    >
      {/* ICON */}
      {(item.id === "dashboard" ||
        item.id === "support-chat" ||
        item.id === "about" ||
        // item.id === "customers" ||
        item.id === "banner-management") && (
        <ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>
          {getDashboardIcons(item.id)}
        </ListItemIcon>
      )}

      <p style={{ marginBottom: 0, flex: 1 }}>{item.title}</p>
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
  classes: PropTypes.any,
};

export default NavItem;
