import * as React from "react";

// material-ui
import { Typography } from "@mui/material";

// project imports
// import NavGroup from "./NavGroup";
import { useEffect } from "react";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
import { restructureArray } from "utils/helper";
import NavCollapse from "../NavCollapse";
import NavItem from "../NavItem";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ classes }: any) => {
  // const dispatch = useDispatch<any>();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      menuItems: [],
    }
  );

  // useEffect(() => {
  //   const user = getDataFromLocalStorage("user");
  //   const menuItems = restructureArray(user?.permissions ?? []);

  //   setState({
  //     menuItems,
  //   });

  //   // eslint-disable-next-line
  // }, [JSON.stringify(admin_slice.modules)]);

  useEffect(() => {
    // get_all_modules();
    const user = getDataFromLocalStorage("user");
    const menuItems = restructureArray(user?.permissions ?? []);

    setState({
      menuItems,
    });

    // eslint-disable-next-line
  }, []);

 
// eslint-disable-next-line array-callback-return
// const navItems = state?.menuItems?.map((item: any) => {
//     // eslint-disable-next-line
//     if(item.visibility === false) {
//       return;
//     }
//     switch (item.type) {
//       case "collapse":
//         return (
//           <NavCollapse classes={classes} key={item.id} menu={item} level={1} />
//         );
//       case "item":
//         return (
//           <NavItem classes={classes} key={item.id} item={item} level={1} />
//         );
//       default:
//         return (
//           <Typography key={item.id} variant="h6" color="error" align="center">
//             Menu Items Error
//           </Typography>
//         );
//     }
//   });
  const navItems = state?.menuItems?.map((item: any) => {
    if (item.visibility === false) {
      return null; // ✅ Fix: Explicitly return null
    }
    switch (item.type) {
      case "collapse":
        return (
          <NavCollapse classes={classes} key={item.id} menu={item} level={1} />
        );
      case "item":
        return (
          <NavItem classes={classes} key={item.id} item={item} level={1} />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};


export default MenuList;
