import { Autocomplete } from "@react-google-maps/api";
import React, { useRef } from "react";

interface GoogleAutocompleteFieldProps {
  placeholder?: string;
  onPlaceSelected: (place: google.maps.places.PlaceResult | null) => void;
  styles?: React.CSSProperties;
}

// const libraries: "places"[] = ["places"];

const GoogleAutocompleteField: React.FC<GoogleAutocompleteFieldProps> = ({
  placeholder = "Search for a location",
  onPlaceSelected,
  styles = { width: "100%", padding: "10px", margin: "10px 0" },
}) => {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.geometry) {
        onPlaceSelected(place);
      } else {
        onPlaceSelected(null);
      }
    }
  };

  return (
    <Autocomplete
      onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
      onPlaceChanged={handlePlaceChanged}
    >
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        style={styles}
      />
    </Autocomplete>
  );
};

export default GoogleAutocompleteField;
