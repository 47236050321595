/*
  Created By Muhammad Hassaan
  Date 12 April 2023
*/
import PropTypes from "prop-types";
import { CSSProperties } from "react";
import { FadeLoader } from "react-spinners";

import { defaultLabelColors } from "utils/constants";
interface LoadingComponentProps {
  className?: string;
  activity: boolean;
}

export default function LoadingComponent(props: LoadingComponentProps) {
  const { activity, className = "loader-overlay" } = props;

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    background: "transparent",
  };
  return (
    // <Dialog
    //   open={activity}
    //   maxWidth="sm"
    //   hideBackdrop
    //   PaperProps={{
    //     style: {
    //       background: "transparent",
    //       boxShadow: "none",
    //       overflow: "hidden",
    //     },
    //   }}
    // >
    //   <DialogContent
    //     style={{
    //       overflow: "hidden",
    //     }}
    //   >
    //     <section>
    //       <div id="preloader">
    //         <div id="ctn-preloader" className="ctn-preloader">
    //           <div className="animation-preloader">
    //             {/* <div className="icon">&nbsp;</div> */}
    //             <div className="txt-loading mt-2">
    //               <span data-text-preloader="F" className="letters-loading">
    //                 F
    //               </span>
    //               <span data-text-preloader="R" className="letters-loading">
    //                 R
    //               </span>
    //               <span data-text-preloader="A" className="letters-loading">
    //                 A
    //               </span>
    //               <span data-text-preloader="N" className="letters-loading">
    //                 N
    //               </span>
    //               <span data-text-preloader="K" className="letters-loading">
    //                 K
    //               </span>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   </DialogContent>
    // </Dialog>
    activity ? (
      <div className={className}>
        <FadeLoader
          color={defaultLabelColors?.main_orange}
          loading={activity}
          cssOverride={override}
          aria-label="Loading Spinner"
          data-testid="loader"
          height={20}
          width={10}
          margin={15}
        />
      </div>
    ) : null
  );
}

// LoadingComponent.defaultProps = {
//   activity: false,
// };

// LoadingComponent.propTypes = {
//   activity: PropTypes.bool,
// };

// import { CSSProperties, useState } from "react";
// // import "app";
// import PropTypes from "prop-types";
// import { FadeLoader, RingLoader } from "react-spinners";
// import { defaultLabelColors } from "../../utils/constants";

// const override: CSSProperties = {
//   display: "block",
//   margin: "0 auto",
//   borderColor: "red",
//   background: "transparent",
// };

// function LoadingComponent(props: any) {
//   const { activity } = props;
//   const [color, setColor] = useState(defaultLabelColors.main_orange); // You can update the color dynamically if needed

//   return (
//     activity ? <div className="loader-overlay">
//       {" "}
//       {/* Transparent overlay */}
//       <FadeLoader
//         color={color}
//         loading={activity}
//         cssOverride={override}
//         aria-label="Loading Spinner"
//         data-testid="loader"
//         height={30}
//         width={10}
//         margin={15}

//       />
//     </div> : null
//   );
// }

// // Default prop values
// LoadingComponent.defaultProps = {
//   acitivity: false,
// };

// // PropTypes for validation
// LoadingComponent.propTypes = {
//   acitivity: PropTypes.bool,
// };

// export default LoadingComponent;
