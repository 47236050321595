/*
  Created By Muhammad Hassaan
  Date 11 April 2023
*/
import { KeyboardArrowDown } from "@mui/icons-material";
import { Autocomplete, Paper, TextField } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { dateRangeFilters, defaultLabelColors } from "utils/constants";
import PropTypes from "prop-types";
import moment from "moment";
export default function DateRangeFilter(props: any) {
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      filter: {
        label: "All Time Records",
        value: "all_time",
      },
      start_date: null,
      end_date: null,
    }
  );

  const {
    input_label,
    classes,
    error,
    isDisabled,
    name,
    variant,
    placeholder,
    readOnly,
    disableClearable,
    remove_padding,
  } = props;

  const handleChange = (e: any, value: any) => {
    try {
      setState({
        filter: value,
      });
      const today = new Date();

      switch (value?.value) {
        case "all_time":
          setState({
            start_date: null,
            end_date: null,
          });
          if (props.onChange) {
            props?.onChange({ start_date: null, end_date: null });
          }
          return;
        case "this_year":
          setState({
            start_date: moment().startOf("year").format("YYYY-MM-DD"),
            end_date: moment().endOf("year").format("YYYY-MM-DD"),
          });
          if (props.onChange) {
            props?.onChange({
              start_date: moment().startOf("year").format("YYYY-MM-DD"),
              end_date: moment().endOf("year").format("YYYY-MM-DD"),
            });
          }
          break;
        case "this_quarter":
          setState({
            start_date: moment().startOf("quarter").format("YYYY-MM-DD"),
            end_date: moment().endOf("quarter").format("YYYY-MM-DD"),
          });
          if (props.onChange) {
            props?.onChange({
              start_date: moment().startOf("quarter").format("YYYY-MM-DD"),
              end_date: moment().endOf("quarter").format("YYYY-MM-DD"),
            });
          }
          break;
        case "this_month":
          setState({
            start_date: moment().startOf("month").format("YYYY-MM-DD"),
            end_date: moment().endOf("month").format("YYYY-MM-DD"),
          });
          if (props.onChange) {
            props?.onChange({
              start_date: moment().startOf("month").format("YYYY-MM-DD"),
              end_date: moment().endOf("month").format("YYYY-MM-DD"),
            });
          }
          break;
        case "this_week":
          setState({
            start_date: moment().startOf("isoWeek").format("YYYY-MM-DD"),
            end_date: moment().endOf("isoWeek").format("YYYY-MM-DD"),
          });
          if (props.onChange) {
            props?.onChange({
              start_date: moment().startOf("isoWeek").format("YYYY-MM-DD"),
              end_date: moment().endOf("isoWeek").format("YYYY-MM-DD"),
            });
          }
          break;
        case "today":
          setState({
            start_date: moment(new Date()).format("YYYY-MM-DD"),
            end_date: moment(new Date()).format("YYYY-MM-DD"),
          });
          if (props.onChange) {
            props?.onChange({
              start_date: moment(new Date()).format("YYYY-MM-DD"),
              end_date: moment(new Date()).format("YYYY-MM-DD"),
            });
          }
          break;
        case "yesterday":
          setState({
            start_date: moment(
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - 1
              )
            ).format("YYYY-MM-DD"),

            end_date: moment(
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - 1
              )
            ).format("YYYY-MM-DD"),
          });
          if (props.onChange) {
            props?.onChange({
              start_date: moment(
                new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDate() - 1
                )
              ).format("YYYY-MM-DD"),

              end_date: moment(
                new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDate() - 1
                )
              ).format("YYYY-MM-DD"),
            });
          }
          break;
        case "previous_week":
          setState({
            start_date: moment()
              .subtract(1, "weeks")
              .startOf("isoWeek")
              .format("YYYY-MM-DD"),
            end_date: moment()
              .subtract(1, "weeks")
              .endOf("isoWeek")
              .format("YYYY-MM-DD"),
          });
          if (props.onChange) {
            props?.onChange({
              start_date: moment()
                .subtract(1, "weeks")
                .startOf("isoWeek")
                .format("YYYY-MM-DD"),
              end_date: moment()
                .subtract(1, "weeks")
                .endOf("isoWeek")
                .format("YYYY-MM-DD"),
            });
          }
          break;
        case "previous_month":
          setState({
            start_date: moment()
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD"),
            end_date: moment()
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD"),
          });
          if (props.onChange) {
            props?.onChange({
              start_date: moment()
                .subtract(1, "months")
                .startOf("month")
                .format("YYYY-MM-DD"),
              end_date: moment()
                .subtract(1, "months")
                .endOf("month")
                .format("YYYY-MM-DD"),
            });
          }
          break;
        default:
          console.log("AAYA IDHAR");
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form
      // eslint-disable-next-line

      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      style={{ position: "relative", width: "100%" }}
    >
      {input_label && (
        <label
          className={classNames(
            classes.input_label_style,
            "Roboto-Regular",
            error && classes.color_red
          )}
        >
          {input_label}
        </label>
      )}
      <Autocomplete
        value={state.filter}
        // id="county-city"
        options={dateRangeFilters}
        getOptionLabel={(option) => option.label}
        fullWidth
        disabled={isDisabled}
        // placeholder={placeholder}
        style={{
          background: isDisabled ? "#f6f6f6" : "transparent",
          color: defaultLabelColors.color_e,
        }}
        size="small"
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              "& ul": {
                "& li": {
                  fontFamily: "Roboto-Regular",
                  fontSize: 16,
                  fontWeight: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  color: defaultLabelColors.color_e,
                  borderBottom: "1px solid #E0E0E0",
                },
              },
            }}
            style={{
              boxShadow:
                "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
              maxHeight: 200,
              overflow: "hidden scroll",
            }}
          >
            {children}
          </Paper>
        )}
        popupIcon={
          <KeyboardArrowDown style={{ color: defaultLabelColors.color_f }} />
        }
        // eslint-disable-next-line

        autoComplete={false}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            size="small"
            // eslint-disable-next-line

            autoComplete="off"
            // eslint-disable-next-line

            aria-autocomplete="none"
            placeholder={placeholder}
            error={error}
            variant={variant}
            inputProps={{
              ...params.inputProps,
              style: {
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                fontWeight: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                backgroundColor: isDisabled ? "#f6f6f6" : "transparent",
                background: isDisabled ? "#f6f6f6" : "transparent",
                color: defaultLabelColors.color_e,
              },
              // eslint-disable-next-line

              autocomplete: "off",
              // eslint-disable-next-line

              "aria-autocomplete": "none",
            }}
            InputProps={{
              ...params.InputProps,
              style: {
                background: isDisabled ? "#f6f6f6" : "white",
                borderRadius: "10px",
              },
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              // eslint-disable-next-line

              autoComplete: "off",
            }}
            style={{
              background: isDisabled ? "#f6f6f6" : "white",
              borderRadius: "10px",
            }}
          />
        )}
        disableClearable={disableClearable}
        readOnly={readOnly}
      />
      {!props.label_hide && (
        <span
          style={
            remove_padding
              ? {
                  fontSize: 14,
                  color: "gray",
                  position: "absolute",
                  bottom: -22,
                  fontFamily: "Roboto-Italic",
                  left: 0,
                  right: 0,
                }
              : {
                  fontSize: 14,
                  color: "gray",
                  fontFamily: "Roboto-Regular",
                }
          }
        >
          {state.start_date && state.end_date && !props.label_hide ? (
            <>
              from {moment(state.start_date).format("DD-MMM-YYYY")} to{" "}
              {moment(state.end_date).format("DD-MMM-YYYY")}
            </>
          ) : (
            "ㅤ"
          )}
        </span>
      )}
    </form>
  );
}

DateRangeFilter.defaultProps = {
  input_label: "",
  required: false,
  error: false,
  isDisabled: false,
  classes: {},
  size: "small",
  variant: "outlined",
  placeholder: "",
  InputProps: null,
  inputProps: null,
  InputLabelProps: null,
  style: null,
  disableClearable: false,
  readOnly: false,
  remove_padding: false,
  label_hide: false,
  onChange: () => {},
};

DateRangeFilter.propTypes = {
  input_label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["outlined", "standard"]),
  required: PropTypes.bool,
  label_hide: PropTypes.bool,
  classes: PropTypes.object,
  InputProps: PropTypes.any,
  inputProps: PropTypes.any,
  InputLabelProps: PropTypes.any,
  style: PropTypes.any,
  error: PropTypes.bool,
  remove_padding: PropTypes.bool,
  onChange: PropTypes.func,
};
