import { NotFoundImage } from "assets/images/FrankIcons";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSubTitle, setTitle } from "store/breadcrumbReducer";

export default function NotFound(props: any) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle("404" as any));
    dispatch(setSubTitle("Page Not Found" as any));
    // eslint-disable-next-line

    return () => {
      dispatch(setTitle("" as any));
      dispatch(setSubTitle(null as any));
    };
        // eslint-disable-next-line
  }, []);
  return (
    <div>
      <NotFoundImage width={"100%"} height={"80vh"} />
    </div>
  );
}
