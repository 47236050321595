import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { key_to_label } from "utils/constants";

export interface ItemListState {
  all_itemList: any;
  all_itemList_data: any;
  all_itemList_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  selected_customer: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
}

const initialState: ItemListState = {
  all_itemList: [],
  all_itemList_data: [],
  all_itemList_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  selected_customer: {},
  activity: true,
};

export const allItemList = createAsyncThunk(
  "/c2c-ItemList",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "ItemList");
      // console.log(response,"response")
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const orderSlice = createSlice({
  name: "ItemList",
  initialState,
  reducers: {
    setSelectedItemList: (state: ItemListState, action: any) => {
      if (state.selected_customer) {
        state.selected_customer = action.payload;
      } else {
        state.selected_customer = {};
      }
    },
    clearAllItemList: (state: ItemListState) => {
      state.all_itemList = [];
      state.all_itemList_data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allItemList.pending, (state: ItemListState, { payload }) => {
        state.activity = true;
      })
      .addCase(allItemList.fulfilled, (state: ItemListState, { payload }) => {
        let list = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          if (item.is_deleted) continue;
          list.push([
            item?.id || "- - -",
            
            item?.item_name_en|| "- - -",
            item?.item_name_fr|| "- - -",
            item?.height|| "- - -",
            item?.length|| "- - -",
            item?.width|| "- - -",
            item?.weight? key_to_label[item?.weight]:"- - -",
            item?.added_by || "- - -",
            item?.created_at? moment(item?.created_at).format("DD/MM/YYYY HH:mm"):"- - -",
            


            // item?.is_active ? "Active" : "Inactive" || "- - -",
            // {
            //   name: item?.is_active ? "Active" : "Inactive",
            //   is_bool: true,
            //   color: item?.is_active
            //     ? defaultLabelColors.green
            //     : defaultLabelColors.red,
            //   style: {
            //     color: item?.is_active
            //       ? defaultLabelColors.green
            //       : defaultLabelColors.red,
            //   },
            // },
            // moment(item?.start_date).format("DD MMMM YYYY") || "- - -",
            // moment(item?.end_date).format("DD MMMM YYYY") || "- - -",
           

            // item?.banner_image?.web || "- - -",
          ]);
        }
        state.all_itemList = list;
        state.all_itemList_data = payload?.data;
        state.all_itemList_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };

        state.activity = false;
      });
  },
});

export const { setSelectedItemList, clearAllItemList } = orderSlice.actions;

export default orderSlice.reducer;
