import { KeyboardArrowDown } from "@mui/icons-material";
import "react-quill/dist/quill.snow.css";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import axios from "axios";
import classNames from "classnames";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "services/HttpProvider";
import apiServices from "services/RequestHandler";
import { setTitle } from "store/breadcrumbReducer";
import Button from "ui-components/Button";
import SelectField from "ui-components/SelectField";
import SwitchFeild from "ui-components/SwitchFeild";
import InputField from "ui-components/TextFeild";
import Toast from "ui-components/Toast";
import { defaultLabelColors } from "utils/constants";
import {
  buildHierarchy,
  getCategoryPath,
  global_error_message,
  handleLocalize,
} from "utils/helper";
/////

import CancelIcon from "@mui/icons-material/Cancel";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { Masonry } from "@mui/lab";
import { UploadImageSvg } from "assets/images/FrankIcons";
import ReactQuill from "react-quill";
import { strings } from "utils/translation";
export default function ProductDetail(props: any) {
  // const { shop_id, product_detail } = props;
  let { classes } = props;
  let { idx, id } = useParams();
  const navigate = useNavigate();

  interface PAYLOAD_REQ {
    shop_id?: any;
    id?: any;
    category_id?: number;
    brand_id?: number;
    product_title?: string;
    product_desc?: string;
    images: Array<any>;
    videos: Array<any>;
    video_links?: Array<any>;
    product_variations?: Array<any>;
    product_specifications?: Array<any>;
    price?: number;
    sku?: string;
    discount?: number;
    quantity?: number;
    without_variant?: boolean;
    net_weight?: number;
    weight_unit?: number;
    service_methods?: Array<any>;
  }
  // const categorySlicer = useSelector((state: RootState) => state?.catalog);
  const dispatch = useDispatch<any>();
  // eslint-disable-next-line
  const [path, setPath] = useState([
    { id: null, name: { en: strings.category, fr: strings.category } },
  ]); // Tracks the breadcrumb path

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      // user: getData("user"),
      // shop_details: getData("shop_details"),
      images: [],
      loading_screen: true,
      variations: [],
      catalog: {
        product_specifications: [],
      },
      attr_types: [],
      p_variants: [],
      without_variant: false,
      net_weight: "",
      // product_specifications_resp:[],

      // while update render this to add new
      selected_brand: "",
      new_variations: [],
    }
  );

  ///////////////////////////////////////////////////////////////////
  //  useEffect(() => {}, [selector.lang]);

  const initPage = () => {
    getServiceMethods();
  };

  const getServiceMethods = async () => {
    try {
      const s_method_resp = await apiServices.getFromApi(
        "/c2c-service-methods",
        ""
      );
      setState({
        delivery_methods: s_method_resp?.data,
      });
      getBrandList();
    } catch (error) {
      console.log(error);
    }
  };

  const getBrandList = async () => {
    try {
      const resp = await apiServices.getFromApi("c2c-brands", "");
      const brands = resp?.data?.map((item: any) => {
        return {
          id: Number(item.id),
          value: Number(item.id),
          brand_name: item.brand_name,
          brand_image: item?.brand_image,
        };
      });
      setState({
        all_brands: brands,
      });
      getCategories();
    } catch (error) {
      console.log(error);
      setState({
        loading_screen: false,
      });
    }
  };

  React.useEffect(() => {
    const unsubscribe = () => {
      setState({
        product_title: "",
        product_desc: "",
        images: [],
        catalog: {
          product_specifications: [],
        },
        p_variants: [],
        product_variations: [],
        product_specifications_resp: [],
        selected_category: null,
        selected_brand: null,
        sku: "",
        price: "",
        discount: "",
        quantity: "",
        without_variant: false,
        is_update: false,
        service_methods: [],
        net_weight: "",
        multi_lang: false,
        new_product_variations: [],
        new_variations: [],
        variations: [],
      });
      setPath([
        {
          id: null,
          name: { en: "strings.category", fr: "strings.category" },
        },
      ]);
    };
    initPage();
    return unsubscribe;
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    //  let selected_prod = props.route?.params?.item;
    dispatch(setTitle("Product Detail" as any));

    if (idx) {
      if (state.categories?.length) {
        getProductByID();

        dispatch(setTitle("Product Detail" as any));
      }
    }
    // eslint-disable-next-line
  }, [idx, state.categories]);

  React.useEffect(() => {
    // let selected_prod = props.route?.params?.item;
    if (idx) {
      setState({
        is_update: true,
      });
    }
    // eslint-disable-next-line
  }, [props.route?.params?.item]);

  const getProductByID = async () => {
    try {
      setState({
        is_update: true,
      });
      const resp = await apiServices.getFromApi(
        // `c2c-product-masters/by-shop/${selected_prod?.id}`,
        `/c2c-product-masters/all-by-shop/${id}/${idx}`,

        ""
      );
      // setVariation(resp?.data?.product_variations);

      const data = resp?.data;
      const filteredCategory = state.categories?.find(
        (i: any) =>
          // eslint-disable-next-line
          Number(i.id) === Number(data?.category_id)
      );
      const groupedCategories = buildHierarchy(state.categories);

      const category_path = getCategoryPath(
        data?.category_id,
        groupedCategories
      );

      const selected_brand = state.all_brands?.find(
        (i: any) =>
          // eslint-disable-next-line

          Number(i.id) === Number(data?.brand_id)
      );

      const prod_specs = await apiServices.getFromApi(
        `/c2c-specification-categories/${filteredCategory?.id}`,
        ""
      );
      //

      setState({
        is_update: true,
        product_title: data?.product_title,
        product_desc: data?.product_desc,
        images: data?.images,
        saved_images: data?.images,
        net_weight: `${data?.product_variations?.[0]?.net_weight ?? ""}`,
        product_id: Number(data?.id),
        price: !data?.without_variant
          ? `${data?.product_variations?.[0]?.price ?? ""}`
          : "",
        discount: !data?.without_variant
          ? `${data?.product_variations?.[0]?.discount ?? ""}`
          : "",
        quantity: !data?.without_variant
          ? `${data?.product_variations?.[0]?.items_in_stock ?? ""}`
          : "",
        sku: !data?.without_variant ? data?.product_variations?.[0]?.sku : "",
        without_variant: data?.without_variant,

        // v1
        // variations,

        // Setting Category & Path

        selected_category: filteredCategory,
        cat_name: handleLocalize(filteredCategory?.category_name),
        category_path,
        // Setting Brand
        selected_brand: {
          ...selected_brand,
          label: handleLocalize(selected_brand?.brand_name),
        },

        // Setting Product Specification
        show_bottom_sheet: false,
        sheet_type: null,

        product_variations: data?.product_variations?.map((i: any) => ({
          ...i,
          quantity: `${i.items_in_stock}`,
          price: `${i.price}`,
          discount: `${i.discount}`,
          saved_images: i.images,
          types: i?.attributes?.map((i: any) =>
            handleLocalize(i.attribute_name)
          ),
          values: i?.attributes?.map((i: any) => i?.attribute_value),
          service_methods: data?.service_methods?.map(
            (i: any) => `${i?.service_method_id}`
          ),
          //maa
        })),

        product_variations_update: data.product_variations?.map((i: any) => ({
          ...i,
          quantity: `${i.items_in_stock}`,
          price: `${i.price}`,
          discount: `${i.discount}`,
          saved_images: i.images,
          types: i?.attributes?.map((i: any) =>
            handleLocalize(i.attribute_name)
          ),
          values: i?.attributes?.map((i: any) => i?.attribute_value),
          service_methods: data?.service_methods?.map(
            (i: any) => `${i?.service_method_id}`
          ),
          //maa
        })),
        product_specifications_resp: prod_specs?.data,
        service_methods: data?.service_methods?.map((i: any) => ({
          label: handleLocalize(i?.service_method_name),
          value: i?.service_method_id,
        })),
      });

      if (
        // eslint-disable-next-line

        prod_specs?.status === 200
      ) {
        let catalog: any = {
          product_specifications: [],
        };

        for (
          let index = 0;
          index < data?.product_specifications?.length;
          index++
        ) {
          const item = data?.product_specifications[index];
          let isPresent = prod_specs?.data?.findIndex(
            (a: any) => Number(a.id) === Number(item?.specification_name_id)
          );
          let selected_i = prod_specs?.data[isPresent];

          let prod_sp = {
            spec_id: Number(item.id),
            specification_name_id: selected_i?.id,
            data: selected_i,
            product_specification_values:
              item?.product_specification_values?.map((j: any) => ({
                specification_value_id: j.specification_value_id,
                item_data:
                  selected_i?.specification_values?.filter((a: any) =>
                    item?.product_specification_values?.some(
                      (b: any) => a.id === b.specification_value_id
                    )
                  )[0] ?? {},
              })),
            selected_product_specification_values:
              item?.product_specification_values?.map(
                (j: any) => j.specification_value_id
              ),

            _value: selected_i?.is_drop_down
              ? item?.product_specification_values?.map((j: any) => ({
                  label: handleLocalize(j?.specification_value),
                  value: j.specification_value_id,
                }))
              : item?.product_specification_values[0]?.specification_value?.en,
          };
          catalog.product_specifications?.push(prod_sp);
        }
        setState({
          catalog,
        });
      }
    } catch (error) {
      console.log(error);
      setState({
        is_update: true,
      });
    }
  };
  function transformAttributes(data: any) {
    const groupedAttributes: any = {};

    data.forEach((item: any) => {
      const attrKey = item.attribute_name.en; // Group by attribute name

      if (!groupedAttributes[attrKey]) {
        groupedAttributes[attrKey] = {
          attr_values: state?.attributes[`${item?.attribute_name?.en}`]?.map(
            (i: any) => ({
              id: i?.id,
              attribute_name: i?.attribute_name,
              attribute_value: i?.attribute_value,
            })
          ),
          selected_attr_type: {
            label: item.attribute_name.en,
            value: item.attribute_name.en,
          },
          selected_attr_values: [],
          selected_attr_value_ids: [],
        };
      }

      // Push attribute details
      groupedAttributes[attrKey].selected_attr_values.push({
        id: item.attribute_id,
        attribute_name: item.attribute_name,
        attribute_value: item.attribute_value,
      });

      // Push attribute value ID mapping
      groupedAttributes[attrKey].selected_attr_value_ids.push({
        label: item.attribute_value.en,
        value: item.attribute_id,
      });
    });

    return Object.values(groupedAttributes);
  }
  const setVariation = (product_variations: any) => {
    try {
      const unionAttributes = (arr: any[]): any[] => {
        const attributeMap = new Map<number, any>();
        arr?.forEach((variation) => {
          variation?.attributes.forEach((attr: any) => {
            if (attributeMap?.has(attr.attribute_id)) {
              // Merge attributes if they share the same `attribute_id`
              const existingAttr = attributeMap.get(attr.attribute_id);
              attributeMap.set(attr.attribute_id, {
                ...existingAttr,
                ...attr,
                attribute_name: {
                  en: existingAttr?.attribute_name.en || attr.attribute_name.en,
                  fr: existingAttr?.attribute_name.fr || attr.attribute_name.fr,
                },
                attribute_value: {
                  en:
                    existingAttr?.attribute_value.en || attr.attribute_value.en,
                  fr:
                    existingAttr?.attribute_value.fr || attr.attribute_value.fr,
                },
              });
            } else {
              // Add new attribute to the map
              attributeMap?.set(attr.attribute_id, attr);
            }
          });
        });

        return Array.from(attributeMap.values());
      };

      const result = unionAttributes(product_variations);

      const result2 = transformAttributes(result);

      // const combine_res = result // here i have to create data
      //   ?.map((attribute: any) => ({
      //     attr_values: state?.attributes[
      //       `${attribute?.attribute_name?.en}`
      //     ]?.map((i: any) => ({
      //       id: i?.id,
      //       attribute_name: i?.attribute_name,
      //       attribute_value: i?.attribute_value,
      //     })),
      //     selected_attr_type: {
      //       label: attribute?.attribute_name?.en,
      //       value: attribute?.attribute_name?.en,
      //     },
      //     selected_attr_values: [
      //       {
      //         id: attribute?.attribute_id,
      //         attribute_name: attribute?.attribute_name,
      //         attribute_value: attribute?.attribute_value,
      //       },
      //     ],
      //     selected_attr_value_ids: [
      //       {
      //         label: attribute?.attribute_value?.en,
      //         value: attribute?.attribute_id,
      //       },
      //     ],
      //   }))
      //   .flat();

      ///

      ///
      setState({ variations: result2 });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (state?.product_variations_update) {
      setVariation(state?.product_variations_update);
    }

    // eslint-disable-next-line
  }, [state?.product_variations_update]);
  const getAttributes = async () => {
    try {
      const resp = await apiServices.getFromApi("c2c-product-attributes", "");
      let attributes = _.groupBy(resp?.data, "attribute_name.en");
      let attr_types = [];

      for (const key in attributes) {
        if (Object.prototype.hasOwnProperty.call(attributes, key)) {
          const element: any = attributes[key];

          attr_types.push({
            label: handleLocalize(element[0]?.attribute_name),
            value: key,
          });
        }
      }

      setState({
        attr_types,
        attributes,
        all_attributes: resp?.data,
        refreshing: false,
        loading_screen: false,
      });
    } catch (error) {
      setState({
        loading_screen: false,
      });
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      const resp = await apiServices.getFromApi(
        `c2c-shop-configure/get-shop-categories/${id}`,
        ""
      );
      setState({
        categories: resp?.data,
      });
      getAttributes();
    } catch (error) {
      console.log(error);
      setState({
        loading_screen: false,
      });
    }
  };

  // image update is not functional
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { images } = state;
    const files = event.target.files;

    // Early return if files is undefined
    if (!files) {
      return;
    }

    // Array to hold valid image URLs
    const newImages: any[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check file type
      const fileType = file.type;
      const isValidType = fileType === "image/jpeg" || fileType === "image/png";

      // Check file size (7 MB = 7 * 1024 * 1024 bytes)
      const isValidSize = file.size <= 7 * 1024 * 1024;

      if (isValidType && isValidSize) {
        // newImages.push(URL.createObjectURL(file));
        newImages.push(file);
      } else {
        // You can alert the user or handle invalid files as needed
        alert(
          `File ${file.name} is invalid. Only JPEG and PNG files under 7MB are allowed.`
        );
      }
    }

    // Update the images only if valid images were found
    if (newImages.length > 0) {
      images = [...images, ...newImages]; // Concatenate new valid images
    }

    setState({ images });
  };

  const handleImageUploadVariation = async (
    event: React.ChangeEvent<HTMLInputElement>,
    is_new: boolean,
    index: number = 0,
    iamge_index: number,
    is_new_var: boolean = false
  ) => {
    const files = event.target.files;

    // Early return if files is undefined
    if (!files) {
      return;
    }

    let images = state.product_variations[index]?.images || [];

    // Check total images after upload
    if (images.length + files.length > 6) {
      alert("You can only upload a maximum of 6 images.");
      return;
    }

    // Array to hold valid images
    const newImages = [];
    const check_newImages = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = file.type;
      const isValidType = fileType === "image/jpeg" || fileType === "image/png";
      const isValidSize = file.size <= 7 * 1024 * 1024;

      // Validate file type and size
      if (isValidType && isValidSize) {
        // const fileUri = URL.createObjectURL(file);

        newImages.push({ ...file, uri: file });
        check_newImages.push(file);
      } else {
        alert(
          `File ${file.name} is invalid. Only JPEG and PNG files under 7MB are allowed.`
        );
      }
    }

    if (newImages.length > 0) {
      images = [...images, ...newImages];

      let updatedVariation = {
        ...state.product_variations[index],
        images,
        newImages,
        check_newImages,
      };

      const new_product_variations = [...state.product_variations];
      new_product_variations[index] = updatedVariation;

      setState({
        ...state,
        product_variations: new_product_variations,
      });
    }
  };

  // const handleChange = (text: string | any, name: string) => {
  //   try {
  //     setState({
  //       [name]: text,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // Filter categories based on the current level
  // const currentLevelId = path[path.length - 1].id;
  // const currentCategories =
  //   state?.categories?.filter(
  //     (category: any) => category?.parent_id === currentLevelId
  //   ) ?? [];

  // Handle category selection to navigate deeper or go back
  // const handleCategorySelect = (category: any) => {
  //   setPath((prevPath: any) => [
  //     ...prevPath,
  //     { id: category.id, name: category.category_name },
  //   ]);
  // };

  // Handle back navigation

  const handleSelectCategory = async (idd?: any) => {
    try {
      // const { selected_category } = state;
      const prod_specs = await apiServices.getFromApi(
        `/c2c-specification-categories/${idd}`,
        ""
      );
      setState({
        show_bottom_sheet: false,
        sheet_type: null,
        product_specifications_resp: prod_specs?.data,
        catalog: {
          product_specifications: [],
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateItemState = (item: any, index: number, type: string) => {
    try {
      let { product_specifications } = state.catalog;
      // eslint-disable-next-line eqeqeq
      if (type == "dropdown") {
        let selected_item = {
          ...product_specifications[index],
          specification_name_id: Number(
            state.product_specifications_resp[index]?.id
          ),
          data: state.product_specifications_resp[index],
          product_specification_values: [
            {
              specification_value_id: item?.value,
              item_data: item,
            },
          ],
          _value: item,
        };
        product_specifications[index] = selected_item;
      } // eslint-disable-next-line eqeqeq
      else if (type == "textInput") {
        let selected_item = {
          ...product_specifications[index],
          specification_name_id: Number(
            state.product_specifications_resp[index]?.id
          ),
          data: state.product_specifications_resp[index],
          product_specification_values: [
            {
              pro_specification_value: {
                en: item,
                fr: item,
              },
              item_data: item,
            },
          ],
          _value: item,
        };
        product_specifications[index] = selected_item;
      } // eslint-disable-next-line eqeqeq
      else if (type == "multiDropdown") {
        let selected_item = {
          ...product_specifications[index],
          specification_name_id: Number(
            state.product_specifications_resp[index]?.id
          ),
          data: state.product_specifications_resp[index],
          product_specification_values: item?.map((i: any) => {
            return {
              specification_value_id: Number(i?.value),
            };
          }),
          selected_product_specification_values: item,
          _value: item,
        };

        product_specifications[index] = selected_item;
      }
      setState({
        catalog: {
          product_specifications,
        },
      });
    } catch (error) {
      // throw error
      // console.log(error);
    }
  };

  // adding debounce function to call my function after scroll ends 1000ms = 1 sec
  const debouncedCreateVariations = _.debounce(async () => {
    try {
      if (state.is_update) {
        const combinations = generateAttributeCombinations(state.variations);
        //logic here

        const update_combination = combinations?.map?.(
          (item: any, index: number) => {
            const variation = state?.product_variations_update?.[index] || {};
            return {
              ...item, // Spread the original combination item
              ...(variation?.id && { id: variation.id }), // Add id only if it exists
              sku: variation?.sku || "", // Use variation's sku or default to ""
              price: variation?.price || "", // Use variation's price or default to ""
              discount: variation?.discount || "", // Use variation's discount or default to ""
              quantity: variation?.quantity || "", // Use variation's discount or default to ""
              images: variation?.images || [], // Add an empty image array
            };
          }
        );

        setState({
          product_variations: update_combination,
        });
      } else {
        const combinations = generateAttributeCombinations(state.variations);

        setState({
          product_variations: combinations,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, 500);

  const generateAttributeCombinations = (attributes: any) => {
    const result: any = [];

    // Recursive function to generate combinations
    function combine(current: any, depth: any) {
      if (depth === attributes?.length) {
        // Create the final result object
        const combination = { ...current };
        combination.types = attributes?.map(
          (attr: any) => attr.selected_attr_type.label
        );
        combination.values = attributes?.map((attr: any) => {
          const value = combination[attr.selected_attr_type.label];
          return attr.selected_attr_values.find(
            (v: any) => v.attribute_value.en === value
          )
            ? {
                en: value,
                fr: attr.selected_attr_values.find(
                  (v: any) => v.attribute_value.en === value
                ).attribute_value.fr,
              }
            : { en: "", fr: "" };
        });

        // Add IDs to the combination
        combination.attributes = attributes
          ?.map(
            (attr: any) =>
              attr.selected_attr_values.find(
                (v: any) =>
                  v.attribute_value.en ===
                  combination[attr.selected_attr_type.label]
              )?.id
          )
          .filter((id: any) => id !== undefined)
          ?.map((ik: any) => ({ attribute_id: Number(ik) }));
        combination.images = [];
        result.push(combination);
        return;
      }

      const attr = attributes[depth];
      const attrType = attr.selected_attr_type.label;
      attr.selected_attr_values.forEach((value: any) => {
        current[attrType] = value.attribute_value.en;
        combine(current, depth + 1);
      });
    }

    // Start the combination process
    combine({}, 0);

    return result;
  };

  const handleChangeVariations = (
    state_name: string,
    value: any,
    index: number,
    key?: string,
    is_new_var: boolean = false
  ) => {
    try {
      let { product_variations, is_update, new_product_variations } = state;

      if (is_update && is_new_var) {
        // eslint-disable-next-line eqeqeq
        if (state_name == "is_default") {
          setState({
            new_product_variations: new_product_variations?.map(
              (i: any, idx: number) => ({
                ...i,
                // eslint-disable-next-line
                [state_name]: idx == index ? value : false,
              })
            ),
          });
          return;
        }
        let obj = {
          ...new_product_variations[index],
        };
        // eslint-disable-next-line eqeqeq
        if (state_name == "variation_name") {
          // eslint-disable-next-line eqeqeq
          if (key == "all") {
            obj = {
              ...new_product_variations[index],
              variation_name: {
                en: value,
                fr: value,
              },
            };
          } else {
            obj = {
              ...new_product_variations[index],
              variation_name: {
                // eslint-disable-next-line
                fr:
                  key === "fr"
                    ? value
                    : new_product_variations[index]?.variation_name?.fr ?? "",
                // eslint-disable-next-line
                en:
                  key === "en"
                    ? value
                    : new_product_variations[index]?.variation_name?.en ?? "",
              },
            };
          }
        } else {
          obj = {
            ...new_product_variations[index],
            [state_name]: value,
          };
        }
        new_product_variations[index] = obj;
        setState({
          new_product_variations,
        });
      } else {
        // eslint-disable-next-line eqeqeq
        if (state_name == "is_default") {
          setState({
            product_variations: product_variations?.map(
              (i: any, idx: number) => ({
                ...i,
                // eslint-disable-next-line
                [state_name]: idx == index ? value : false,
              })
            ),
          });
          return;
        }
        let obj = {
          ...product_variations[index],
        };
        // eslint-disable-next-line eqeqeq
        if (state_name == "variation_name") {
          // eslint-disable-next-line eqeqeq
          if (key == "all") {
            obj = {
              ...product_variations[index],
              variation_name: {
                en: value,
                fr: value,
              },
            };
          } else {
            obj = {
              ...product_variations[index],
              variation_name: {
                // eslint-disable-next-line

                fr:
                  key === "fr"
                    ? value
                    : product_variations[index]?.variation_name?.fr ?? "",
                // eslint-disable-next-line

                en:
                  key === "en"
                    ? value
                    : product_variations[index]?.variation_name?.en ?? "",
              },
            };
          }
        } else {
          obj = {
            ...product_variations[index],
            [state_name]: value,
          };
        }
        product_variations[index] = obj;
        setState({
          product_variations,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProduct = async () => {
    try {
      if (handleValidateData()) {
        setState({
          loading_screen: false,
        });
        return;
      }

      const result = await buildDataForPayload();

      // test before hit api
      console.log(result);

      // return;
      const api_resp = await apiServices.postFromApi(
        `/c2c-product-masters`,
        result,
        ""
      );

      if (
        // eslint-disable-next-line

        api_resp?.status === 201
      ) {
        setState({
          loading_screen: false,
        });
        Toast.showSuccess("Your item is now online on Frank Market! 🥳🥳");
        navigate(`/vendors/frank-shops/${id}`);
        //  Alert.alert(
        //    "",
        //    selector.lang == "en"
        //      ? "Your item is now online on Frank Market! 🥳🥳"
        //      : "Votre article est maintenant en ligne sur Frank! 🥳🥳",
        //    [
        //      {
        //        text: "OK",
        //        onPress: () => {
        //          if (from_products) {
        //            navigation.navigate("all_products");
        //            return;
        //          }
        //          navigation.goBack();
        //        },
        //      },
        //    ]
        //  );
      } else {
        Toast.showError(
          api_resp?.error?.message ??
            api_resp?.error?.isNotEmpty ??
            api_resp?.error?.error ??
            `${global_error_message}`
        );
        //  Alert.alert(
        //    "",
        //    api_resp?.error?.message ??
        //      api_resp?.error?.isNotEmpty ??
        //      api_resp?.error?.error ??
        //      ItemSizes.globalErrorMessage(selector.lang)
        //  );
      }
      setState({
        loading_screen: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateProductDetails = async () => {
    try {
      if (handleValidateData()) {
        setState({
          loading_screen: false,
        });
        return;
      }
      const { product_specifications } = state.catalog;
      const api_payload: PAYLOAD_REQ = {
        id: Number(idx),
        shop_id: Number(id),
        category_id: Number(state.selected_category?.id),
        brand_id: Number(state.selected_brand?.value),
        product_title: state.product_title,
        product_desc: state.product_desc,
        images: [],
        videos: [],
        net_weight: Number(state.net_weight),
        weight_unit: 8,
        service_methods: state.service_methods?.map((i: any) => ({
          service_method_id: Number(i?.value),
        })),
      };
      let _images =
        state?.saved_images?.filter(
          (i: any) =>
            // eslint-disable-next-line

            typeof i == "string"
        ) ?? [];
      let _temp_files = [];
      let _formData = new FormData();

      for (const img of state.images) {
        if (img.uri) {
          _formData.append("files", {
            ...img,
            name: img?.fileName,
          });
          _temp_files.push(img);
        }
      }

      if (_temp_files.length > 0) {
        try {
          const images_resp = await axios({
            baseURL: BASE_URL,
            headers: {
              "Content-Type": "multipart/form-data", // this is important
            },
            method: "post",
            url: "upload/upload-multiple",
            data: _formData,
          });

          if (images_resp?.data?.status === 201) {
            for (const _item of images_resp?.data?.data ?? []) {
              _images.push(_item?.uri);
            }
          }
        } catch (error) {
          Toast.showError({
            placement: "top",
            title: "Network Error",
            variant: "left-accent",
            description:
              "This is to inform you that currently we are unable to upload your images, please contact to Frank Support",
          });
        }
      }

      api_payload.images = _images;
      // variation send to update.
      let product_variation = [];

      for (let index = 0; index < state.product_variations?.length; index++) {
        const i = state.product_variations?.[index];
        let images =
          i?.images?.filter(
            (i: any) =>
              // eslint-disable-next-line

              typeof i == "string"
          ) ?? [];

        if (i?.check_newImages?.length > 0) {
          // return
          try {
            let formData = new FormData();
            for (let index = 0; index < i?.check_newImages.length; index++) {
              const item = i?.check_newImages[index];
              const file = new File(
                [item],
                `product_img_${"variation"}_${index}`,
                {
                  type: item.type,
                }
              );

              formData.append("files", file);
            }
            const images_resp = await axios({
              baseURL: BASE_URL,
              headers: {
                "Content-Type": "multipart/form-data",
              },
              method: "post",

              url: "upload/upload-multiple",
              data: formData,
            });

            if (images_resp?.data?.status === 201) {
              for (const _item of images_resp?.data?.data ?? []) {
                images.push(_item?.uri);
              }
            }
          } catch (error) {
            Toast.showError(
              "This is to inform you that currently we are unable to upload your images, please contact to Frank Support"
            );
          }
        }

        let vari = "";
        i?.types?.map(
          (k: any, idx: number) =>
            (vari += `${k}-${handleLocalize(i?.values[idx])}${
              // eslint-disable-next-line
              idx == i?.types?.length - 1 ? "" : ", "
            }`)
        );
        let { product_variations } = state;
        const _name = product_variations[index]?.variation_name;

        product_variation.push({
          // id: Number(i.id) || "",
          ...(i.id && { id: Number(i.id) }), // Add id only if i.id exists

          price: Number(!state.without_variant ? state.price : i.price),
          discount: Number(
            !state.without_variant ? state.discount ?? 0 : i.discount ?? 0
          ),
          sku: !state.without_variant ? state.sku : i.sku,
          is_default: Boolean(i.is_default),
          variation_name: _name
            ? _name
            : {
                en: `${state.product_title?.en ?? ""} ${vari}`,
                fr: `${state.product_title?.fr ?? ""} ${vari}`,
              },
          images: images,
          attributes: i?.attributes,
          video_links: i.video_links,
          net_weight: Number(state.net_weight),
          weight_unit: 8,
        });
      }
      api_payload.product_variations = product_variation;

      let specifications = [];
      for (const p_spec of product_specifications) {
        let specification_value = [];
        for (const spec_val of p_spec.product_specification_values) {
          if (spec_val?.specification_value_id) {
            specification_value.push({
              specification_value_id: spec_val.specification_value_id,
            });
          }
          if (spec_val?.pro_specification_value) {
            specification_value.push({
              pro_specification_value: spec_val.pro_specification_value,
            });
          }
        }
        specifications.push({
          id: p_spec.spec_id,
          specification_name_id: p_spec.specification_name_id,
          product_specification_values: specification_value,
        });
      }

      api_payload.product_specifications = specifications;

      if (product_variation.length < 1) {
        Toast.showError({
          description: "Please add atleast 1 variation",
        });
        setState({
          loading_screen: false,
        });
        return;
      }
      // setState({
      //   loading_screen: false,
      // });
      // return;
      const api_resp = await apiServices.patchFromApi(
        `/c2c-product-masters`,
        api_payload,
        ""
      );
      if (api_resp?.status === 201) {
        setState({
          loading_screen: false,
        });
        Toast.showSuccess("Your item is updated 🥳🥳");
        navigate(`/vendors/frank-shops/${id}`);
        //  Alert.alert(
        //    "",
        //    selector.lang == "en"
        //      ? "Your item is updated 🥳🥳"
        //      : "Your item is updated 🥳🥳",
        //    [
        //      {
        //        text: "OK",
        //        onPress: () => {
        //          if (from_products) {
        //            navigation.navigate("all_products");
        //            return;
        //          }
        //          navigation.goBack();
        //        },
        //      },
        //    ]
        //  );
      } else {
        Toast.showError(
          `${
            api_resp?.error?.message ??
            api_resp?.error?.isNotEmpty ??
            api_resp?.error?.error ??
            api_resp?.isNotEmpty ??
            `${global_error_message}`
          }`
          //  ItemSizes.globalErrorMessage(selector.lang)
        );
      }
      setState({
        loading_screen: false,
      });
    } catch (error) {
      console.log(error);
      setState({
        loading_screen: false,
      });
    }
  };
  // state.loading_screen = false
  const handleValidateData = (): boolean => {
    try {
      const {
        product_variations,
        selected_category,
        product_title,
        images,
        catalog,
        product_specifications_resp,
        selected_brand,
        new_product_variations,
      } = state;
      const { product_specifications } = catalog;
      let error = false;
      // eslint-disable-next-line eqeqeq
      if (images.length == 0) {
        alert("Upload at least 1 image of product");
        return true;
      }

      if (!product_title) {
        alert("Enter product title");
        return true;
      }

      if (!selected_category?.id) {
        alert("Select category");
        return true;
      }

      if (!selected_brand?.value) {
        alert("Select Brand");
        return true;
      }
      if (!state.is_update) {
        if (product_specifications_resp?.length > 0) {
          for (
            let index = 0;
            index < product_specifications_resp?.length;
            index++
          ) {
            const p_spec = product_specifications_resp?.[index];

            if (p_spec?.is_required) {
              if (
                (product_specifications?.[index]?.product_specification_values
                  ?.length ?? 0) === 0
              ) {
                alert(
                  `${handleLocalize(
                    p_spec?.specification_name
                  )} ${handleLocalize({ en: "is required", fr: "est requis" })}`
                );
                error = true;
                break;
              }
            }
          }
          if (error) {
            return true;
          }
        }
      }

      if (product_variations?.length > 0) {
        for (let index = 0; index < product_variations?.length; index++) {
          const p_var = product_variations?.[index];
          if (!p_var?.price) {
            alert("Kindly enter variation price");
            error = true;
            break;
          }

          if (!p_var?.sku) {
            alert("Kindly enter variation product SKU");
            error = true;
            break;
          }

          if (!p_var?.quantity) {
            alert("Kindly enter product quantity");
            error = true;
            break;
          }
        }
        if (error) {
          return true;
        }
      }

      if (state.is_update) {
        if (new_product_variations?.length > 0) {
          for (let index = 0; index < new_product_variations?.length; index++) {
            const p_var = new_product_variations?.[index];
            if (p_var?.images?.length < 1) {
              alert("Kindly upload atleast 1 variation image");
              error = true;
              break;
            }

            if (!p_var?.price) {
              alert("Kindly enter variation price");
              error = true;
              break;
            }
            if (Number(p_var?.price) === 0) {
              alert("Kindly enter variation price");
              error = true;
              break;
            }

            if (!p_var?.sku) {
              alert("Kindly enter variation product SKU");
              error = true;
              break;
            }

            if (!p_var?.quantity) {
              alert("Kindly enter product quantity");
              error = true;
              break;
            }

            if (Number(p_var?.quantity) === 0) {
              alert("Kindly enter product quantity");
              error = true;
              break;
            }
          }
          if (error) {
            return true;
          }
        }
      }

      return error;
    } catch (error) {
      console.log(error);
      return true;
    }
  };

  const buildDataForPayload = async () => {
    try {
      const {
        product_variations,
        selected_category,
        product_title,
        product_desc,
        images,
        catalog,
        selected_brand,
      } = state;
      const { product_specifications } = catalog;
      let final_data: PAYLOAD_REQ = {
        shop_id: Number(id),
        category_id: Number(selected_category?.id),
        brand_id: Number(selected_brand?.value),
        without_variant: state?.without_variant,
        product_title,
        product_desc,
        images: [],
        videos: [],
        video_links: [],
        service_methods: state.service_methods?.map((i: any) => ({
          service_method_id: Number(i?.value),
        })),
        net_weight: Number(state.net_weight),
        weight_unit: 8,
      };

      let formData = new FormData();
      let timestamp = new Date().getTime();

      for (let index = 0; index < images.length; index++) {
        // parent img
        const item = images[index];
        // Creating a new File object with a unique name for each item
        const file = new File([item], `product_img_${timestamp}_${index}`, {
          type: item.type,
        });

        // Append each file individually with a unique name
        formData.append("files", file);
      }

      try {
        const images_resp = await axios({
          baseURL: BASE_URL,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          method: "post",
          url: "upload/upload-multiple",
          data: formData,
        });

        if (images_resp?.data?.status === 201) {
          for (const _item of images_resp.data.data ?? []) {
            final_data.images.push(_item.uri);
          }
        }
      } catch (error) {
        Toast.showError(
          "This is to inform you that currently we are unable to upload your images, please contact to Frank Support"
        );
      }
      let specifications = [];
      for (const p_spec of product_specifications) {
        let specification_value = [];
        for (const spec_val of p_spec.product_specification_values) {
          if (spec_val?.specification_value_id) {
            specification_value.push({
              specification_value_id: spec_val.specification_value_id,
            });
          }
          if (spec_val?.pro_specification_value) {
            specification_value.push({
              pro_specification_value: spec_val.pro_specification_value,
            });
          }
        }
        specifications.push({
          specification_name_id: p_spec.specification_name_id,
          product_specification_values: specification_value,
        });
      }

      final_data.product_specifications = specifications;
      if (product_variations) {
        const product_var = [];
        for (const p_var of product_variations) {
          let obj: any = {
            price: Number(p_var.price ?? 0),
            discount: Number(p_var.discount ?? 0),
            quantity: Number(p_var?.quantity),
            is_default: p_var?.is_default,
            sku: p_var?.sku,
            variation_name: p_var?.variation_name,
            images: [],
            videos: [],
            video_links: [],
            attributes: p_var?.attributes,
            net_weight: Number(state.net_weight),
            weight_unit: 8,
            service_methods: state.service_methods?.map((i: any) => ({
              service_method_id: Number(i?.value),
            })),
          };
          if (p_var?.images?.length > 0) {
            //variation child images
            let formData = new FormData();
            let timestamp = new Date().getTime();

            for (let index = 0; index < p_var?.images?.length; index++) {
              const item = p_var.images[index].uri;
              // Create a new File object with a unique name
              const file = new File(
                [item],
                `product_var_img_${timestamp}_${index}`,
                {
                  type: item.type,
                }
              );

              // Append each file to FormData
              formData.append("files", file);
            }

            try {
              const images_resp = await axios({
                baseURL: BASE_URL,
                headers: {
                  "Content-Type": "multipart/form-data", // this is important
                },
                method: "post",
                url: "upload/upload-multiple",
                data: formData,
              });

              if (images_resp?.data?.status === 201) {
                for (const _item of images_resp?.data?.data ?? []) {
                  obj.images.push(_item?.uri);
                }
              }
            } catch (error) {
              Toast.showError({
                placement: "top",
                title: "Network Error",
                variant: "left-accent",
                description:
                  "This is to inform you that currently we are unable to upload your variations images, please contact to Frank Support",
              });
            }
          }
          let vari = "";
          p_var?.types?.map(
            (k: any, idx: number) =>
              (vari += `${k}-${handleLocalize(p_var?.values[idx])}${
                // eslint-disable-next-line
                idx == p_var?.types?.length - 1 ? "" : ", "
              }`)
          );

          product_var.push({
            ...obj,
            variation_name: obj?.variation_name ?? vari,
          });
        }

        final_data.product_variations = product_var;
        if (product_var.length > 0) {
          return final_data;
        }
      } else {
        final_data.product_variations = [
          {
            ...final_data,
            price: Number(state.price ?? 0),
            discount: Number(state.discount ?? 0),
            quantity: Number(state.quantity),
            sku: state.sku,
            variation_name: product_title,
          },
        ];
      }

      // final_data = {
      //   ...final_data,
      //   price: Number(state.price ?? 0),
      //   discount: Number(state.discount ?? 0),
      //   quantity: Number(state.quantity),
      //   sku: state.sku,
      // };

      // if (final_data?.product_variations?.length > 1) {
      //   final_data.without_variant = true;
      // } else {
      //   final_data.without_variant = false;
      // }
      // final_data.without_variant = state?.without_variant;

      return final_data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  ///// category silder///
  const groupByParentId = (data: any) => {
    const grouped: any = {};
    data?.forEach((item: any) => {
      const parentId = item.parent_id || "root";
      if (!grouped[parentId]) grouped[parentId] = [];
      grouped[parentId].push(item);
    });
    return grouped;
  };

  const renderMenuItems = (parentId: string) => {
    const groupedData = groupByParentId(state.categories);

    return groupedData[parentId]?.map((item: any, index: number) => {
      if (groupedData[item.id]) {
        return (
          <SubMenu key={item.id} label={item.category_name.en}>
            {renderMenuItems(item.id)}
          </SubMenu>
        );
      }
      return (
        <MenuItem
          key={item.id}
          onClick={() => {
            // getCategories(item?.id);
            handleSelectCategory(item?.id);
            setState({
              selected_category: {
                category_name: item?.category_name.en,
                id: item?.id,
              },
              cat_name: item?.category_name.en,
              cat_id: item?.id,
            });
          }}
        >
          {item?.category_name.en}
        </MenuItem>
      );
    });
  };

  ////////////////////////////////////main_part///////////////////////////////

  return (
    <div style={{ background: "#f6f6f6" }}>
      <div className={classes.main_layout_padding}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: 10,
            gap: 10,
          }}
        >
          <Button
            value={state.is_update ? "Update Product" : "Save Product"}
            classesNames={classNames(
              classes.contained_button,
              classes.button_width
            )}
            onClick={() => {
              setState({
                loading_screen: true,
              });
              if (state.is_update) {
                handleUpdateProductDetails();
                return;
              } else {
                handleSaveProduct();
              }
            }}
          />
          <Button
            value={"Back"}
            classesNames={classNames(
              classes.outlined_button,
              classes.button_width
            )}
            onClick={() => {
              navigate(`/vendors/frank-shops/${id}`);
            }}
          />
        </div>
        <div id="parent" style={{ display: "flex", gap: 10 }}>
          <Masonry
            columns={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
            spacing={2}
            defaultHeight={100}
          >
            <div
              style={{
                // width: "50%",
                background: "#ffff",
                borderRadius: 10,
                border: `1px solid ${defaultLabelColors?.main_orange}`,
                height: "max-content",
              }}
              id="right_parent"
            >
              <div
                className={classes.product_info_header}
                style={{
                  alignItems: "center",
                  paddingLeft: 10,
                  borderRadius: 10,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontFamily: "Raleway-SemiBold",
                  }}
                >
                  Product
                </p>
              </div>
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: 16,
                  borderRadius: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 15,
                }}
                id="1"
              >
                <div>
                  <label
                    className={classNames(
                      classes.input_label_style,
                      "RobotoCondensed-Regular"
                    )}
                    style={{ display: "inline-flex" }}
                  >
                    Product Image
                    <br />
                    <span
                      className={classNames(
                        classes.input_label_style,
                        "RobotoCondensed-Regular"
                      )}
                    ></span>
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column-reverse",
                      gap: 10,
                    }}
                  >
                    {state?.images?.length > 0 && (
                      <div
                        style={{
                          height: "15vh",
                          width: "100%",
                          borderRadius: 6,
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        id="image_parent"
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "16px",
                            overflow: "scroll",
                            padding: "10px",
                          }}
                        >
                          {state?.images?.length > 0 && (
                            <div style={{ display: "flex", gap: 10 }}>
                              {state.images?.map((item: any, index: number) => (
                                <div
                                  onClick={() => {}}
                                  key={"PRODUCTIMAGES_" + index + 1}
                                  id={`yo${index + 1}`}
                                >
                                  <div
                                    style={{
                                      height: 100,
                                      width: 100,
                                      borderRadius: 16,
                                      backgroundColor:
                                        defaultLabelColors.light_sand,
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: 10,
                                      borderWidth: 1,
                                      position: "relative",
                                    }}
                                  >
                                    {item?.uri ||
                                    typeof item === "string" ||
                                    item instanceof File ? (
                                      <img
                                        // src={item?.uri ?? item ?? ""}
                                        src={
                                          item instanceof File
                                            ? URL.createObjectURL(item)
                                            : item?.uri ?? item ?? ""
                                        }
                                        alt="not-found" // Always provide an alt attribute for accessibility
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                          borderRadius: "16px", // Use 'px' for pixelvalues
                                          objectFit: "contain", // This is correct for web
                                        }}
                                      />
                                    ) : (
                                      <HistoryEduIcon />
                                    )}
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: -10,
                                        right: -10,
                                      }}
                                    >
                                      <CancelIcon
                                        onClick={() => {
                                          let { images } = state;
                                          images.splice(index, 1);
                                          setState({
                                            images,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div>
                      <p
                        style={{ fontSize: 14, fontFamily: "Raleway-SemiBold" }}
                      >
                        Up to 6 photo, JPG, PNG and GIF format up to 7MB
                      </p>
                    </div>
                    <div
                      style={{
                        height: "10vh",
                        width: "100%",
                        borderRadius: 6,
                        border: "2px solid #EBEBEB",
                        flexDirection: "column",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className={classes?.displayFlex}
                        style={{
                          bottom: 0,
                          right: 0,
                          // padding: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          className={classNames(
                            classes?.displayFlex,
                            state?.result?.images?.length > 5
                              ? classes?.disable_box
                              : ""
                          )}
                        >
                          <label
                            htmlFor="file"
                            className="Roboto-Regular"
                            style={{
                              cursor: "pointer",
                            }}
                          ></label>
                          <div
                            className={classes?.displayFlex}
                            style={{ flexDirection: "column" }}
                          >
                            <UploadImageSvg />

                            <label
                              htmlFor="file_parent"
                              style={{
                                cursor: "pointer",
                                color: defaultLabelColors?.main_orange,
                                fontFamily: "Raleway-SemiBold",
                              }}
                            >
                              Add Image
                            </label>
                          </div>
                        </div>
                        <input
                          style={{
                            display: "none",
                          }}
                          type="file"
                          name="parent_image"
                          accept="image/*"
                          id="file_parent"
                          multiple={true}
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* part_1 */}

                <div style={{}} id="3">
                  <SwitchFeild
                    input_label="Set in Multiple Languages?"
                    check={state?.multi_lang ?? false}
                    onChange={(e: any) => {
                      setState({
                        multi_lang: e.target.checked,
                      });
                    }}
                  />
                </div>

                {state.multi_lang ? (
                  <>
                    <div style={{}}>
                      <InputField
                        // input_label={state.is_update}
                        placeholder={strings.prod_title + " (FR)"}
                        value={state.product_title?.fr}
                        onChange={(event: any) => {
                          setState({
                            product_title: {
                              en: state.product_title?.en,
                              fr: event.target.value,
                            },
                          });
                        }}
                        fullWidth
                        input_label={"Title"}
                        variant={"outlined"}
                        inputProps={{
                          className: classNames(
                            classes?.input_feild,
                            "Roboto-Medium"
                          ),
                        }}
                        classes={classes}
                      />
                    </div>
                    <div style={{}}>
                      <InputField
                        placeholder={strings?.prod_title + " (EN)"}
                        value={state.product_title?.en}
                        onChange={(event) => {
                          setState({
                            product_title: {
                              fr: state.product_title?.fr,
                              en: event.target.value,
                            },
                          });
                        }}
                        fullWidth
                        input_label={"Title"}
                        variant={"outlined"}
                        inputProps={{
                          className: classNames(
                            classes?.input_feild,
                            "Roboto-Medium"
                          ),
                        }}
                        classes={classes}
                      />
                    </div>
                  </>
                ) : (
                  <div style={{}}>
                    <InputField
                      // input_label={state.is_update}
                      placeholder={strings.prod_title}
                      value={state.product_title?.en}
                      onChange={(event) => {
                        setState({
                          product_title: {
                            fr: event.target.value,
                            en: event.target.value,
                          },
                        });
                      }}
                      fullWidth
                      input_label={"Product Title"}
                      variant={"outlined"}
                      // onChange={(e: any) => {}}
                      inputProps={{
                        className: classNames(
                          classes?.input_feild,
                          "Roboto-Medium"
                        ),
                      }}
                      classes={classes}
                    />
                  </div>
                )}

                <ReactQuill
                  value={state?.product_desc}
                  onChange={(e: any) => {
                    setState({ product_desc: e });
                  }}
                  modules={{
                    toolbar: {
                      container: [
                        // ["toolbar"],
                        ["bold", "italic", "underline", "strike"],

                        ["link", "image"],

                        [{ header: 1 }, { header: 2 }], // custom button values
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { list: "check" },
                        ],

                        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],

                        [{ color: [] }],
                      ],
                    },
                  }}
                  theme="snow"
                  style={{ borderRadius: 5 }}
                  readOnly={false}
                />
                <InputField
                  value={state?.net_weight ?? ""}
                  onChange={(event: any) => {
                    if (isNaN(Number(event?.target.value))) {
                      return;
                    }
                    // eslint-disable-next-line eqeqeq
                    if (event?.target.value == "" ||event?.target.value == null
                    ) {
                      setState({ net_weight: event?.target.value });
                      return;
                    }
                    let reg_exp = new RegExp(/^[0-9]/);
                    if (!reg_exp.test(event?.target.value)) {
                      return;
                    }
                    setState({
                      net_weight: event?.target.value,
                    });
                  }}
                  fullWidth
                  input_label={"Net weight (g)"}
                  variant={"outlined"}
                  inputProps={{
                    className: classNames(
                      classes?.input_feild,
                      "Roboto-Medium"
                    ),
                  }}
                  classes={classes}
                />
                {/* main_part2 */}
                <div
                  onClick={() => {
                    setState({
                      show_bottom_sheet: true,
                      sheet_type: "category",
                    });
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      justifyContent: "center",
                    }}
                  >
                    <Grid xs={12} md={6} item>
                      <div>
                        <p
                          style={{
                            color: defaultLabelColors?.main_orange,
                            fontFamily: "Raleway-SemiBold",
                            paddingLeft: 10,
                            fontSize: "17px",
                          }}
                        >
                          Choose Category
                        </p>
                      </div>
                      <div>
                        {state?.categories?.length > 0 ? (
                          <Menu
                            overflow="auto"
                            position="initial"
                            menuButton={
                              <div
                                style={{
                                  height: 43,
                                  width: "100%",
                                  border: "1px solid rgba(0, 0, 0, 0.23)",
                                  borderRadius: 10,
                                }}
                                className={classNames(
                                  classes.justify_space_btw,
                                  // classes.outlined_button
                                  "Roboto-Medium"
                                )}
                              >
                                <div
                                  className={classes.displayFlex}
                                  style={{ paddingLeft: 5 }}
                                >
                                  {state?.cat_name ?? ""}
                                </div>
                                <div className={classes.displayFlex}>
                                  <KeyboardArrowDown />
                                </div>
                              </div>
                            }
                            menuStyle={{ height: "40vh", overflow: "scroll" }}
                          >
                            {renderMenuItems("root")}
                          </Menu>
                        ) : (
                          <div
                            style={{
                              height: 43,
                              width: "100%",
                              border: "1px solid rgba(0, 0, 0, 0.23)",
                              borderRadius: 10,
                            }}
                            className={classNames(
                              classes.justify_space_btw,
                              // classes.outlined_button
                              "Roboto-Medium"
                            )}
                          >
                            <div
                              className={classes.displayFlex}
                              style={{ paddingLeft: 5 }}
                            >
                              {"Loading categories..."}
                            </div>
                            <div className={classes.displayFlex}>
                              <KeyboardArrowDown />
                            </div>
                          </div>
                        )}
                      </div>
                    </Grid>

                    <span
                      style={{
                        fontSize: 12,
                        fontFamily: "Raleway-SemiBold",
                        // color: "color_e",
                        opacity: 0.7,
                        marginTop: 10,
                        // lineHeight: 17,
                      }}
                    >
                      {state?.is_update
                        ? state.category_path
                            ?.map((p: any) => handleLocalize(p))
                            .join(" > ")
                        : state?.cat_id
                        ? getCategoryPath(
                            state?.cat_id,
                            buildHierarchy(state?.categories)
                          )
                            ?.map((p: any) => handleLocalize(p))
                            .join(" > ")
                        : ""}
                    </span>
                  </div>
                </div>

                {state.selected_category?.id && (
                  <div>
                    <SelectField
                      options={
                        state?.all_brands?.map((item: any) => {
                          return {
                            label: handleLocalize(item?.brand_name ?? "") ?? "",
                            value: item?.value,
                          };
                        }) ?? []
                      }
                      input_label={strings.brand}
                      value={state?.selected_brand ?? ""}
                      handleSelect={(name, event, value) => {
                        setState({
                          selected_brand: value,
                          brand_id: value?.value?.id,
                        });
                      }}
                    />
                  </div>
                )}

                <div>
                  <SelectField
                    // search
                    // searchPlaceholder={strings.search}
                    input_label={strings.type_of_delivery}
                    options={
                      state.delivery_methods?.map((item: any) => {
                        return {
                          label: handleLocalize(item?.service_method_name),
                          value: item?.id,
                        };
                      }) ?? []
                    }
                    caretColor={"transparent"}
                    multiple
                    value={state?.service_methods ?? []}
                    handleSelect={(name, event, value) => {
                      // let { service_methods } = state;
                      let new_arr = value;
                      setState({
                        // eslint-disable-next-line
                        ["service_methods"]: new_arr,
                      });
                    }}
                  />
                </div>
                {/* main_part3 */}
                <div
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <SwitchFeild
                    input_label="Is your product contains variants?"
                    check={state?.without_variant}
                    onChange={(event: any) => {
                      setState({
                        without_variant: event.target.checked,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            {/* <div
            id="left_parent"
            style={{
              // width: "50%",
              display: "flex",
              gap: 10,
              flexDirection: "column",
              height: "max-content",
            }}
          > */}
            {!state.without_variant && (
              <div
                id="without variant_parent"
                style={{
                  background: "#ffff",
                  borderRadius: 10,
                  border: `1px solid ${defaultLabelColors?.main_orange}`,
                  maxHeight: "max-content",
                  minHeight: 50,
                }}
              >
                <div
                  className={classes.product_info_header}
                  style={{
                    alignItems: "center",
                    paddingLeft: 10,
                    borderRadius: 10,
                  }}
                >
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Raleway-SemiBold",
                    }}
                  >
                    Product Without Variant
                  </p>
                </div>
                <Grid container spacing={2} padding={"10px"}>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      placeholder={"Price"}
                      value={state?.price}
                      onChange={(event: any) => {
                        if (isNaN(Number(event?.target?.value))) {
                          return;
                        }
                        // eslint-disable-next-line eqeqeq
                        if (event?.target?.value == "" ||event?.target?.value == null
                        ) {
                          setState({ price: event?.target?.value });
                          return;
                        }
                        let reg_exp = new RegExp(/^[0-9]/);
                        if (!reg_exp.test(event?.target?.value)) {
                          return;
                        }
                        setState({
                          price: event?.target?.value,
                        });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                      fullWidth
                      input_label={"Price"}
                      variant={"outlined"}
                      inputProps={{
                        className: classNames(
                          classes?.input_feild,
                          "Roboto-Medium"
                        ),
                      }}
                      classes={classes}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                      value={state?.discount}
                      onChange={(event: any) => {
                        if (isNaN(Number(event?.target?.value))) {
                          return;
                        }
                        // eslint-disable-next-line eqeqeq
                        if (event?.target?.value == "" ||event?.target?.value == null
                        ) {
                          setState({ price: event?.target?.value });
                          return;
                        }
                        let reg_exp = new RegExp(/^[0-9]/);
                        if (!reg_exp.test(event?.target?.value)) {
                          return;
                        }
                        setState({
                          discount: event?.target?.value,
                        });
                      }}
                      fullWidth
                      input_label={"Discount"}
                      variant={"outlined"}
                      inputProps={{
                        className: classNames(
                          classes?.input_feild,
                          "Roboto-Medium"
                        ),
                      }}
                      classes={classes}
                    />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <InputField
                      value={state?.sku}
                      onChange={(event: any) =>
                        setState({
                          sku: event.target.value,
                        })
                      }
                      fullWidth
                      input_label={"SKU"}
                      variant={"outlined"}
                      inputProps={{
                        className: classNames(
                          classes?.input_feild,
                          "Roboto-Medium"
                        ),
                      }}
                      classes={classes}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <InputField
                      //     input_label={state.is_update}
                      placeholder={strings.quantity}
                      value={state?.quantity}
                      onChange={(event: any) => {
                        if (
                          event?.target?.value.includes(".") ||
                          event?.target?.value.includes("،") ||
                          event?.target?.value.includes(",")
                        ) {
                          return;
                        }
                        // eslint-disable-next-line eqeqeq
                        if (event?.target?.value == "" ||event?.target?.value == null
                        ) {
                          setState({ quantity: event?.target?.value });
                          return;
                        }
                        let reg_exp = new RegExp(/^[0-9]/);
                        if (!reg_exp.test(event?.target?.value)) {
                          return;
                        }
                        setState({ quantity: event?.target?.value });
                      }}
                      fullWidth
                      input_label={"Quantity"}
                      variant={"outlined"}
                      inputProps={{
                        className: classNames(
                          classes?.input_feild,
                          "Roboto-Medium"
                        ),
                      }}
                      classes={classes}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            {state.product_specifications_resp?.length > 0 && (
              <div
                id="spec_parent"
                style={{
                  background: "#ffff",
                  borderRadius: 10,
                  border: `1px solid ${defaultLabelColors?.main_orange}`,
                  maxHeight: "max-content",
                  minHeight: 50,
                }}
              >
                <div
                  className={classes.product_info_header}
                  style={{
                    alignItems: "center",
                    paddingLeft: 10,
                    borderRadius: 10,
                  }}
                >
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Raleway-SemiBold",
                    }}
                  >
                    Specification
                  </p>
                </div>
                <div
                  style={{
                    gap: 20,
                    padding: 16,
                    marginTop: 10,
                    borderRadius: 16,
                  }}
                >
                  {state.product_specifications_resp?.map(
                    (prod_spec: any, index: number) => {
                      return (
                        <div key={`PRODUCT_SPEC_${prod_spec?.id}__${index}`}>
                          {prod_spec?.is_drop_down ? (
                            <>
                              {prod_spec?.is_muti_selection ? (
                                <SelectField
                                  style={{
                                    borderWidth: 1,
                                    borderRadius: 16,
                                    paddingHorizontal: 16,
                                    paddingVertical: 14,
                                  }}
                                  handleSelect={(name, event: any, value) => {
                                    handleUpdateItemState(
                                      value,
                                      index,
                                      "multiDropdown"
                                    );
                                  }}
                                  value={
                                    state.catalog?.product_specifications[index]
                                      ?._value ?? []
                                  }
                                  input_label={handleLocalize(
                                    prod_spec?.specification_name
                                  )}
                                  multiple
                                  options={
                                    prod_spec?.specification_values?.map(
                                      (item: any) => {
                                        return {
                                          label: handleLocalize(
                                            item?.specification_value
                                          ),
                                          value: item?.id,
                                          specification_name_id:
                                            item?.specification_name_id,
                                        };
                                      }
                                    ) ?? []
                                  }

                                  //  data={prod_spec?.specification_values ?? []}
                                />
                              ) : (
                                <SelectField
                                  style={{
                                    borderWidth: 1,
                                    borderRadius: 16,
                                    paddingHorizontal: 16,
                                    paddingVertical: 14,
                                  }}
                                  input_label={handleLocalize(
                                    prod_spec?.specification_name
                                  )}
                                  value={
                                    state.catalog?.product_specifications[index]
                                      ?._value ?? null
                                  }
                                  options={
                                    prod_spec?.specification_values?.map(
                                      (item: any) => {
                                        return {
                                          label: handleLocalize(
                                            item?.specification_value
                                          ),
                                          value: item?.id,
                                          specification_name_id:
                                            item?.specification_name_id,
                                        };
                                      }
                                    ) ?? []
                                  }
                                  handleSelect={(
                                    name,
                                    event: any,
                                    value: any
                                  ) =>
                                    handleUpdateItemState(
                                      value,
                                      index,
                                      "dropdown"
                                    )
                                  }
                                />
                              )}
                            </>
                          ) : (
                            <InputField
                              placeholder={handleLocalize(
                                prod_spec?.specification_name
                              )}
                              fullWidth
                              value={
                                state?.catalog?.product_specifications[index]
                                  ?._value ?? ""
                              }
                              variant={"outlined"}
                              onChange={(event: any) =>
                                handleUpdateItemState(
                                  event?.target?.value,
                                  index,
                                  "textInput"
                                )
                              }
                              inputProps={{
                                className: classNames(
                                  classes?.input_feild,
                                  "Roboto-Medium"
                                ),
                              }}
                              classes={classes}
                            />
                            // <div><input></input></div>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )}

            {/* product variant */}
            {state.without_variant && state?.selected_category?.id && (
              <div
                id="variant_parent"
                style={{
                  background: "#ffff",
                  borderRadius: 10,
                  border: `1px solid ${defaultLabelColors?.main_orange}`,
                  maxHeight: "max-content",
                  minHeight: 50,
                }}
              >
                <div
                  className={classes.product_info_header}
                  style={{
                    alignItems: "center",
                    paddingLeft: 10,
                    borderRadius: 10,
                  }}
                >
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Raleway-SemiBold",
                    }}
                  >
                    Product Variant
                  </p>
                </div>

                <div
                  style={{
                    gap: 20,
                    padding: 16,
                    marginTop: 10,
                    borderRadius: 16,
                  }}
                >
                  {/* main_part5 */}
                  {state.variations?.map((vari: any, index: number) => (
                    <div
                      key={`VARIATION_ITEM_${index + 1}`}
                      style={{
                        gap: 16,
                        display: "flex",
                        flexDirection: "column",
                        // background:"red"
                      }}
                    >
                      <SelectField
                        options={state.attr_types}
                        placeholder={handleLocalize(vari?.selected_attr_type)}
                        input_label={handleLocalize(vari?.selected_attr_type)}
                        value={vari?.selected_attr_type}
                        handleSelect={(name, event, value: any) => {
                          let v = state.variations.find(
                            (a: any) =>
                              a.selected_attr_type?.value === value?.value
                          );

                          if (v) {
                            Toast.showError(
                              `You have already selected  ${value?.label}`
                            );
                            let { variations } = state;
                            let selected_var = {
                              ...variations[index],
                              selected_attr_type: null,
                              attr_values: [],
                              selected_attr_values: [],
                            };
                            variations[index] = selected_var;

                            setState({ variations });

                            return;
                          }

                          let { variations } = state;
                          let selected_var = {
                            ...variations[index],
                            selected_attr_type: value,
                            attr_values: state?.attributes?.[value?.label],
                            selected_attr_values: [],
                          };
                          variations[index] = selected_var;

                          setState({ variations });
                        }}
                        // dropdownPosition="auto"
                      />
                      <div
                        style={{
                          display:
                            vari?.attr_values?.length > 0 ? "flex" : "none",
                        }}
                      >
                        <SelectField
                          style={{
                            borderWidth: 1,
                            borderColor: defaultLabelColors.color_e,
                            borderRadius: 16,
                            paddingHorizontal: 16,
                            paddingVertical: 14,
                            backgroundColor: defaultLabelColors.color_e,
                          }}
                          options={
                            vari?.attr_values?.map((item: any) => {
                              return {
                                label: handleLocalize(item?.attribute_value),
                                value: item?.id,
                              };
                            }) ?? []
                          }
                          multiple
                          handleSelect={(name, event: any, value) => {
                            let { variations } = state;
                            let selected_var = {
                              ...variations[index],
                              selected_attr_value_ids: value,
                              selected_attr_values: vari.attr_values?.filter(
                                (a: any) =>
                                  value?.some(
                                    (j: any) =>
                                      Number(a.id) === Number(j?.value)
                                  )
                              ),
                            };
                            variations[index] = selected_var;
                            setState({ variations });
                            debouncedCreateVariations();
                          }}
                          value={vari?.selected_attr_value_ids ?? []}
                        />
                      </div>
                      {/* <div
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              color: defaultLabelColors.red,
                              fontFamily: "Epilogue SemiBold",
                              fontSize: 14,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              let { variations } = state;
                              variations.splice(index, 1);
                              setState({ variations });
                              debouncedCreateVariations();

                              return;
                            }}
                          >
                          </span>
                        </div> */}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          paddingBottom: 10,
                        }}
                      >
                        <Button
                          value={"Remove Variationn"}
                          classesNames={classNames(
                            classes.contained_button_red,
                            classes.button_width
                          )}
                          onClick={() => {
                            let { variations } = state;
                            variations.splice(index, 1);
                            setState({ variations });
                            // debouncedCreateVariations();

                            return;
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  {state?.new_variations?.map((vari: any, index: number) => (
                    <div
                      key={`VARIATION_ITEM_${index + 1}`}
                      style={{
                        gap: 16,
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <SelectField
                        style={{
                          borderWidth: 1,
                          borderColor: defaultLabelColors.color_e,
                          borderRadius: 16,
                          paddingHorizontal: 16,
                          paddingVertical: 14,
                          backgroundColor: defaultLabelColors.color_e,
                        }}
                        options={state.attr_types ?? []}
                        value={vari.selected_attr_type}
                        handleSelect={(value: any) => {
                          let v = state?.variations?.find(
                            (a: any) =>
                              // eslint-disable-next-line
                              a.selected_attr_type?.value == value.value
                          );

                          if (v) {
                            console.log(v);

                            Toast.showError(
                              `You have already selected field ${value?.label}`
                            );
                            let { new_variations } = state;
                            let selected_var = {
                              ...new_variations[index],
                              selected_attr_type: null,
                              attr_values: [],
                              selected_attr_values: [],
                            };
                            new_variations[index] = selected_var;

                            setState({ new_variations });

                            return;
                          }

                          let { new_variations } = state;
                          let selected_var = {
                            ...new_variations[index],
                            selected_attr_type: value,
                            attr_values: state?.attributes?.[value?.label],
                            selected_attr_values: [],
                          };
                          new_variations[index] = selected_var;

                          setState({ new_variations });
                        }}
                        placeholder={"Select Variant Type"}
                      />
                      <div
                        style={{
                          display:
                            vari?.attr_values?.length > 0 ? "flex" : "none",
                        }}
                      >
                        <SelectField
                          style={{
                            borderWidth: 1,
                            borderColor: defaultLabelColors.color_e,
                            borderRadius: 16,
                            paddingHorizontal: 16,
                            paddingVertical: 14,
                            backgroundColor: defaultLabelColors.color_e,
                          }}
                          options={
                            vari?.attr_values?.map((item: any) => {
                              return {
                                label: handleLocalize(item?.attribute_value),
                                value: item?.id,
                              };
                            }) ?? []
                          }
                          handleSelect={(name, event, value) => {
                            let { new_variations } = state;
                            let selected_var = {
                              ...new_variations[index],
                              selected_attr_value_ids: value,
                              selected_attr_values: vari.attr_values?.filter(
                                (a: any) =>
                                  value?.some(
                                    (j: any) => Number(a.id) === Number(j.value)
                                  )
                              ),
                            };
                            new_variations[index] = selected_var;
                            setState({ new_variations });
                            debouncedCreateVariations();
                          }}
                          multiple
                          value={vari?.selected_attr_value_ids ?? []}
                        />
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          value={"Remove Variation"}
                          classesNames={classNames(
                            classes.contained_button_red,
                            classes.button_width
                          )}
                          onClick={() => {
                            let { new_variations } = state;
                            new_variations.splice(index, 1);
                            setState({ new_variations });
                            debouncedCreateVariations();

                            return;
                          }}
                        />
                      </div>
                    </div>
                  ))}

                  <div>
                    <Button
                      classesNames={classNames(
                        classes.contained_button_blue,
                        classes.button_width
                      )}
                      value={"Add Variation"}
                      onClick={() => {
                        let { variations, is_update } = state;

                        if (is_update) {
                          if (!variations[variations.length - 1]) {
                            variations.push({});
                            setState({ variations });
                            return;
                          }
                          if (
                            !variations[variations.length - 1]
                              ?.selected_attr_value_ids
                          ) {
                            Toast.showError(
                              "Please complete the previous variation data then procceed to next!!"
                            );
                            return;
                          }
                          if (
                            variations[variations.length - 1]
                              ?.selected_attr_value_ids?.length < 1
                          ) {
                            Toast.showError(
                              "Please complete the previous variation data then procceed to next!!"
                            );
                            return;
                          }
                          variations.push({});
                          setState({ variations });
                          // &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
                        } else {
                          if (!variations[variations.length - 1]) {
                            variations.push({});
                            setState({ variations });
                            return;
                          }
                          if (
                            !variations[variations.length - 1]
                              ?.selected_attr_value_ids
                          ) {
                            Toast.showError(
                              "Please complete the previous variation data then procceed to next!!"
                            );
                            return;
                          }
                          if (
                            variations[variations.length - 1]
                              ?.selected_attr_value_ids?.length < 1
                          ) {
                            Toast.showError(
                              "Please complete the previous variation data then procceed to next!!"
                            );
                            return;
                          }
                          variations.push({});
                          setState({ variations });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {state?.product_variations?.length > 0 && (
              <>
                {state.without_variant && (
                  <>
                    {state?.product_variations?.map(
                      (_var: any, index: number) => (
                        <div
                          key={`PRODUCT_VAROATIO_${index + 1}`}
                          id={`PRODUCT_VAROATIO_${index + 1}`}
                          style={{
                            background: "#ffff",
                            borderRadius: 10,
                            border: `1px solid ${defaultLabelColors?.main_orange}`,
                            maxHeight: "max-content",
                            minHeight: 50,
                          }}
                        >
                          <div
                            className={classes.product_info_header}
                            style={{
                              alignItems: "center",
                              paddingLeft: 10,
                              borderRadius: 10,
                            }}
                          >
                            <p
                              style={{
                                fontSize: 16,
                                fontFamily: "Raleway-SemiBold",
                              }}
                            >
                              {`Product Variation ${index + 1}`}
                            </p>
                          </div>
                          <div style={{ padding: "0px 10px" }}>
                            <label
                              className={classNames(
                                classes.input_label_style,
                                "RobotoCondensed-Regular"
                              )}
                              style={{ display: "inline-flex" }}
                            >
                              Product Image
                              <br />
                              <span
                                className={classNames(
                                  classes.input_label_style,
                                  "RobotoCondensed-Regular"
                                )}
                              >
                                {" "}
                              </span>
                            </label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column-reverse",
                                gap: 10,
                              }}
                            >
                              {_var?.images?.length > 0 && (
                                <div
                                  style={{
                                    height: "15vh",
                                    width: "100%",
                                    borderRadius: 6,
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  id="image_child1"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "16px",
                                      overflow: "scroll",
                                      padding: "10px",
                                    }}
                                  >
                                    <div style={{ display: "flex", gap: 10 }}>
                                      {_var.images?.map(
                                        (img: any, iamge_index: number) => (
                                          <div
                                            key={
                                              "CHILD_IMAGE_SCROLL_INDEX_" +
                                              index +
                                              1
                                            }
                                          >
                                            <div
                                              style={{
                                                height: 100,
                                                width: 100,
                                                borderRadius: 16,
                                                alignItems: "center",
                                                backgroundColor:
                                                  defaultLabelColors.light_sand,
                                                justifyContent: "center",
                                                padding: 10,
                                                borderWidth: 1,
                                                position: "relative",
                                              }}
                                            >
                                              {img?.uri ||
                                              typeof img === "string" ||
                                              img?.uri instanceof File ? (
                                                <img
                                                  // src={img?.uri ?? img ?? ""}
                                                  src={
                                                    img?.uri instanceof File
                                                      ? URL.createObjectURL(
                                                          img.uri
                                                        )
                                                      : img?.uri ?? img ?? ""
                                                  }
                                                  alt="not-found" // Always provide an alt attribute for accessibility
                                                  style={{
                                                    height: "100%",
                                                    width: "100%",
                                                    borderRadius: "16px", // Use 'px' for pixel values
                                                    objectFit: "contain", // This is correct for web
                                                  }}
                                                />
                                              ) : (
                                                <HistoryEduIcon />
                                              )}
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  top: -3,
                                                  right: 0,
                                                }}
                                              >
                                                <CancelIcon
                                                  onClick={() => {
                                                    let { product_variations } =
                                                      state;
                                                    let { images } =
                                                      product_variations[index];
                                                    images.splice(
                                                      iamge_index,
                                                      1
                                                    );
                                                    setState({
                                                      product_variations,
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div>
                                <p
                                  style={{
                                    fontSize: 14,
                                    fontFamily: "Raleway-SemiBold",
                                  }}
                                >
                                  Up to 6 photo, JPG, PNG and GIF format up to
                                  7MB
                                </p>
                              </div>{" "}
                              <div
                                style={{
                                  height: "10vh",
                                  width: "100%",
                                  borderRadius: 6,
                                  border: "2px solid #EBEBEB",
                                  flexDirection: "column",
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  className={classes?.displayFlex}
                                  style={{
                                    top: 0,
                                    left: 0,
                                    // padding: "10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div
                                    className={classNames(
                                      classes?.displayFlex,
                                      state?.result?.images?.length > 5
                                        ? classes?.disable_box
                                        : ""
                                    )}
                                    style={{ flexDirection: "column" }}
                                  >
                                    <label
                                      htmlFor={`file_var${index + 1}`}
                                      className="Roboto-Regular"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <UploadImageSvg />
                                    </label>
                                    <div className={classes?.displayFlex}>
                                      <label
                                        htmlFor={`file_var${index + 1}`}
                                        className="Raleway-SemibBold"
                                        style={{
                                          cursor: "pointer",
                                          color:
                                            defaultLabelColors?.main_orange,
                                          fontFamily: "Raleway-SemiBold",
                                        }}
                                      >
                                        Add Image
                                      </label>
                                    </div>
                                  </div>
                                  <input
                                    style={{
                                      display: "none",
                                    }}
                                    type="file"
                                    name="parent_image"
                                    accept="image/*"
                                    id={`file_var${index + 1}`}
                                    multiple={true}
                                    onChange={(e: any) =>
                                      handleImageUploadVariation(
                                        e,
                                        true,
                                        index,
                                        0
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div style={{ padding: "10px 10px" }}>
                            <div
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <SwitchFeild
                                input_label="Make this product Default"
                                onChange={async (e: any) => {
                                  handleChangeVariations(
                                    "is_default",
                                    e.target.checked,
                                    index
                                  );
                                }}
                                check={_var?.is_default}
                              />
                            </div>

                            <span
                              style={{
                                fontSize: 16,
                                fontFamily: "Epilogue",
                                width: "100%",
                              }}
                              onClick={() => {
                                let vari = "";
                                _var?.types?.map(
                                  (k: any, idx: number) =>
                                    (vari += `${k}-${handleLocalize(
                                      _var?.values[idx]
                                    )}${
                                      // eslint-disable-next-line
                                      idx == _var?.types?.length - 1 ? "" : ", "
                                    }`)
                                );
                                let { product_variations } = state;
                                const _name =
                                  product_variations[index]?.variation_name;

                                const obj = {
                                  ...product_variations[index],
                                  variation_name: _name
                                    ? _name
                                    : {
                                        en: `${
                                          state.product_title?.en ?? ""
                                        } ${vari}`,
                                        fr: `${
                                          state.product_title?.fr ?? ""
                                        } ${vari}`,
                                      },
                                  is_editable: true,
                                };
                                product_variations[index] = obj;
                                setState({
                                  product_variations,
                                });
                              }}
                            >
                              {handleLocalize(_var?.variation_name) ? (
                                handleLocalize(_var?.variation_name)
                              ) : (
                                <>
                                  {_var?.types?.map((k: any, idx: number) => (
                                    <span
                                      style={{
                                        fontSize: 16,
                                        fontFamily: "Epilogue",
                                      }}
                                      key={`VARIATION_NAME_${k}_${idx + 1}`}
                                    >
                                      {k}-{handleLocalize(_var?.values[idx])}
                                    </span>
                                  ))}
                                </>
                              )}
                            </span>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                {" "}
                                <InputField
                                  placeholder={"Price"}
                                  input_label={"Price"}
                                  value={_var.price ?? ""}
                                  onChange={(event: any) => {
                                    if (isNaN(Number(event?.target.value))) {
                                      return;
                                    }
// eslint-disable-next-line eqeqeq
                                    if (event?.target.value == "" ||event?.target.value == null
                                    ) {
                                      handleChangeVariations(
                                        "price",
                                        event?.target.value,
                                        index
                                      );
                                      return;
                                    }
                                    let reg_exp = new RegExp(/^[0-9]/);
                                    if (!reg_exp.test(event?.target.value)) {
                                      return;
                                    }
                                    handleChangeVariations(
                                      "price",
                                      event?.target.value,
                                      index
                                    );
                                  }}
                                  fullWidth
                                  variant={"outlined"}
                                  inputProps={{
                                    className: classNames(
                                      classes?.input_feild,
                                      "Roboto-Medium"
                                    ),
                                  }}
                                  classes={classes}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                {" "}
                                <InputField
                                  placeholder={"Discount"}
                                  input_label={"Discount"}
                                  value={_var.discount ?? ""}
                                  onChange={(event: any) => {
                                    if (isNaN(Number(event?.target.value))) {
                                      return;
                                    }
                                    if (
                                      Number(event?.target.value) >
                                      Number(_var.price)
                                    ) {
                                      Toast.showError({
                                        description:
                                          "Discount should be less than price",
                                      });
                                      return;
                                    }
// eslint-disable-next-line eqeqeq
                                    if (event?.target.value == "" ||event?.target.value == null
                                    ) {
                                      handleChangeVariations(
                                        "discount",
                                        event?.target.value,
                                        index
                                      );
                                      return;
                                    }
                                    let reg_exp = new RegExp(/^[0-9]/);
                                    if (!reg_exp.test(event?.target.value)) {
                                      return;
                                    }
                                    handleChangeVariations(
                                      "discount",
                                      event?.target.value,
                                      index
                                    );
                                  }}
                                  fullWidth
                                  variant={"outlined"}
                                  inputProps={{
                                    className: classNames(
                                      classes?.input_feild,
                                      "Roboto-Medium"
                                    ),
                                  }}
                                  classes={classes}
                                />
                              </Grid>
                              <Grid item xs={12} md={7}>
                                {" "}
                                <InputField
                                  placeholder={"SKU"}
                                  input_label={"SKU"}
                                  value={_var?.sku ?? ""}
                                  onChange={(event: any) =>
                                    handleChangeVariations(
                                      "sku",
                                      event.target.value,
                                      index
                                    )
                                  }
                                  fullWidth
                                  variant={"outlined"}
                                  inputProps={{
                                    className: classNames(
                                      classes?.input_feild,
                                      "Roboto-Medium"
                                    ),
                                  }}
                                  classes={classes}
                                />
                              </Grid>
                              <Grid item xs={12} md={5}>
                                {" "}
                                <InputField
                                  placeholder={strings.quantity}
                                  input_label={strings.quantity}
                                  value={_var?.quantity ?? ""}
                                  onChange={(event: any) => {
                                    if (
                                      event?.target.value.includes(".") ||
                                      event?.target.value.includes("،") ||
                                      event?.target.value.includes(",")
                                    ) {
                                      return;
                                    }
                                    // eslint-disable-next-line eqeqeq
                                    if (event?.target.value == "" ||event?.target.value == null
                                    ) {
                                      handleChangeVariations(
                                        "quantity",
                                        event?.target.value,
                                        index
                                      );
                                      return;
                                    }
                                    let reg_exp = new RegExp(/^[0-9]/);
                                    if (!reg_exp.test(event?.target.value)) {
                                      return;
                                    }
                                    handleChangeVariations(
                                      "quantity",
                                      event?.target.value,
                                      index
                                    );
                                  }}
                                  fullWidth
                                  variant={"outlined"}
                                  inputProps={{
                                    className: classNames(
                                      classes?.input_feild,
                                      "Roboto-Medium"
                                    ),
                                  }}
                                  classes={classes}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      )
                    )}

                    {state?.new_product_variations?.map(
                      (_var: any, index: number) => (
                        <div
                          key={`PRODUCT_VAROATIO_${index + 1}`}
                          id={`PRODUCT_VAROATIO_${index + 1}`}
                          style={{
                            background: "#ffff",
                            borderRadius: 10,
                            border: `1px solid ${defaultLabelColors?.main_orange}`,
                            maxHeight: "max-content",
                            minHeight: 50,
                          }}
                        >
                          <div
                            className={classes.product_info_header}
                            style={{
                              alignItems: "center",
                              paddingLeft: 10,
                              borderRadius: 10,
                            }}
                          >
                            <p
                              style={{
                                fontSize: 16,
                                fontFamily: "Raleway-SemiBold",
                              }}
                            >
                              {`Product Variation ${index + 1} new`}
                            </p>
                          </div>
                          <div style={{ padding: "0px 10px" }}>
                            <label
                              className={classNames(
                                classes.input_label_style,
                                "RobotoCondensed-Regular"
                              )}
                              style={{ display: "inline-flex" }}
                            >
                              Product Image
                              <br />
                              <span
                                className={classNames(
                                  classes.input_label_style,
                                  "RobotoCondensed-Regular"
                                )}
                              >
                                {" "}
                              </span>
                            </label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column-reverse",
                                gap: 10,
                              }}
                            >
                              {_var?.images?.length > 0 && (
                                <div
                                  style={{
                                    height: "15vh",
                                    width: "100%",
                                    borderRadius: 6,
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  id="image_child1"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "16px",
                                      overflow: "scroll",
                                      padding: "10px",
                                    }}
                                  >
                                    <div style={{ display: "flex", gap: 10 }}>
                                      {_var.images?.map(
                                        (img: any, iamge_index: number) => (
                                          <div
                                            key={
                                              "CHILD_IMAGE_SCROLL_INDEX_" +
                                              index +
                                              1
                                            }
                                          >
                                            <div
                                              style={{
                                                height: 100,
                                                width: 100,
                                                borderRadius: 16,
                                                alignItems: "center",
                                                backgroundColor:
                                                  defaultLabelColors.light_sand,
                                                justifyContent: "center",
                                                padding: 10,
                                                borderWidth: 1,
                                                position: "relative",
                                              }}
                                            >
                                              {img?.uri ||
                                              typeof img === "string" ||
                                              img?.uri instanceof File ? (
                                                <img
                                                  // src={img?.uri ?? img ?? ""}
                                                  src={
                                                    img?.uri instanceof File
                                                      ? URL.createObjectURL(
                                                          img.uri
                                                        )
                                                      : img?.uri ?? img ?? ""
                                                  }
                                                  alt="not-found" // Always provide an alt attribute for accessibility
                                                  style={{
                                                    height: "100%",
                                                    width: "100%",
                                                    borderRadius: "16px", // Use 'px' for pixel values
                                                    objectFit: "contain", // This is correct for web
                                                  }}
                                                />
                                              ) : (
                                                <HistoryEduIcon />
                                              )}
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  top: -3,
                                                  right: 0,
                                                }}
                                              >
                                                <CancelIcon
                                                  onClick={() => {
                                                    let { product_variations } =
                                                      state;
                                                    let { images } =
                                                      product_variations[index];
                                                    images.splice(
                                                      iamge_index,
                                                      1
                                                    );
                                                    setState({
                                                      product_variations,
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div>
                                <p
                                  style={{
                                    fontSize: 14,
                                    fontFamily: "Raleway-SemiBold",
                                  }}
                                >
                                  Up to 6 photo, JPG, PNG and GIF format up to
                                  7MB
                                </p>
                              </div>{" "}
                              <div
                                style={{
                                  height: "10vh",
                                  width: "100%",
                                  borderRadius: 6,
                                  border: "2px solid #EBEBEB",
                                  flexDirection: "column",
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  className={classes?.displayFlex}
                                  style={{
                                    top: 0,
                                    left: 0,
                                    // padding: "10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div
                                    className={classNames(
                                      classes?.displayFlex,
                                      state?.result?.images?.length > 5
                                        ? classes?.disable_box
                                        : ""
                                    )}
                                    style={{ flexDirection: "column" }}
                                  >
                                    <label
                                      htmlFor={`file_var${index + 1}`}
                                      className="Roboto-Regular"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <UploadImageSvg />
                                    </label>
                                    <div className={classes?.displayFlex}>
                                      <label
                                        htmlFor={`file_var${index + 1}`}
                                        className="Raleway-SemibBold"
                                        style={{
                                          cursor: "pointer",
                                          color:
                                            defaultLabelColors?.main_orange,
                                          fontFamily: "Raleway-SemiBold",
                                        }}
                                      >
                                        Add Image
                                      </label>
                                    </div>
                                  </div>
                                  <input
                                    style={{
                                      display: "none",
                                    }}
                                    type="file"
                                    name="parent_image"
                                    accept="image/*"
                                    id={`file_var${index + 1}`}
                                    multiple={true}
                                    onChange={(e: any) =>
                                      handleImageUploadVariation(
                                        e,
                                        true,
                                        index,
                                        0
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div style={{ padding: "10px 10px" }}>
                            <div
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <SwitchFeild
                                input_label="Make this product Default"
                                onChange={async (e: any) => {
                                  handleChangeVariations(
                                    "is_default",
                                    e.target.checked,
                                    index
                                  );
                                }}
                                check={_var?.is_default}
                              />
                            </div>
                            <div
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              {_var?.is_editable ? (
                                <div style={{ width: "90%" }}>
                                  {state.multi_lang ? (
                                    <div style={{ gap: 16 }}>
                                      <InputField
                                        // input_label={state.is_update}
                                        placeholder={`Variation Name (FR)`}
                                        value={_var.variation_name?.fr}
                                        onChange={(event: any) =>
                                          handleChangeVariations(
                                            "variation_name",
                                            event.target.value,
                                            index,
                                            "fr"
                                          )
                                        }
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                onClick={() =>
                                                  handleChangeVariations(
                                                    "is_editable",
                                                    false,
                                                    index
                                                  )
                                                }
                                                edge="end"
                                                style={{ marginRight: 10 }}
                                              >
                                                <CheckCircleOutlineIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                      <InputField
                                        // input_label={state.is_update}
                                        placeholder={`Variation Name (EN)`}
                                        value={_var?.variation_name?.en}
                                        onChange={(event: any) =>
                                          handleChangeVariations(
                                            "variation_name",
                                            event.target.value,
                                            index,
                                            "fr"
                                          )
                                        }
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                onClick={() =>
                                                  handleChangeVariations(
                                                    "is_editable",
                                                    false,
                                                    index
                                                  )
                                                }
                                                edge="end"
                                                style={{ marginRight: 10 }}
                                              >
                                                <CheckCircleOutlineIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                        fullWidth
                                        input_label={"label"}
                                        variant={"outlined"}
                                        inputProps={{
                                          className: classNames(
                                            classes?.input_feild,
                                            "Roboto-Medium"
                                          ),
                                        }}
                                        classes={classes}
                                      />
                                    </div>
                                  ) : (
                                    <InputField
                                      placeholder={"Variation Name"}
                                      value={_var?.variation_name?.en ?? ""}
                                      fullWidth
                                      input_label={"Total Orders"}
                                      variant={"outlined"}
                                      inputProps={{
                                        className: classNames(
                                          classes?.input_feild,
                                          "Roboto-Medium"
                                        ),
                                      }}
                                      classes={classes}
                                      onChange={(event: any) =>
                                        handleChangeVariations(
                                          "variation_name",
                                          event.target.value,
                                          index,
                                          "all"
                                        )
                                      }
                                      // placeholderTextColor={"lightgray"}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              onClick={() =>
                                                handleChangeVariations(
                                                  "is_editable",
                                                  false,
                                                  index
                                                )
                                              }
                                              edge="end"
                                              style={{ marginRight: 10 }}
                                            >
                                              <CheckCircleOutlineIcon color="warning" />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                </div>
                              ) : (
                                <span
                                  style={{
                                    fontSize: 16,
                                    fontFamily: "Epilogue",
                                    width: "100%",
                                  }}
                                  onClick={() => {
                                    let vari = "";
                                    _var?.types?.map(
                                      (k: any, idx: number) =>
                                        (vari += `${k}-${handleLocalize(
                                          _var?.values[idx]
                                        )}${
                                          // eslint-disable-next-line
                                          idx == _var?.types?.length - 1
                                            ? ""
                                            : ", "
                                        }`)
                                    );
                                    let { product_variations } = state;
                                    const _name =
                                      product_variations[index]?.variation_name;

                                    const obj = {
                                      ...product_variations[index],
                                      variation_name: _name
                                        ? _name
                                        : {
                                            en: `${
                                              state.product_title?.en ?? ""
                                            } ${vari}`,
                                            fr: `${
                                              state.product_title?.fr ?? ""
                                            } ${vari}`,
                                          },
                                      is_editable: true,
                                    };
                                    product_variations[index] = obj;
                                    setState({
                                      product_variations,
                                    });
                                  }}
                                >
                                  {handleLocalize(_var?.variation_name) ? (
                                    handleLocalize(_var?.variation_name)
                                  ) : (
                                    <>
                                      {_var?.types?.map(
                                        (k: any, idx: number) => (
                                          <span
                                            style={{
                                              fontSize: 16,
                                              fontFamily: "Epilogue",
                                            }}
                                            key={`VARIATION_NAME_${k}_${
                                              idx + 1
                                            }`}
                                          >
                                            {/* {k} */}
                                            {k}-
                                            {handleLocalize(_var?.values[idx])}
                                          </span>
                                        )
                                      )}
                                    </>
                                  )}
                                </span>
                              )}
                              <div
                                onClick={() => {
                                  alert(
                                    "  Warning ⚠️ Are you sure you want to remove this variation? This action is irreversible and cannot be undone."
                                    //modal banna ha
                                  );
                                }}
                              >
                                {/* <FrankTrashIcon
                                              height={24}
                                              width={24}
                                              color={defaultLabelColors .main_orange}
                                            /> */}
                              </div>
                            </div>

                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                {" "}
                                <InputField
                                  placeholder={"Price"}
                                  input_label={"Price"}
                                  value={_var.price ?? ""}
                                  onChange={(event: any) => {
                                    if (isNaN(Number(event?.target.value))) {
                                      return;
                                    }
                                    // eslint-disable-next-line eqeqeq
                                    if (event?.target.value == "" ||event?.target.value == null
                                    ) {
                                      handleChangeVariations(
                                        "price",
                                        event?.target.value.replaceAll(" ", ""),
                                        index
                                      );
                                      return;
                                    }
                                    let reg_exp = new RegExp(/^[0-9]/);
                                    if (!reg_exp.test(event?.target.value)) {
                                      return;
                                    }
                                    handleChangeVariations(
                                      "price",
                                      event?.target.value.replaceAll(" ", ""),
                                      index
                                    );
                                  }}
                                  fullWidth
                                  variant={"outlined"}
                                  inputProps={{
                                    className: classNames(
                                      classes?.input_feild,
                                      "Roboto-Medium"
                                    ),
                                  }}
                                  classes={classes}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                {" "}
                                <InputField
                                  placeholder={"Discount"}
                                  input_label={"Discount"}
                                  value={_var.discount ?? ""}
                                  onChange={(event: any) => {
                                    if (isNaN(Number(event?.target.value))) {
                                      return;
                                    }
                                    if (
                                      Number(event?.target.value) >
                                      Number(_var.price)
                                    ) {
                                      Toast.showError({
                                        description:
                                          "Discount should be less than price",
                                      });
                                      return;
                                    }
// eslint-disable-next-line eqeqeq
                                    if (event?.target.value == "" ||event?.target.value == null
                                    ) {
                                      handleChangeVariations(
                                        "discount",
                                        event?.target.value.replaceAll(" ", ""),
                                        index
                                      );
                                      return;
                                    }
                                    let reg_exp = new RegExp(/^[0-9]/);
                                    if (!reg_exp.test(event?.target.value)) {
                                      return;
                                    }
                                    handleChangeVariations(
                                      "discount",
                                      event?.target.value.replaceAll(" ", ""),
                                      index
                                    );
                                  }}
                                  fullWidth
                                  variant={"outlined"}
                                  inputProps={{
                                    className: classNames(
                                      classes?.input_feild,
                                      "Roboto-Medium"
                                    ),
                                  }}
                                  classes={classes}
                                />
                              </Grid>
                              <Grid item xs={12} md={7}>
                                {" "}
                                <InputField
                                  placeholder={"SKU"}
                                  input_label={"SKU"}
                                  value={_var?.sku ?? ""}
                                  onChange={(event: any) =>
                                    handleChangeVariations(
                                      "sku",
                                      event.target.value,
                                      index
                                    )
                                  }
                                  fullWidth
                                  variant={"outlined"}
                                  inputProps={{
                                    className: classNames(
                                      classes?.input_feild,
                                      "Roboto-Medium"
                                    ),
                                  }}
                                  classes={classes}
                                />
                              </Grid>
                              <Grid item xs={12} md={5}>
                                {" "}
                                <InputField
                                  placeholder={strings.quantity}
                                  input_label={strings.quantity}
                                  value={_var?.quantity ?? ""}
                                  onChange={(event: any) => {
                                    if (
                                      event?.target.value.includes(".") ||
                                      event?.target.value.includes("،") ||
                                      event?.target.value.includes(",")
                                    ) {
                                      return;
                                    }
                                    // eslint-disable-next-line eqeqeq
                                    if (event?.target.value == "" ||event?.target.value == null
                                    ) {
                                      handleChangeVariations(
                                        "quantity",
                                        event?.target.value,
                                        index
                                      );
                                      return;
                                    }
                                    let reg_exp = new RegExp(/^[0-9]/);
                                    if (!reg_exp.test(event?.target.value)) {
                                      return;
                                    }
                                    handleChangeVariations(
                                      "quantity",
                                      event?.target.value,
                                      index
                                    );
                                  }}
                                  fullWidth
                                  variant={"outlined"}
                                  inputProps={{
                                    className: classNames(
                                      classes?.input_feild,
                                      "Roboto-Medium"
                                    ),
                                  }}
                                  classes={classes}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
              </>
            )}
          </Masonry>
        </div>
      </div>
    </div>
  );
}
