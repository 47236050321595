import {
  AddCommentOutlined
} from "@mui/icons-material";
import classNames from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import apiServices from "services/RequestHandler";
import { allComments } from "store/commentsReducer/indext";
import Button from "ui-components/Button";
import InputField from "ui-components/TextFeild";
import Toast from "ui-components/Toast";
import { global_error_message } from "utils/helper";
  
  export default function Comments(props: any) {
    const { classes } = props;
    const dispatch = useDispatch<any>();
    // const comment_slicer = useSelector((state: RootState) => state.comments);
  
    const [state, setState] = React.useReducer(
      (state: any, newState: any) => ({ ...state, ...newState }),
      {}
    );
    const handleAddComment = async () => {
      try {
        if (!state.new_comment) {
          Toast.showError("Comment cannot be left black");
          return;
        }

        const response = await apiServices.postFromApi(
          "/comments",
          {
            resource_id: props?.id, //Number(),
            content: state.new_comment,
          },
          ""
        );
        // console.log("checking::", props?.id)

        if (response.status === 201) {
          Toast.showSuccess("Comment Posted Successfully");
          setState({
            new_comment: "",
          });
                  dispatch(allComments({ end_point: `comments/${props?.id}` }));

        //   handleGetLogs();
          return;
        }
        Toast.showError(
          response.error.message ??
            response.error.error ??
            response.error.isNotEmpty ??
            global_error_message
        );
      } catch (error) {
        console.log(error);
      }
    };
    const handleInputChange = (event: any, type?: string) => {
        try {
          const { value, name, checked } = event?.target;
          setState({
            [name]: type !== "check" ? value : checked,
          });
        } catch (error) {
          console.log(error);
        }
      };
  
    return (
      <div 
      // className={classes.main_layout_padding}
      >
  
        <div
          style={{
            background: "white",
            marginTop: 15,
            borderRadius: 6,
            boxShadow:
              "0px 0px 0px -2px rgba(0,0,0,0.2),0px 0px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
            padding: 24,
          }}
        >
          <div
            className={classNames(classes?.active_tab)}
            style={{ marginBottom: 5, paddingLeft: 4 }}
          >
            Add Comment
          </div>
          <InputField
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            type={"text"}
            value={state.new_comment}
            name={"new_comment"}
            placeholder={"e.g. Please do not modify this user"}
            input_label={""}
            onChange={handleInputChange}
            inputProps={{
              className: classNames(classes?.input_feild, "Roboto-Medium"),
            }}
            multiline
            rows={4}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: 15,
            }}
          >
            <Button
              value={"Add Comment"}
              classesNames={classNames(
                classes.button_width,
                classes.outlined_button
              )}
              onClick={handleAddComment}
              startIcon={<AddCommentOutlined />}
            />
          </div>
        </div>
  
  
      </div>
    );
  }
  