/*
  Created By Muhammad Hassaan
  Date 17 April 2023
*/
import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

export default function ViewImageModal(props: any) {
  const { activity, image_source, handleCloseModal } = props;
  return (
    <Dialog
      open={activity??false}
      maxWidth="sm"
      // hideBackdrop
      PaperProps={{
        style: {
          overflow: "hidden",
        },
      }}
      onClose={handleCloseModal}
    >
      <DialogTitle
        style={{
          padding: "10px 20px 0px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Close style={{ cursor: "pointer" }} onClick={handleCloseModal} />
      </DialogTitle>
      <DialogContent
        style={{
          overflow: "hidden",
        }}
      >
        <img
          src={image_source}
          style={{ height: 400, width: 400, objectFit: "contain" }}
          alt={`vied_image`}
        />
      </DialogContent>
    </Dialog>
  );
}
