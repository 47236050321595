import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import apiServices from "services/RequestHandler";
import { RootState } from "store";
import { setSubTitle, setTitle } from "store/breadcrumbReducer";
import {
  BusinessCustomerRequestById,
  BzCustomerAnnoucementAsSender,
  BzCustomerAnnoucementAsTransporter,
  BzCustomerFrankShopAsCust,
} from "store/customerReducer";
import ActivityLogs from "ui-components/ActivityLog";
import Button from "ui-components/Button";
import Comments from "ui-components/Comments";
import Datatable from "ui-components/Datatable";
import DraggableDialog from "ui-components/DraggableDialog";
import LoadingComponent from "ui-components/LoadinComponent";
import Toast from "ui-components/Toast";
import {
  getStatusLabel,
  getStatusLabelColor,
  numberFormatWithoutCurrency,
} from "utils/helper";
import BzPrfile from "./BzProfile";
import Profile from "./Profile";
import ProfileInfo from "./ProfileInfo";
import Status from "./Status";
import TransporterProfile from "./TransporterProfile";
// import Profile from "views/Vendors/MiniShop/MiniShopByID/Profile";
// import ProfileInfo from "views/Vendors/MiniShop/MiniShopByID/ProfileInfo";
// import Status from "views/Vendors/MiniShop/MiniShopByID/Status";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ height: 370, marginTop: value === 0 ? 30 : 5 }}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function BusCustomerById(props: any) {
  const { classes } = props;
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const BusinessSlicer = useSelector((state: RootState) => state.customers);
  //   const miniShop = useSelector((state: RootState) => state.vendors);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      e_merchant: null,
      manage_busCustomer: false,
      selected_tab: 0,
      is_changed: false,
    }
  );
  let { id } = useParams();
  React.useEffect(() => {
    getBusCusomter();
    getCustomerAnnounce();
    getFrankshopOrderAsCust();
    getCustomerAnnounceAsTransporter();
    // eslint-disable-next-line
  }, [id]);

  React.useEffect(() => {
    setProductnOrder();
    // eslint-disable-next-line
  }, [JSON.stringify(BusinessSlicer?.single_business_customer_data)]);

  const getBusCusomter = async () => {
    try {
      dispatch(
        BusinessCustomerRequestById({
          end_point: `/c2c/${id}`,
          page: 1,
          limit: 50,
        } as any)
      );
      // setState({bus_customer:BusinessSlicer?.single_business_customer_data})

      dispatch(setTitle("Customer " as any));
      dispatch(setSubTitle(`${BusinessSlicer?.single_business_customer_data?.name??""}` as any))
    } catch (error) {
      console.log(error);
    }
  };

  const setProductnOrder = (): void => {
    try {
      //   for ordder
      let _order = [];
      const total_order =
        BusinessSlicer?.single_business_customer_data?.orders?.length;
      if (total_order) {
        for (let index = 0; index < total_order; index++) {
          const item =
            BusinessSlicer?.single_business_customer_data?.orders[index];
          _order?.push([
            `ODR-${item?.id}` || "- - -",
            // item?.order_date ?? "- - -",
            item?.order_date
              ? moment(item?.order_date).format("DD-MM-YYYY")
              : "- - -",
            item?.expected_delivery_to
              ? moment(item?.expected_delivery_to).format("DD-MM-YYYY")
              : "- - -",
            // getStatusLabel(item?.order_status)  ?? "- - -",
            {
              name: getStatusLabel(item?.order_status as string) ?? "-",
              is_bool: true,
              color:
                item?.order_status === "pending"
                  ? getStatusLabelColor(item?.order_status as string)
                  : "black",
              style: {
                color:
                  getStatusLabelColor(item?.order_status as string) ?? "black",
              },
            },
            // item?.shipping_rate,
            "€ " +
              numberFormatWithoutCurrency(Number(item?.shipping_rate ?? 0)),

            // item?.order_date || "- - -",
            // item?.expected_delivery_to
            //   ? moment(item?.expected_delivery_to).format("YYYY-MMM-DD")
            //   : "- - -",
            // ,
            // item?.shipping_rate || "- - -",
            // item?.service_method || "- - -",
          ]);
        }
      }
      let _data = BusinessSlicer?.single_business_customer_data;

      setState({
        bus_customer: {
          ..._data,
          selected_is_active: {
            label: _data?.is_active ? "Active" : "Inactive",
            value: _data?.is_active ?? false,
          },
          selected_is_verified: {
            label: _data?.is_verified ? "Verified" : "Not Verified",
            value: _data?.is_verified ?? false,
          },
        },
        // products: _products,
        orders: _order,
      });
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(state?.products)
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setState({
      selected_tab: newValue,
    });
  };

  const handleSelect = (name: any, event: any, values: any): void => {
    try {
      setState({
        bus_customer: {
          ...state.bus_customer,
          [name]: values?.value ?? null, 
          [`selected_${[name]}`]: values ? values : null,
        },
        is_changed: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateStatus = async (): Promise<void> => {
    try {
      let final_obj = {
        id: Number(state.bus_customer?.id),
        is_active: state.bus_customer?.selected_is_active?.value ?? false,
        is_verified: state.bus_customer?.selected_is_verified?.value ?? false,
      };
      // console.log(final_obj);
      const resp = await apiServices.postFromApi(
        "c2c-shop-configure/activate",
        final_obj,
        ""
      );
      if (resp.status === 201) {
        Toast.showSuccess(`Status updated successfully`);
        setState({
          manage_busCustomer: false,
        });
        getBusCusomter();
      } else {
        Toast.showError(
          // "Unable perform action, please try again, if errors again then contact to our Support Team"
          resp.error.message
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  // const handleClickRow = (event: any, row: any, index: any) => {
  //   try {
  //     if (row[0]) {
  //       const selected_customer = Number(row[0].replace("ODR-", ""));

  //       setState({
  //         selected_customer,
  //       });
  //     } else {
  //       setState({
  //         selected_customer: null,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const getCustomerAnnounce = async () => {
    try {
      // const resp=
      dispatch(
        BzCustomerAnnoucementAsSender({
          end_point: "/c2c/get-my-shipments",
          id: id,
          page: 1,
          limit: 50,
        } as any)
      );
    } catch (error) {
      console.log(error);
    }
  };
  const getFrankshopOrderAsCust = async () => {
    try {
      // const resp=apiServices.postFromApi("/c2c/get-shop-order-as-customers",{id:id},"")

      dispatch(
        BzCustomerFrankShopAsCust({
          end_point: "/c2c/get-shop-order-as-customers",
          id: id,
          page: 1,
          limit: 50,
        } as any)
      );
    } catch (error) {
      console.log(error);
    }
  };
  const getCustomerAnnounceAsTransporter = async () => {
    try {
      // const resp=apiServices.postFromApi("/c2c/get-shop-order-as-customers",{id:id},"")

      dispatch(
        BzCustomerAnnoucementAsTransporter({
          end_point: "c2c/get-shipment-orders-as-transporters",
          id: id,
          page: 1,
          limit: 50,
        } as any)
      );
    } catch (error) {
      console.log(error);
    }
  };


  ///FILTER MY ANNOUCEMENT
  const getMyAnnoucementsByFilter = async (
    start_date: any,
    end_date: any,
    page_number: any,
    limit: any,
    searching: any
  ): Promise<void> => {
    try {
      // let query = `/c2c`;
      if (start_date && end_date) {
        // query += `&start=${start_date}&end=${end_date}`;
      }
      dispatch(
        BzCustomerAnnoucementAsSender({
          end_point: "/c2c/get-my-shipments",
          id: id,
          page: page_number,
          limit: limit,
          start_date:start_date,
          end_date:end_date,
        } as any)
      );
    } catch (error) {
      console.log(error);
    }
  };
  ///FILTER Transporter Order
  const getTransporterOrdersByFilter = async (
    start_date: any,
    end_date: any,
    page_number: any,
    limit: any,
    searching: any
  ): Promise<void> => {
    try {
      // let query = `/c2c`;
      if (start_date && end_date) {
        // query += `&start=${start_date}&end=${end_date}`;
      }
      dispatch(
        BzCustomerAnnoucementAsTransporter({
          end_point: "c2c/get-shipment-orders-as-transporters",
          id: id,
          page: page_number,
          limit: limit,
          start_date:start_date,
          end_date:end_date,
        } as any))
    } catch (error) {
      console.log(error);
    }
  };
   ///FILTER Transporter Order
   const getFrankShopOrdersByFilter = async (
    start_date: any,
    end_date: any,
    page_number: any,
    limit: any,
    searching: any
  ): Promise<void> => {
    try {
     
      dispatch(
        BzCustomerFrankShopAsCust({
          end_point: "/c2c/get-shop-order-as-customers",
          id: id,
          page: page_number,
          limit:limit,
          start_date:start_date,
          end_date:end_date
        } as any)
      );
    } catch (error) {
      console.log(error);
    }
  };
  
  return (
    <div className={classes.main_layout_padding}>
      <LoadingComponent activity={BusinessSlicer.activity} />
      <Grid container spacing={2}>
        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1 }}>
            {/* {BusinessSlicer.panels?.profile &&
            BusinessSlicer.panels?.status &&
            BusinessSlicer.panels?.orders ? (
              <span
                className={classNames(classes.toggle_sections)}
                onClick={() => dispatch(togglePanels(false as any))}
              >
                Collapse All
              </span>
            ) : (
              <span
                className={classNames(classes.toggle_sections)}
                onClick={() => dispatch(togglePanels(true as any))}
              >
                Expand All
              </span>
            )} */}
          </div>
          <Button
            value={"Manage"}
            classesNames={classNames(
              classes.button_width,
              classes.contained_button
            )}
            onClick={() => setState({ manage_busCustomer: true })}
          />
          &emsp;
          <Button
            value={"Back to All"}
            classesNames={classNames(
              classes.button_width,
              classes.outlined_button
            )}
            onClick={() => navigate(-1)}
          />
        </Grid>

        <Grid xs={12} md={6} item>
          <Profile
            {...props}
            {...state}
            panel_head={"Customer Detail"}
            // from_vendors={true}
            details={BusinessSlicer?.single_business_customer_data}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          item
          sx={{ display: "flex", gap: "10px", flexDirection: "column" }}
        >
          <Status
            // total order,is_verified,is_active dhekna a status me
            {...props}
            {...state}
            panel_head={"Status & Summary"}
            // from_vendors={true}
            details={BusinessSlicer?.single_business_customer_data}
          />
          <BzPrfile
            // total order,is_verified,is_active dhekna a status me
            {...props}
            {...state}
            panel_head={"Business Profile"}
            // from_vendors={true}
            details={BusinessSlicer?.single_business_customer_data}
          />
          <TransporterProfile
            // total order,is_verified,is_active dhekna a status me
            {...props}
            {...state}
            panel_head={"Transporter Profile"}
            // from_vendors={true}
            details={BusinessSlicer?.single_business_customer_data}
          />
        </Grid>
        {/* <Grid xs={12} item>
          <Orders
            orders={state.products}
            {...props}
            {...state}
            panel_head={"All Products"}
          />
        </Grid> */}

        <Grid xs={12} item>
          <Accordion
            defaultExpanded={false}
            // expanded={
            //   state?.from_vendors
            //     ? BusinessSlicer.panels.status
            //     : miniShop.panels.status
            // }
            className={classNames(classes.accordion_container)}
          >
            <AccordionSummary
              className={classNames(classes.accordion_head_container)}
              expandIcon={
                <ExpandMore
                  className={classes.accordion_icons}
                  style={{ fontSize: 24 }}
                />
              }
            >
              <span
                className={classNames(classes.accordion_heading)}
                style={{ flex: 1 }}
              >
                {"My Annoucement"}
              </span>
            </AccordionSummary>

            <AccordionDetails className={classes.accordion_detail_padding}>
              <Datatable
                columns={[
                  { name: "Announcement ID", alignment: "left" },
                  { name: "Annoucenment Created at", alignment: "left" },
                  { name: "Delivery Date", alignment: "left" },
                  { name: "Status", alignment: "left" },
                  { name: "Delivery Cost", alignment: "left" },
                  { name: "Delivery Type", alignment: "left" },

                  // { name: "Active", alignment: "left" },

                  //   { name: "Store Owner", alignment: "left" },
                ]}
                rows={BusinessSlicer?.my_annc_business_customer || []}
                onRowClick={(event, row, index) => {
                  if (row[0]) {
                    const selected_customer = Number(
                      row[0].replace("ODR-", "")
                    );
                    navigate(`/orders/announcements/${selected_customer}`);
                  }
                }}
                min_width={"100%"}
                // ariaLabel={"Product Table"}
                // searchSize={"none"}
                classes={classes}
                radioButton={true}
                // show_header={true}
                revert_header_color={true}
                filterByType={false}
                filterByTypeOptions={false}
                enablePagination
                previous_count={Number(
                  BusinessSlicer.my_annc__business_pagnate_data
                    ?.previous_count ?? 0
                )}
                current_count={Number(
                  BusinessSlicer.my_annc__business_pagnate_data
                    ?.current_count ?? 0
                )}
                total_records={Number(
                  BusinessSlicer?.my_annc__business_pagnate_data?.total_count ??
                    0
                )}
                count={Number(
                  BusinessSlicer?.my_annc__business_pagnate_data?.total_pages ??
                    1
                )}
                handleUpdateRecords={getMyAnnoucementsByFilter}
                // show_button
                // btn_label={"Open"}
                // btn_disable={state.selected_customer ? false : true}
                // onButtonClick={() =>
                //   navigate(`/orders/c2c-business/${state?.selected_customer}`)
                // }
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid xs={12} item>
          <Accordion
            defaultExpanded={false}
            // expanded={
            //   state?.from_vendors
            //     ? BusinessSlicer.panels.status
            //     : miniShop.panels.status
            // }
            className={classNames(classes.accordion_container)}
          >
            <AccordionSummary
              className={classNames(classes.accordion_head_container)}
              expandIcon={
                <ExpandMore
                  className={classes.accordion_icons}
                  style={{ fontSize: 24 }}
                />
              }
            >
              <span
                className={classNames(classes.accordion_heading)}
                style={{ flex: 1 }}
              >
                {"Transport Order (as transporter)"}
              </span>
            </AccordionSummary>

            <AccordionDetails className={classes.accordion_detail_padding}>
              <Datatable
                columns={[
                  { name: "Announcement ID", alignment: "left" },
                  // { name: " Date", alignment: "left" },
                  { name: "Announcement Created at", alignment: "left" },

                  { name: "Delivery Date", alignment: "left" },
                  { name: "Order Status", alignment: "left" },
                  { name: "Delivery Cost", alignment: "left" },
                  { name: "Delivery Type", alignment: "left" },

                  // { name: "Active", alignment: "left" },
                  //   { name: "Store Owner", alignment: "left" },
                ]}
                rows={
                  BusinessSlicer?.order_as_transporter_business_customer || []
                }
                // onRowClick={}
                onRowClick={(event, row, index) => {
                  if (row[0]) {
                    const selected_customer = Number(
                      row[0].replace("ODR-", "")
                    );
                    navigate(`/orders/announcements/${selected_customer}`);
                  }
                }}
                min_width={"100%"}
                // ariaLabel={"Product Table"}
                // searchSize={"none"}
                classes={classes}
                radioButton={true}
                // show_header={true}
                revert_header_color={true}
                filterByType={false}
                filterByTypeOptions={false}
                enablePagination
                previous_count={Number(
                  BusinessSlicer?.order_as_transporter_business_pagnate_data
                    ?.previous_count ?? 0
                )}
                current_count={Number(
                  BusinessSlicer.order_as_transporter_business_pagnate_data
                    ?.current_count ?? 0
                )}
                total_records={Number(
                  BusinessSlicer?.order_as_transporter_business_pagnate_data?.total_count ??
                    0
                )}
                count={Number(
                  BusinessSlicer?.order_as_transporter_business_pagnate_data?.total_pages ??
                    1
                )}
                handleUpdateRecords={getTransporterOrdersByFilter}
               
                // show_button
                // btn_label={"Open"}
                // btn_disable={state.selected_customer ? false : true}
                // onButtonClick={() =>
                //   navigate(`/orders/c2c-business/${state?.selected_customer}`)
                // }
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid xs={12} item>
          <Accordion
            defaultExpanded={false}
            // expanded={
            //   state?.from_vendors
            //     ? BusinessSlicer.panels.status
            //     : miniShop.panels.status
            // }
            className={classNames(classes.accordion_container)}
          >
            <AccordionSummary
              className={classNames(classes.accordion_head_container)}
              expandIcon={
                <ExpandMore
                  className={classes.accordion_icons}
                  style={{ fontSize: 24 }}
                />
              }
            >
              <span
                className={classNames(classes.accordion_heading)}
                style={{ flex: 1 }}
              >
                {"Frank Shop Order (as customer)"}
              </span>
            </AccordionSummary>

            <AccordionDetails className={classes.accordion_detail_padding}>
              <Datatable
                columns={[
                  { name: "Order ID", alignment: "left" },
                  { name: "Order Date", alignment: "left" },
                  { name: "Delivery Date", alignment: "left" },
                  { name: "Order Status", alignment: "left" },
                  { name: "Order Amount", alignment: "left" },
                  // { name: "Active", alignment: "left" },
                  //   { name: "Store Owner", alignment: "left" },
                ]}
                rows={
                  BusinessSlicer?.frnk_order_as_cust_business_customer || []
                }
                onRowClick={(event, row, index) => {
                  if (row[0]) {
                    const selected_customer = Number(
                      row[0].replace("ODR-", "")
                    );
                    navigate(`/orders/frank-shop-orders/${selected_customer}`);
                  }
                }}
                min_width={"100%"}
                // ariaLabel={"Product Table"}
                // searchSize={"none"}
                classes={classes}
                radioButton={true}
                // show_header={true}
                revert_header_color={true}
                filterByType={false}
                filterByTypeOptions={false}
                enablePagination
                previous_count={Number(
                  BusinessSlicer?.frnk_order_as_cust_business_pagnate_data
                    ?.previous_count ?? 0
                )}
                current_count={Number(
                  BusinessSlicer.frnk_order_as_cust_business_pagnate_data
                    ?.current_count ?? 0
                )}
                total_records={Number(
                  BusinessSlicer?.frnk_order_as_cust_business_pagnate_data?.total_count ??
                    0
                )}
                count={Number(
                  BusinessSlicer?.frnk_order_as_cust_business_pagnate_data?.total_pages ??
                    1
                )}
                handleUpdateRecords={getFrankShopOrdersByFilter}
               
                // show_button
                // btn_label={"Open"}
                // btn_disable={state.selected_customer ? false : true}
                // onButtonClick={() =>
                //   navigate(`/orders/c2c-business/${state?.selected_customer}`)
                // }
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid xs={12} item>
          <Accordion
            defaultExpanded={false}
            // expanded={
            //   state?.from_vendors
            //     ? BusinessSlicer.panels.status
            //     : miniShop.panels.status
            // }
            className={classNames(classes.accordion_container)}
          >
            <AccordionSummary
              className={classNames(classes.accordion_head_container)}
              expandIcon={
                <ExpandMore
                  className={classes.accordion_icons}
                  style={{ fontSize: 24 }}
                />
              }
            >
              <span
                className={classNames(classes.accordion_heading)}
                style={{ flex: 1 }}
              >
                {"Comment"}
              </span>
            </AccordionSummary>

            <AccordionDetails
            // className={classes.accordion_detail_padding}
            >
              <Comments
                id={state?.bus_customer?.id}
                margin={0}
                classes={classes}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Activity log */}
        <Grid xs={12} item>
          <Accordion
            defaultExpanded={false}
            // expanded={false}
            //   state?.from_vendors
            //     ? BusinessSlicer.panels.status
            //     : miniShop.panels.status
            // }
            className={classNames(classes.accordion_container)}
          >
            <AccordionSummary
              className={classNames(classes.accordion_head_container)}
              expandIcon={
                <ExpandMore
                  className={classes.accordion_icons}
                  style={{ fontSize: 24 }}
                />
              }
            >
              <span
                className={classNames(classes.accordion_heading)}
                style={{ flex: 1 }}
              >
                {"Activity Logs"}
              </span>
            </AccordionSummary>

            <AccordionDetails
            // className={classes.accordion_detail_padding}
            >
              <ActivityLogs
                id={state?.bus_customer?.id}
                margin={0}
                classes={classes}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <DraggableDialog
        open={state.manage_busCustomer}
        handleClose={() => setState({ manage_busCustomer: false })}
        dialog_title={"Manage Business Customer"}
        classes={classes}
        maxWidth={"lg"}
        dialogBody={
          <DialogContent dividers>
            <Tabs
              value={state.selected_tab}
              onChange={handleChangeTab}
              textColor="inherit"
              style={{ borderBottom: "2px solid lightgray" }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#ee6931",
                },
              }}
              sx={{
                "& .MuiTabs-scroller": {
                  "&. MuiTabs-flexContainer": {
                    zIndex: 2,
                    position: "relative",
                  },
                },
              }}
            >
              <Tab
                className={classNames(
                  state.selected_tab === 0
                    ? classes?.active_tab
                    : classes?.inactive_tab
                )}
                label={`Customer Profile`}
                {...a11yProps(0)}
              />
              {/* <Tab
                className={classNames(
                  state.selected_tab === 1
                    ? classes?.active_tab
                    : classes?.inactive_tab
                )}
                label="Space Documents"
                {...a11yProps(1)}
              /> */}
            </Tabs>
            <SwipeableViews
              index={state.selected_tab}
              onChangeIndex={(val) => handleChangeTab(null as any, val)}
              style={{ marginTop: 15 }}
            >
              <TabPanel value={state.selected_tab} index={0}>
                <ProfileInfo
                  {...state}
                  {...props}
                  // e_merchant={state.from_vendors}
                  handleSelect={handleSelect}
                  //   details={vendor}
                  details={BusinessSlicer?.single_business_customer_data}
                />
              </TabPanel>
            </SwipeableViews>
          </DialogContent>
        }
        dialogFooter={
          <DialogActions style={{ padding: "20px" }}>
            <div style={{ flex: 1 }}>
              {/* {state.selected_tab === 0 ? ( */}
              <Button
                classesNames={classNames(
                  classes.button_width,
                  classes.outlined_button
                )}
                disable={state.is_changed ? false : true}
                onClick={handleUpdateStatus}
                style={{ marginRight: 20 }}
                value={"Update"}
              />
            </div>
            {/* {state.selected_tab === 0 && (
              <Button
                classesNames={classNames(
                  classes.button_width,
                  classes.outlined_button
                )}
                style={{ marginRight: 20 }}
                value={"Copy as Text"}
              />
            )} */}
            {/* <Button
              classesNames={classNames(
                classes.button_width,
                classes.outlined_button
              )}
              value="Print"
              style={{ marginRight: 20 }}
            /> */}
            <Button
              classesNames={classNames(
                classes.button_width,
                classes.contained_button
              )}
              value="Close"
              onClick={() => {
                setState({ manage_busCustomer: false });
                let _data = BusinessSlicer?.single_business_customer_data;
                setState({
                  bus_customer: {
                    ..._data,
                    selected_is_active: {
                      label: _data?.is_active ? "Active" : "Inactive",
                      value: _data?.is_active ?? false,
                    },
                    selected_is_verified: {
                      label: _data?.is_verified ? "Verified" : "Not Verified",
                      value: _data?.is_verified ?? false,
                    },
                  },
                });
              }}
            />
          </DialogActions>
        }
      />
    </div>
  );
}
