import title_img from "assets/images/frank_favicon.png";
import PropTypes from "prop-types"; // Import PropTypes
import { Helmet } from "react-helmet";

// interface ReactHelmetProps {
//   title?: string;
// }
export default function ReactHelmet(props:any) {
  const {title}=props;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="icon" href={title_img} type="image/png" />
      </Helmet>
    </div>
  );
}
ReactHelmet.defaultProps = {
  title: "Frank",
};

// Define prop types
ReactHelmet.propTypes = {
  title: PropTypes.string,
};