import PropTypes from "prop-types";
// material-ui
import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import { Logout, Settings } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { NewProfileIcon } from "assets/images/FrankIcons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Toast from "ui-components/Toast";
import { defaultLabelColors } from "utils/constants";
import {
  clearAllDataFromLocalStorage,
  getDataFromLocalStorage,
} from "utils/LocalStore.util";
// import Settings from "views/Settings";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
// const elem = document.documentElement;

const Header = ({ handleLeftDrawerToggle, drawerOpen }: any) => {
  const theme = useTheme() as any;
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [user, setUser] = useState(getDataFromLocalStorage("user"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    try {
      clearAllDataFromLocalStorage();
      Toast.showSuccess("Logged Out Successfully");
      setTimeout(() => {
        navigate("/login");
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 270,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
          justifyContent: drawerOpen ? "flex-end" : "flex-start",
        }}
      >
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            sx={{
              transition: "all .2s ease-in-out",
              background: "transparent",
              color: "white",
              "&:hover": {
                color: theme.palette.frank.appPrimary,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <MenuIcon
              style={{
                fontSize: 38,
              }}
            />
          </Avatar>
        </ButtonBase>
      </Box>

      {/* header search */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* notification & profile */}
      {/* {fullScreen ? (
        <Tooltip title="Exit Full Screen" placement="bottom">
          <FullscreenExit
            style={{
              fontSize: 28,
              color: "white",
              cursor: "pointer",
              marginRight: 20,
            }}
            onClick={closeFullScreen}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Full Screen" placement="bottom">
          <Fullscreen
            style={{
              fontSize: 28,
              color: "white",
              cursor: "pointer",
              marginRight: 20,
            }}
            onClick={openFullScreen}
          />
        </Tooltip>
      )}

     */}

      {/* <Person2Outlined
        style={{
          fontSize: 28,
          color: "white",
          padding: 20,
          cursor: "pointer",
        }}
        onClick={handleClick}
      /> */}
      <div style={{ display: "flex", gap: 20, marginTop: 5 }}>
        <div>
          <p
            style={{
              fontFamily: "Raleway-Variable",
              color: defaultLabelColors?.main_blue,
              fontSize: 32,
              fontWeight: 600,
            }}
          >{`${user?.first_name || ""} ${user?.last_name || ""}`}</p>
        </div>
        <div
          style={{
            fontSize: 28,
            color: "white",
            marginRight: 20,
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          <NewProfileIcon />
        </div>
      </div>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        sx={{ top: 30 }}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: 150,
            padding: "0px 10px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem style={{ padding: "0px 10px", opacity: 1 }} disabled>
          <ListItemIcon>
            <Avatar sx={{ width: "1.25rem", height: "1.25rem" }} />
          </ListItemIcon>
          <ListItemText>
            {`${user?.first_name || ""} ${user?.last_name || ""}`}
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          style={{ padding: "10px 10px" }}
          onClick={handleClose}
          //  component={Link}
          //  to={link.profile}
        >
          <ListItemIcon>
            <Settings style={{ color: "gray" }} />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
        <MenuItem style={{ padding: "10px 10px" }} onClick={handleLogout}>
          <ListItemIcon>
            <Logout style={{ color: "gray" }} />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
  drawerOpen: PropTypes.bool,
};

export default Header;
