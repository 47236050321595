import { FormControlLabel, Switch, SwitchProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { defaultLabelColors } from "utils/constants";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    backgroundColor:"#ffff !important",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      backgroundColor:"#ffff !important",
      BorderColor:"red",


      "& + .MuiSwitch-track": {
        // backgroundColor: theme.palette.mode === "dark" ? "#8fa6ff" : "#8fa6ff",
        // backgroundColor:"red",
        backgroundColor: "#ff8754",
        BorderColor:"red",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    backgroundColor:"#ffff !important"
  },
  
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: defaultLabelColors.color_switch,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface SwitchFeildProps {
  input_label: string;
  required?: boolean;
  check?: boolean;
  onChange?: (event: any) => void;
  disabled?: boolean;
  classes?: any;
  name?: string;
}

export default function SwitchFeild(props: SwitchFeildProps) {
  const { check, input_label, onChange, disabled, name } =
    props;
  return (
    <div>
      <FormControlLabel
        checked={check}
        label=""
        name={name}
        control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
        onChange={onChange}
        disabled={disabled}
      />
      {input_label && (
        <label
          className="Raleway-SemiBold"
          style={{ marginRight: 15,color:defaultLabelColors?.main_orange ,
fontFamily:"Raleway-SemiBold",
            
          }}
        >
          {input_label}
        </label>
      )}
    </div>
  );
}
