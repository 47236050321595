import { Grid } from "@mui/material";
import classNames from "classnames";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setTitle } from "store/breadcrumbReducer";
// import { allCommssionsRequest } from "store/commissionReducer";
import apiServices from "services/RequestHandler";
import { allBlogsCategory } from "store/BlogsReducer";
import Button from "ui-components/Button";
import Datatable from "ui-components/Datatable";
import DraggableDialog from "ui-components/DraggableDialog";
import LoadingComponent from "ui-components/LoadinComponent";
import InputField from "ui-components/TextFeild";
import Toast from "ui-components/Toast";
import { global_error_message } from "utils/helper";

export default function BlogCategory(props: any) {
  const { classes } = props;
  const dispatch = useDispatch<any>();
  // const navigate = useNavigate();
  const blog_slicer = useSelector((state: RootState) => state.blogs);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {}
  );

  useEffect(() => {
    dispatch(setTitle("Blogs Category" as any));
    dispatch(allBlogsCategory({ end_point: "/c2c-blog-categories" }));

    return () => {
      dispatch(setTitle("" as any));
    };
    // eslint-disable-next-line
  }, []);

  const getblogbyFilter = async (
    start_date: any,
    end_date: any,
    page_number: any,
    limit: any,
    // eslint-disable-next-line
    searching: any
  ): Promise<void> => {
    try {
      // eslint-disable-next-line
      let query = `c2c-blogs?page=${page_number}&limit=${limit}`;
      if (start_date && end_date) {
              // eslint-disable-next-line
        query += `&start=${start_date}&end=${end_date}`;
      }
        // dispatch(allblogs({ end_point: query }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickRow = (event: any, row: any, index: any): void => {
    try {
      if (row) {
        setState({
          cat_id: row[0],
          is_modal_update: true,
          name_en: blog_slicer?.all_blog_cat_data[0]?.category_name?.en,
          name_fr: blog_slicer?.all_blog_cat_data[0]?.category_name?.fr,
          desc_en: blog_slicer?.all_blog_cat_data[0]?.category_desc?.en,
          desc_fr: blog_slicer?.all_blog_cat_data[0]?.category_desc?.fr,
        });
        console.log(index, blog_slicer?.all_blog_cat_data[0], "0");

        // navigate(`/blogs/manage/${row[0]}`);
      } else {
        setState({
          selected_blog_id: null,
          selected_blog: null,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputChange = (event: any): void => {
    try {
      const { name, value } = event.target;
      setState({
        [name]: value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const CreateCategory = async () => {
    try {
      if (!state?.name_en) {
        Toast.showError("Please fill Category name");
        return;
      }
      if (!state?.name_fr) {
        Toast.showError("Please fill Category name");
        return;
      }

      let send_object: any = {
        category_name: {
          fr: state?.name_en,
          en: state?.name_fr,
        },
        category_desc: {
          fr: state?.desc_en ?? "",
          en: state?.desc_fr ?? "",
        },
      };

      const resp = await apiServices.postFromApi(
        "/c2c-blog-categories",
        send_object,
        ""
      );

      if (resp?.status === 201 || resp?.status === 200) {
        console.log("Category created successfully");
        setState({
          is_modal: false,
          cat_id: null,
          name_fr: "",
          name_en: "",
          desc_en: "",
          desc_fr: "",
        });
        dispatch(allBlogsCategory({ end_point: "/c2c-blog-categories" }));
      } else {
        Toast.showError(global_error_message);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const UpdateCategory = async () => {
    try {
      if (!state?.name_en) {
        Toast.showError("Please fill Category name");
        return;
      }
      if (!state?.name_fr) {
        Toast.showError("Please fill Category name");
        return;
      }
      if (!state?.cat_id) {
        Toast.showError("Something went wrong in category id");
        return;
      }

      let send_object: any = {
        id: Number(state?.cat_id),
        category_name: {
          fr: state?.name_en,
          en: state?.name_fr,
        },
        category_desc: {
          fr: state?.desc_en ?? "",
          en: state?.desc_fr ?? "",
        },
      };

      const resp = await apiServices.patchFromApi(
        "/c2c-blog-categories",
        send_object,
        ""
      );

      if (resp?.status === 201 || resp?.status === 200) {
        console.log("Category Updated successfully");
        // navigate("/blogs-category")
        setState({
          is_modal_update: false,
          cat_id: null,
          name_fr: "",
          name_en: "",
          desc_en: "",
          desc_fr: "",
        });
        dispatch(allBlogsCategory({ end_point: "/c2c-blog-categories" }));
      } else {
        Toast.showError(global_error_message);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const DeleteCat = async () => {
    try {
      if (state?.cat_id) {
        const resp = await apiServices.patchFromApi(
          `/c2c-blog-categories/${state?.cat_id}`,
          {},
          ""
        );

        if (resp?.status === 201 || resp?.status === 200) {
          console.log("Category Deleted successfully");
          // navigate("/blogs-category")
          setState({
            is_modal_update: false,
            cat_id: null,
            name_fr: "",
            name_en: "",
            desc_en: "",
            desc_fr: "",
          });
          dispatch(allBlogsCategory({ end_point: "/c2c-blog-categories" }));
        } else {
          Toast.showError(global_error_message);
        }
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  return (
    <div className={classes.main_layout_padding}>
      <LoadingComponent activity={blog_slicer.activity} />
      <Grid container spacing={2}>
        {/* <Grid xs={12} md={12} item>
          <div style={{background:"white",display:"flex",justifyContent:"flex-end",padding:10}}>
            <Button value={"Create"} classesNames={classNames(
              classes.button_width,
              classes.contained_button
            )}/>
          </div>
        </Grid> */}
        <Grid xs={12} item>
          <Datatable
            columns={[
              { name: "Blog Category Id", alignment: "left" },
              { name: "Name (English)", alignment: "left" },
              { name: "Name (French)", alignment: "left" },
              { name: "Created at", alignment: "left" },
              { name: "Added by", alignment: "left" },
            ]}
            rows={blog_slicer?.all_blog_cat || []}
            date_range={false}
            // show_header={false}
            onRowClick={handleClickRow}
            selectedRow={{ index: state.selected_blog?.id }}
            min_width={"max-content"}
            table_width="100%"
            ariaLabel={"blog-table"}
            searchSize={"small"}
            hide_sn
            classes={classes}
            radioButton={true}
            enablePagination
            previous_count={Number(
              blog_slicer.all_blog_paginate_data?.previous_count ?? 0
            )}
            current_count={Number(
              blog_slicer.all_blog_paginate_data?.current_count ?? 0
            )}
            total_records={Number(
              blog_slicer.all_blog_paginate_data?.total_count ?? 0
            )}
            count={Number(blog_slicer.all_blog_paginate_data?.total_pages ?? 1)}
            handleUpdateRecords={getblogbyFilter}
            filterByType={false}
            show_button
            btn_label={"Create"}
            onButtonClick={() => {
              //   navigate(`/blogs/create`);
              setState({ is_modal: true });
            }}
            btn_classes={classNames(
              classes.button_width,
              classes.contained_button
            )}
          />
        </Grid>
      </Grid>
      <DraggableDialog
        classes={classes}
        dialog_title={"Blogs Category"}
        open={state?.is_modal}
        handleClose={() =>
          setState({
            is_modal: false,
            cat_id: null,
            name_fr: "",
            name_en: "",
            desc_en: "",
            desc_fr: "",
          })
        }
        dialogBody={
          <div className={classes.main_layout_padding}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  value={state?.name_en}
                  name={"name_en"}
                  input_label="Category Name (English)"
                  required
                  onChange={handleInputChange}
                  inputProps={{
                    className: classNames(
                      classes?.input_feild,
                      "Roboto-Medium"
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  value={state?.name_fr}
                  name={"name_fr"}
                  input_label="Category Name (French)"
                  required
                  onChange={handleInputChange}
                  inputProps={{
                    className: classNames(
                      classes?.input_feild,
                      "Roboto-Medium"
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  value={state.desc_en}
                  name={"desc_en"}
                  input_label="Category Description (English)"
                  onChange={handleInputChange}
                  inputProps={{
                    className: classNames(
                      classes?.input_feild,
                      "Roboto-Medium"
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  value={state.desc_fr}
                  name={"desc_fr"}
                  input_label="Category Description (French)"
                  onChange={handleInputChange}
                  inputProps={{
                    className: classNames(
                      classes?.input_feild,
                      "Roboto-Medium"
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    classesNames={classNames(
                      classes.button_width,
                      classes.contained_button
                    )}
                    onClick={() => {
                      CreateCategory();
                    }}
                    value={"Create"}
                    style={{ marginRight: 20 }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        }
      />
      <DraggableDialog
        classes={classes}
        dialog_title={"Blogs Category"}
        open={state?.is_modal_update}
        handleClose={() =>
          setState({
            is_modal_update: false,
            cat_id: null,
            name_fr: "",
            name_en: "",
            desc_en: "",
            desc_fr: "",
          })
        }
        dialogBody={
          <div className={classes.main_layout_padding}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  value={state?.name_en}
                  name={"name_en"}
                  input_label="Category Name (English)"
                  required
                  onChange={handleInputChange}
                  inputProps={{
                    className: classNames(
                      classes?.input_feild,
                      "Roboto-Medium"
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  value={state?.name_fr}
                  name={"name_fr"}
                  input_label="Category Name (French)"
                  required
                  onChange={handleInputChange}
                  inputProps={{
                    className: classNames(
                      classes?.input_feild,
                      "Roboto-Medium"
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  value={state.desc_en}
                  name={"desc_en"}
                  input_label="Category Description (English)"
                  onChange={handleInputChange}
                  inputProps={{
                    className: classNames(
                      classes?.input_feild,
                      "Roboto-Medium"
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  value={state.desc_fr}
                  name={"desc_fr"}
                  input_label="Category Description (French)"
                  onChange={handleInputChange}
                  inputProps={{
                    className: classNames(
                      classes?.input_feild,
                      "Roboto-Medium"
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    classesNames={classNames(
                      classes.button_width,
                      classes.outlined_button
                    )}
                    onClick={() => {
                      // CreateCategory();
                      DeleteCat();
                    }}
                    value={"Delete"}
                    style={{ marginRight: 20 }}
                  />
                  <Button
                    classesNames={classNames(
                      classes.button_width,
                      classes.contained_button
                    )}
                    onClick={() => {
                      // CreateCategory();
                      UpdateCategory();
                    }}
                    value={"Update"}
                    style={{ marginRight: 20 }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        }
      />
    </div>
  );
}
