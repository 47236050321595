import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { defaultLabelColors, key_to_label } from "utils/constants";

export interface BannerState {
  all_banner: any;
  all_banner_data: any;
  all_banner_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  all_promo: any;
  all_promo_data: any;
  all_promo_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
  selected_customer: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
}

const initialState: BannerState = {
  all_banner: [],
  all_banner_data: [],
  all_banner_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },
  all_promo: [],
  all_promo_data: [],
  all_promo_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },
  selected_customer: {},
  activity: true,
};

export const allBanners = createAsyncThunk(
  "/c2c-banners",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "banners");
      // console.log(response,"response")
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);
export const allPromo = createAsyncThunk("/c2c-promo", async (data: any) => {
  try {
    const { end_point, ...payload } = data;

    let response = await apiServices.postFromApi(
      end_point,
      { payload },
      "promo"
    );
    // console.log(response,"response")
    return response;
  } catch (err) {
    console.log(err);
  }
});

export const orderSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    setSelectedBanner: (state: BannerState, action: any) => {
      if (state.selected_customer) {
        state.selected_customer = action.payload;
      } else {
        state.selected_customer = {};
      }
    },
    clearAllBanner: (state: BannerState) => {
      state.all_banner = [];
      state.all_banner_data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allBanners.pending, (state: BannerState, { payload }) => {
        state.activity = true;
      })
      .addCase(allBanners.fulfilled, (state: BannerState, { payload }) => {
        let banners = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          if (item.is_deleted) continue;
          banners.push([
            item?.id || "- - -",
            item?.added_by || "- - -",
            item?.banner_type || "- - -",
            // item?.is_active ? "Active" : "Inactive" || "- - -",
            {
              name: item?.is_active ? "Active" : "Inactive",
              is_bool: true,
              color: item?.is_active
                ? defaultLabelColors.green
                : defaultLabelColors.red,
              style: {
                color: item?.is_active
                  ? defaultLabelColors.green
                  : defaultLabelColors.red,
              },
            },
            item?.start_date
              ? moment(
                  `${item?.start_date}`.endsWith("Z")
                    ? item?.start_date
                    : item?.start_date + "Z"
                ).format("DD/MM/YYYY HH:mm")
              : "",
            item?.end_date
              ? moment(
                  `${item?.end_date}`.endsWith("Z")
                    ? item?.end_date
                    : item?.end_date + "Z"
                ).format("DD/MM/YYYY HH:mm")
              : "",
            {
              ui: (
                <div>
                  <img
                    src={item?.en.web_image}
                    alt="frank.jpeg"
                    height={50}
                    width={100}
                    style={{ objectFit: "contain", cursor: "pointer" }}
                    className="banner-img"
                  ></img>
                </div>
              ),
            },
            // item?.banner_image?.web || "- - -",
          ]);
        }
        state.all_banner = banners;
        state.all_banner_data = payload?.data;
        state.all_banner_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };

        state.activity = false;
      })

      .addCase(allPromo.pending, (state: BannerState, { payload }) => {
        state.activity = true;
      })
      .addCase(allPromo.fulfilled, (state: BannerState, { payload }) => {
        let Promo = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          Promo.push([
            item?.id || "- - -",
            item?.promo_code || "- - -",
            
            item?.created_at
            ? moment(
                `${item?.created_at}`.endsWith("Z")
                  ? item?.created_at
                  : item?.created_at + "Z"
              ).format("DD/MM/YYYY HH:mm")
            : "",
            item?.start_date
              ? moment(
                  `${item?.start_date}`.endsWith("Z")
                    ? item?.start_date
                    : item?.start_date + "Z"
                ).format("DD/MM/YYYY HH:mm")
              : "",
            item?.end_date
              ? moment(
                  `${item?.end_date}`.endsWith("Z")
                    ? item?.end_date
                    : item?.end_date + "Z"
                ).format("DD/MM/YYYY HH:mm")
              : "",
              item?.discount_type ?key_to_label[item?.discount_type]:"- - -",
              item?.promo_code_type ?key_to_label[item?.promo_code_type]:"- - -",


            {
              name: item?.is_active ? "Active" : "Inactive",
              is_bool: true,
              color: item?.is_active
                ? defaultLabelColors.green
                : defaultLabelColors.red,
              style: {
                color: item?.is_active
                  ? defaultLabelColors.green
                  : defaultLabelColors.red,
              },
            },
            `${item?.promo_amount|| "- - -"}  ${item?.discount_type==="fixed_order"?"€":"%"}` ,

            item?.max_customer_allowed || "- - -",
            item?.max_usage_per_customer || "- - -",
            item?.added_by || "- - -",

            // item?.banner_image?.web || "- - -",
          ]);
        }
        state.all_promo = Promo;
        state.all_promo_data = payload?.data;
        state.all_promo_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };

        state.activity = false;
      });
  },
});

export const { setSelectedBanner, clearAllBanner } = orderSlice.actions;

export default orderSlice.reducer;
