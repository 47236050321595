import {
  ExpandMore
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  StyledEngineProvider
} from "@mui/material";
import classNames from "classnames";
import InputField from "ui-components/TextFeild";

export default function Status(props: any) {
  const { classes, panel_head,  details } = props;
  // const dispatch = useDispatch<any>();
  // const miniShop = useSelector((state: RootState) => state.transporterSlice);
  // const vendorSlice = useSelector((state: RootState) => state.vendors);
  // React.useEffect()
  return (
    <StyledEngineProvider injectFirst>
      <Accordion
      defaultExpanded
        // expanded={
        //   from_vendors ? vendorSlice.panels.status : miniShop.panels.status
        // }
        className={classNames(classes.accordion_container)}
      >
        <AccordionSummary
          className={classNames(classes.accordion_head_container)}
          expandIcon={
            <ExpandMore
              className={classes.accordion_icons}
              style={{ fontSize: 24 }}
              // onClick={() =>
              //   dispatch(
              //     from_vendors
              //       ? toggleStatus(!vendorSlice.panels.status as any)
              //       : handleChangeStatus(!miniShop.panels.status as any)
              //   )
              // }
            />
          }
        >
          <span
            // onClick={() =>
            //   dispatch(
            //     from_vendors
            //       ? toggleStatus(!vendorSlice.panels.status as any)
            //       : handleChangeStatus(!miniShop.panels.status as any)
            //   )
            // }
            className={classNames(classes.accordion_heading)}
            style={{ flex: 1,}}
          >
            {panel_head}
          </span>
          {/* <ContentCopyOutlined
            style={{ marginRight: 10 }}
            className={classNames(classes.accordion_icons)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <PictureAsPdfOutlined
            style={{ marginRight: 10 }}
            className={classNames(classes.accordion_icons)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          /> */}
        </AccordionSummary>

        <AccordionDetails className={classes.accordion_detail_padding}>
          <Grid container spacing={4}>
          <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={details?.otp_verified
                  ? "Active" : "Inactive" }
                input_label={"Active"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={details?.is_verified ? "Verified" : "Not verified" }
                input_label={"iDenfy Verification"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
           

            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={details?.frank_shop_orders_as_customer ?? "0"}
                input_label={"Total Orders (as customer)"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={details?.signup_source ?? "N/A"}
                input_label={"SignUp by"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </StyledEngineProvider>
  );
}
