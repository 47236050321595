import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

// material-ui
import {
  AssistantDirectionOutlined,
  ExpandLess,
  ExpandMore
} from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
// project imports
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import {
  FrankAnalyticsIcon,
  FrankCustomerIcon,
  FrankNotificationIcon,
  FrankOrderIcon,
  FrankTransporterIcon,
  SideBarManagement,
  SiderBarCRM,
  SiderBarOMS,
  SiderBarPayment,
  SiderBarTMS,
  SiderBarVendor,
  SiderBarWMS
} from "assets/images/FrankIcons";
import classNames from "classnames";
import { defaultLabelColors } from "utils/constants";
import NavItem from "../NavItem";

// assets
// import { IconChevronDown, IconChevronUp } from "@tabler/icons";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level, classes }: any) => {
  // const customization = useSelector((state: any) => state.customization);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };

  const { pathname } = useLocation();
  const checkOpenForParent = (child: any, id: any) => {
    child.forEach((item: any) => {
      if (item.url === pathname) {
        setOpen(true);
        setSelected(id);
      }
    });
  };

  // menu collapse for sub-levels
  useEffect(() => {
    setOpen(false);
    setSelected(null);
    if (menu.children?.length > 0) {
      menu.children.forEach((item: any) => {
        if (item.children?.length) {
          checkOpenForParent(item.children, menu.id);
        }
        const currentIndex = pathname
          .toString()
          .split("/")
          .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
          setSelected(menu.id);
          setOpen(true);
        }
      });
    }

    // eslint-disable-next-line
  }, [pathname, menu.children]);

  // menu collapse & item
// const menus = menu.children?.map((item: any) => {
//       // eslint-disable-next-line
//     if (item.visibility === false) {
//       return;
//     }
//       // eslint-disable-next-line
//     switch (item.type) {
//       case "collapse":
//         return <NavCollapse key={item.id} menu={item} level={level + 1} />;
//       case "item":
//         return <NavItem key={item.id} item={item} level={level + 1} />;
//       default:
//         return (
//           <Typography key={item.id} variant="h6" color="error" align="center">
//             Menu Items Error
//           </Typography>
//         );
//     }
//   });

  const menus = menu.children?.map((item: any) => {
    if (item.visibility === false) {
      return null; // ✅ Fix: Explicitly return null
    }
    
    switch (item.type) {
      case "collapse":
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case "item":
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });
  
  const getDrawerIcons = (type: string) => {
    try {
      // console.log(type);
      
      switch (type) {
        case "orders":
          return (
            <FrankOrderIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );
        case "customers":
          return (
            <FrankCustomerIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );

        case "transporters":
          return (
            <FrankTransporterIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );

        case "vendors":
          return (
            <SiderBarVendor
              className={classNames(classes?.dashboard_icon, "frank-icon")}

            />
          );

        case "payments":
          return (
            <SiderBarPayment
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );
          case "oms":
          return (
            <SiderBarOMS
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );
          case "wms":
          return (
            <SiderBarWMS
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );
          case "crm":
          return (
            <SiderBarCRM
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );case "tms":
          return (
            <SiderBarTMS
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );


        case "notifications":
          return (
            <FrankNotificationIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );

        case "analytics":
          return (
            <FrankAnalyticsIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );
        case "management":
          return (
            <SideBarManagement
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );
        case "reporting":
          return (
            <ViewCarouselIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );

        default:
          return <AssistantDirectionOutlined style={{ marginRight: 10, color:defaultLabelColors?.main_orange, }} />;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ListItemButton
        sx={{
          mb: 1.5,
          alignItems: "flex-start",
          backgroundColor: level > 1 ? "transparent !important" : "inherit",
          py: 0.8,
          pl: 1,
          pr: 0,
          borderRadius: 1,
          // color:"red",
          fontFamily: "Raleway-Variable",
          fontWeight: 600,
          color: defaultLabelColors?.main_orange,
          "&:hover": {
            background: `#f6b497 !important`, // Add !important here
            color: `white !important`, // Add !important here
          },
        }}
        onClick={handleClick}
      >
        {/* ICON */}
        <ListItemIcon sx={{ my: "auto", minWidth: !menu?.icon ? 18 : 36 }}>
          {getDrawerIcons(menu.id)}
        </ListItemIcon>

        <p style={{ marginBottom: 0, flex: 1 }}>{menu.title}</p>
        {/* {menu.id === "_notif" && <FiberManualRecord />} */}

        {open ? (
          <ExpandLess style={{ marginTop: "auto", marginBottom: "auto" }} />
        ) : (
          <ExpandMore style={{ marginTop: "auto", marginBottom: "auto" }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menus}
        </List>
      </Collapse>
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number,
  classes: PropTypes.any,
};

export default NavCollapse;
