/*
  Created By Muhammad Hassaan
  Date 07 April 2023
*/
import { Grid, IconButton, TextField, Tooltip } from "@mui/material";
import useStyles from "routes/style-jss";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "store";
import moment from "moment";
import React, { useEffect } from "react";
import { Circle } from "@mui/icons-material";
import { FrankCloseIcon, FrankSaveIcon } from "assets/images/FrankIcons";
import { defaultLabelColors } from "utils/constants";
import { useParams } from "react-router-dom";
import apiServices from "services/RequestHandler";
import Toast from "ui-components/Toast";
import { setSubTitle } from "store/breadcrumbReducer";
import { useDispatch } from "react-redux";
import _ from "lodash";

const Breadcrumbs = () => {
  const classes = useStyles() as any;
  let BreadcrumbTitle = useSelector((state: RootState) => state.breadcrumb);
  const params = useParams();

  const dispatch = useDispatch<any>();
  const [time, setTime] = React.useState(new Date());
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    { editable_sub_title: false, sub_title: BreadcrumbTitle?.sub_title }
  );
  const commission_slicer = useSelector(
    (state: RootState) => state.commissions
  );
  React.useEffect(() => {
    let timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setState({
      sub_title: BreadcrumbTitle?.sub_title,
    });
    // eslint-disable-next-line
  }, [BreadcrumbTitle?.sub_title]);

  const handleUpdateCommissioName = async (): Promise<void> => {
    try {
      if (
        state.sub_title.toLowerCase() ===
        BreadcrumbTitle?.sub_title?.toLowerCase()
      ) {
        setState({
          editable_sub_title: false,
          sub_title: BreadcrumbTitle?.sub_title,
        });
        return;
      }

      if (state.sub_title === "" || !state.sub_title) {
        Toast.showError(
          "The commission name is currently left blank. Please provide a suitable commission name."
        );
        return;
      }

      let isPresent = commission_slicer?.all_commissions_data?.findIndex(
        (item: any) =>
          item?.commission_name?.toLowerCase() ===
          state.sub_title?.toLowerCase()
      );

      if (isPresent > -1) {
        Toast.showError(
          "An commission name with the same title already exists. Please choose a different name for your commission to avoid any conflicts"
        );
        return;
      }

      const resp = await apiServices.patchFromApi(
        `/commissions/update-commission-name?cm_id=${params.cm_id}`,
        {
          commission_name: state.sub_title,
        },
        "commisison"
      );

      if (resp.status === 201) {
        Toast.showSuccess("Commission name modified successfully");
        dispatch(setSubTitle(state.sub_title as any));
        setState({
          editable_sub_title: false,
        });
      } else {
        Toast.showError(resp?.error ?? resp.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.breadcrumb_container}>
      <Grid container spacing={2}>
        <Grid
          xs={7}
          md={7}
          item
          className={classNames(classes.align_center, classes.breadcrumb_head)}
        >
          {BreadcrumbTitle?.title || ""}
          {BreadcrumbTitle?.sub_title && (
            <Circle style={{ margin: "0px 15px", fontSize: "1.2rem" }} />
          )}
          {state.editable_sub_title ? (
            <TextField
              fullWidth
              name="excceeds_days_count"
              placeholder="no of days"
              value={state.sub_title}
              variant={"standard"}
              onChange={(e) =>
                setState({
                  sub_title: e.target.value,
                })
              }
              inputProps={{
                className: classNames(classes?.input_feild, "Roboto-Regular"),
                style: {
                  background: "transparent",
                  padding: 0,
                  fontSize: 28,
                },
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <IconButton
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      style={{ padding: 0, marginRight: 10 }}
                      onClick={() =>
                        setState({
                          editable_sub_title: false,
                          sub_title: BreadcrumbTitle?.sub_title,
                        })
                      }
                    >
                      <FrankCloseIcon
                        height={30}
                        width={30}
                        stroke={defaultLabelColors.color_f}
                      />
                    </IconButton>
                    <IconButton
                      disableRipple
                      disableTouchRipple
                      disableFocusRipple
                      style={{ padding: 0 }}
                      onClick={handleUpdateCommissioName}
                    >
                      <FrankSaveIcon
                        height={30}
                        width={30}
                        stroke={defaultLabelColors.color_f}
                      />
                    </IconButton>
                  </>
                ),
                style: {
                  background: "transparent",
                  padding: 0,
                },
                disableUnderline: true,
              }}
              classes={classes}
              style={{
                background: "transparent",
                outline: "none",
              }}
            />
          ) : params.cm_id ? (
            <Tooltip
              arrow
              placement="bottom"
              title="You may alter the commission name by performing a double-click action on it."
            >
              <span
                onDoubleClick={() => {
                  setState({ editable_sub_title: true });
                }}
              >
                {_.startCase(BreadcrumbTitle?.sub_title || "")}
              </span>
            </Tooltip>
          ) : (
            _.startCase(BreadcrumbTitle?.sub_title ?? "")
          )}
        </Grid>
        <Grid
          xs={5}
          md={5}
          item
          className={classNames(classes.align_end, classes.direction_column)}
        >
          <div className={classNames(classes.breadcrumb_time)}>
            {moment(time).format("hh:mm A")}
          </div>
          <div className={classNames(classes.breadcrumb_description)}>
            {moment().format("DD/MM/YYYY")}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Breadcrumbs;
