/*
  Created By Muhammad Hassaan
  Date 15 April 2023
*/
import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, Paper, TextField, Chip } from "@mui/material";
import { defaultLabelColors } from "utils/constants";
import { KeyboardArrowDown } from "@mui/icons-material";

export default function SelectField(props: any) {
  const {
    input_label,
    required,
    value,
    classes,
    error,
    options,
    isDisabled,
    name,
    variant,
    placeholder,
    handleSelect,
    readOnly,
    disableClearable,
    disableUnderline,
    multiple,
    height,
    minHeight,
    disableCloseOnSelect,
    limitTags,
    font_size,
    caretColor,
    multi_col,
  } = props;
  return (
    <form
      style={{ width: "100%" }}
      // eslint-disable-next-line
      autoComplete="off"
      // eslint-disable-next-line

      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {input_label && (
        <label
          className={classNames(
            classes.input_label_style,
            "Raleway-Variable",
            error && classes.color_red
          )}
          style={{ paddingLeft: 10,color:defaultLabelColors?.main_orange,fontFamily:"Raleway-SemiBold" }}
        >
          {input_label}
          {required ? <span style={{ color: "red" }}> *</span> : ""}
        </label>
      )}

      <Autocomplete
        options={options}
        getOptionLabel={(option: any) => option?.label}
        fullWidth
        disabled={isDisabled}
        style={{
          background: isDisabled ? "#f6f6f6" : "transparent",
          color: defaultLabelColors.color_e,
          caretColor: caretColor ? caretColor : "auto",
        }}
      
        limitTags={limitTags}
        multiple={multiple}
        disableCloseOnSelect={disableCloseOnSelect}
        size="small"
        value={value}
        filterOptions={(options, state) => {
          const inputValue = state.inputValue.toLowerCase();
          return options.filter(
            (option) =>
              option?.label?.toLowerCase()?.includes(inputValue) ||
              option?.label_2?.toLowerCase()?.includes(inputValue)
          );
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            {multi_col ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    fontFamily: "Roboto-Medium",
                    color: option?.hex ? option?.hex : "black",
                    fontSize: 16,
                  }}
                >
                  {option?.label}{" "}
                  {option?.is_primary && (
                    <span
                      style={{
                        fontFamily: "Roboto-Bold",
                        color: defaultLabelColors.main_blue,
                        fontSize: 12,
                        // marginLeft: 5,
                      }}
                    >
                      (Default)
                    </span>
                  )}
                </div>
                <div
                  style={{
                    fontFamily: "Roboto-Regular",
                    color: "black",
                    fontSize: 12,
                  }}
                >
                  {option?.label_2}
                </div>
              </div>
            ) : (
              option?.label
            )}
          </li>
        )}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              "& ul": {
                maxHeight: 200,
                "& li": {
                  fontFamily: "Roboto-Regular",
                  fontSize: 16,
                  fontWeight: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  color: defaultLabelColors.color_e,
                  borderBottom: "1px solid #E0E0E0",
                },
              },
            }}
            style={{
              boxShadow:
                "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
              maxHeight: 200,
              overflow: "hidden scroll",
            }}
          >
            {children}
          </Paper>
        )}
        popupIcon={
          <KeyboardArrowDown style={{ color: defaultLabelColors.color_f }} />
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              // key={`chip-${index}`}
              label={option?.label}
              style={{
                borderRadius: 4,
                background: defaultLabelColors.light_gray,
                color: defaultLabelColors.main_orange,
                margin: 2,
              }}
              {...getTagProps({ index })}
            />
          ))
        }
        // eslint-disable-next-line

        autoComplete={false}
        // eslint-disable-next-line

        onChange={(event, values) => handleSelect(name, event, values)}
        // isOptionEqualToValue={(option, value) => console.log(option?.value === value.value,"checking")}
        isOptionEqualToValue={(option, value) => {
          // console.log(option, "checking-option");
          // console.log(value, "checking-value");

          return option?.value === value.value; // Adjust based on your object structure
        }}
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            size="small"
            // eslint-disable-next-line

            autoComplete="off"
            // eslint-disable-next-line

            aria-autocomplete="none"
            placeholder={placeholder}
            error={error}
            variant={variant}
            inputProps={{
              ...params.inputProps,
              style: {
                fontFamily: "Roboto-Medium",
                fontSize: font_size,
                fontWeight: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                backgroundColor: isDisabled ? "#f6f6f6" : "transparent",
                background: isDisabled ? "#f6f6f6" : "transparent",
                color: defaultLabelColors.color_e,
                width: "100%",
                paddingLeft: disableUnderline ? 10 : 10,
                height: disableUnderline ? "" : multiple ? 0 : "",
              },
              // eslint-disable-next-line

              autoComplete: "off",
              // eslint-disable-next-line

              "aria-autocomplete": "none",
            }}
            InputProps={{
              ...params.InputProps,
              style: {
                background: isDisabled ? "#f6f6f6" : "white",
                height: height,
                paddingRight: 10,
                paddingBottom: multiple ? 2 : 6,
                minHeight: !minHeight ? 0 : 43,
                paddingTop: name === "order_criteria" ? 0 : multiple ? 3 : 6,
              },
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              // eslint-disable-next-line

              autoComplete: "off",
              // eslint-disable-next-line

              disableUnderline: disableUnderline,
            }}
            style={{ background: isDisabled ? "#f6f6f6" : "white" }}
            title={
              multiple ? value?.map((i: any) => `${i?.label}, `) : value?.label
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius:3,
                // "& fieldset": {
                //   borderColor: defaultLabelColors?.main_orange, // Default color
                // },
               
                // "&.Mui-focused fieldset": {
                //   borderColor: defaultLabelColors?.sand, // Selected color
                // },
              },
            }}
          />
        )}
        readOnly={readOnly}
        disableClearable={disableClearable}
      />
    </form>
  );
}
SelectField.defaultProps = {
  input_label: "",
  value: null,
  required: false,
  error: false,
  classes: {},
  size: "small",
  variant: "outlined",
  placeholder: "",
  InputProps: null,
  inputProps: null,
  InputLabelProps: null,
  style: null,
  handleSelect: null,
  options: [],
  isDisabled: false,
  readOnly: false,
  name: "",
  disableClearable: false,
  disableUnderline: false,
  multiple: false,
  height: 43,
  caretColor: "auto",
  disableCloseOnSelect: false,
  limitTags: -1,
  font_size: 16,
  multi_col: false,
};

SelectField.propTypes = {
  input_label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,

  options: PropTypes.any,

  value: PropTypes.any,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  caretColor: PropTypes.string,
  variant: PropTypes.oneOf(["outlined", "standard"]),
  required: PropTypes.bool,
  classes: PropTypes.object,
  InputProps: PropTypes.any,
  inputProps: PropTypes.any,
  InputLabelProps: PropTypes.any,
  style: PropTypes.any,
  error: PropTypes.bool,
  isDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  disableClearable: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  multiple: PropTypes.bool,
  height: PropTypes.any,
  minHeight: PropTypes.bool,

  handleSelect: PropTypes.func,

  disableCloseOnSelect: PropTypes.bool,
  limitTags: PropTypes.number,
  font_size: PropTypes.any,
  multi_col: PropTypes.bool,
};
