import ScheduleIcon from "@mui/icons-material/Schedule";
import { InputAdornment } from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers";
import classNames from "classnames";
import moment from "moment";

export default function WebTimePicker(props: any) {
  const {
    classes,
    input_label,
    value,
    required,
    error,
    height,
    width,
    disabled,
    backGround,
    placeholder,
    onChange,
    key,
    textFeildClasses,
    readOnly,
  } = props;
  //   const { n_value } = props;
  return (
    <div>
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {input_label && (
          <label
            className={classNames(
              classes.input_label_style,
              "RobotoCondensed-Regular",
              error && classes.color_red
            )}
            style={{ paddingBottom: 3 }}
          >
            {input_label}
            {required && <span style={{ color: "red" }}> *</span>}
          </label>
        )}

        <MobileTimePicker
          slotProps={{
            textField: {
              style: {
                background: "white",
              },
              placeholder,
              inputProps: {
                className: textFeildClasses,
                style: {
                  height: 0,
                },
              },
              InputProps: {
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ background: "white", cursor: "pointer" }}
                  >
                    <ScheduleIcon style={{ fontSize: 24 }} />
                  </InputAdornment>
                ),
                style: {
                  height,
                },
                readOnly,
              },
            },
          }}
          label=""
          sx={{
            width: width,
            background: backGround,

            "& .MuiOutlinedInput-root": {
              borderRadius: "10px", // Set border radius for the input field
            },
          }}
          value={value ? moment(value) : null}
          onChange={onChange}
          key={key}
          // readOnly
          readOnly={readOnly}
          disabled={disabled}
        />
      </form>
    </div>
  );
}
