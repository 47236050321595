import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NoImgAvail from "assets/images/no-avail-img.png";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { defaultLabelColors, key_to_label } from "utils/constants";
import {
  getStatusLabel,
  getStatusLabelColor,
  numberFormatWithoutCurrency,
  numberFormatWithoutCurrencyFixed,
} from "utils/helper";

export interface CustomerState {
  all_3p_customers: any;
  all_3p_customers_data: any;
  all_3p_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  all_space_customers: any;
  all_space_customers_data: any;
  all_space_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  all_app_customers: any;
  all_app_customers_data: any;
  all_app_pagnate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  all_business_customer: any;
  all_business_customer_data: any;
  all_business_pagnate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  single_business_customer: any;
  single_business_customer_data: any;
  //
  my_annc_business_customer: any;
  my_annc__business_customer_data: any;
  my_annc__business_pagnate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
  //
  order_as_transporter_business_customer: any;
  order_as_transporter_business_customer_data: any;
  order_as_transporter_business_pagnate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
  //
  frnk_order_as_cust_business_customer: any;
  frnk_order_as_cust_business_customer_data: any;
  frnk_order_as_cust_business_pagnate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  particular_product: any;
  particular_product_data: any;
  particular_product_paginate: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  particular_product_order: any;
  particular_product_order_data: any;
  particular_product_order_paginate: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  selected_customer: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
}

const initialState: CustomerState = {
  all_3p_customers: [],
  all_3p_customers_data: [],
  all_3p_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  all_space_customers: [],
  all_space_customers_data: [],
  all_space_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  all_app_customers: [],
  all_app_customers_data: [],
  all_app_pagnate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  all_business_customer: [],
  all_business_customer_data: [],
  all_business_pagnate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },
  single_business_customer_data: [],

  single_business_customer: [],
  //
  //
  my_annc_business_customer: [],
  my_annc__business_customer_data: [],
  my_annc__business_pagnate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },
  //
  order_as_transporter_business_customer: [],
  order_as_transporter_business_customer_data: [],
  order_as_transporter_business_pagnate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },
  //
  frnk_order_as_cust_business_customer: [],
  frnk_order_as_cust_business_customer_data: [],
  frnk_order_as_cust_business_pagnate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },
  //
  particular_product: [],
  particular_product_data: [],
  particular_product_paginate: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },
  particular_product_order: [],
  particular_product_order_data: [],
  particular_product_order_paginate: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  selected_customer: {},
  activity: true,
};

export const all3PCustomerRequest = createAsyncThunk(
  "/admin/customers/3P Customer",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "cusomers-3p"
      );
      // response = all_3pCustomer;
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const allAppCustomerRequest = createAsyncThunk(
  "/admin/customers/App Customer",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "cusomers-app"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const allBusinessCustomerRequest = createAsyncThunk(
  "customers/business",
  async (data: any) => {
    try {
      const { end_point, ...payload } = data;
      let response = await apiServices.postFromApi(
        end_point,
        payload,
        "customers-business"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);
export const BusinessCustomerRequestById = createAsyncThunk(
  "customers/business/byId",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,

        "customers-business-byId"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const allSpaceCustomerRequest = createAsyncThunk(
  "/admin/customers/Space Customers",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "space-customer"
      );
      // response = all_3pCustomer;
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

//cust annoucement as transporter
export const BzCustomerAnnoucementAsTransporter = createAsyncThunk(
  "/c2c/get-shipment-orders-as-transporters",
  async (data: any) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        {
          id: data.id,
          page: data.page,
          limit: data.limit,
          start_date: data?.start_date,
          end_date: data?.end_date,
        },
        "get-shipment-orders-as-transporters"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);
export const BzCustomerAnnoucementAsSender = createAsyncThunk(
  "/c2c/get-my-shipments",
  async (data: any) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        {
          page: data.page,
          limit: data.limit,
          id: data.id,
          start_date: data?.start_date,
          end_date: data?.end_date,
          // ?page=${1}&=${50}
        },
        "get-my-shipments"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);
export const BzCustomerFrankShopAsCust = createAsyncThunk(
  "/c2c/get-shop-order-as-customers",
  async (data: any) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        {
          id: data.id,
          page: data.page,
          limit: data.limit,
          start_date: data?.start_date,
          end_date: data?.end_date,
        },
        "get-shop-order-as-customers"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const ParticularProds = createAsyncThunk(
  "c2c-product-masters/particular/admin",
  async (data: any) => {
    try {
      const { end_point, ...payload } = data;

      let response = await apiServices.postFromApi(
        end_point,
        payload,
        "c2c-product-masters/particular/admin"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const ParticularProdOrder = createAsyncThunk(
  "c2c-orders/particular",
  async (data: any) => {
    try {
      const { end_point, ...payload } = data;

      let response = await apiServices.postFromApi(
        end_point,
        payload,
        "c2c-orders/particular"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);
export const orderSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setSelectedOrder: (state: CustomerState, action: any) => {
      if (state.selected_customer) {
        state.selected_customer = action.payload;
      } else {
        state.selected_customer = {};
      }
    },
    clearAllCustomers: (state: CustomerState) => {
      state.all_3p_customers = [];
      state.all_3p_customers_data = [];

      state.all_app_customers = [];
      state.all_app_customers_data = [];

      state.all_business_customer = [];
      state.all_business_customer_data = [];
      state.my_annc__business_customer_data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        all3PCustomerRequest.pending,
        (state: CustomerState, { payload }) => {
          state.activity = true;
        }
      )
      .addCase(
        all3PCustomerRequest.fulfilled,
        (state: CustomerState, { payload }) => {
          let customers = [] as any;
          for (let index = 0; index < payload?.data.length; index++) {
            const item = payload?.data[index];
            customers.push([
              // eslint-disable-next-line react-hooks/exhaustive-deps

              item?.id || "- - -",
              // `3P-CUST-${index + 1}-` + item?.id || "- - -",
              item?.full_name || "- - -",
              item?.locations || "- - -",
              item?.phone || "- - -",

              // `${item?.phone_code || ""} ${item?.phone ?? ""}`,
              // item?.status ? "Active" : "Inactive",
              item?.join_date
                ? moment(item?.join_date).format("DD-MMM-YYYY")
                : "- - -",
              numberFormatWithoutCurrency(Number(item?.total_orders ?? 0)) ||
                "- - -",
              "€ " +
                numberFormatWithoutCurrency(Number(item?.total_amount ?? 0)) ||
                "- - -",
              item?.last_order_date
                ? moment(item?.last_order_date).format("DD-MMM-YYYY")
                : "- - -",
              "€ " +
                numberFormatWithoutCurrency(
                  Number(item?.last_order_amount ?? 0)
                ),
            ]);
          }
          state.all_3p_customers = customers;
          state.all_3p_customers_data = payload?.data;
          state.all_3p_paginate_data = {
            current_count: payload?.current_count,
            current_page: payload?.current_page,
            previous_count: payload?.previous_count,
            total_pages: payload?.total_pages,
            total_count: payload?.total_count,
          };

          state.activity = false;
        }
      )
      .addCase(
        allSpaceCustomerRequest.pending,
        (state: CustomerState, { payload }) => {
          state.activity = true;
        }
      )
      .addCase(
        allSpaceCustomerRequest.fulfilled,
        (state: CustomerState, { payload }) => {
          let _space_customer = [] as any;
          for (let index = 0; index < payload?.data.length; index++) {
            const item = payload?.data[index];
            _space_customer.push([
              // eslint-disable-next-line react-hooks/exhaustive-deps

              item?.id || "- - -",
              item?.full_name || "- - -",
              item?.locations || "- - -",
              item?.phone || "- - -",

              item?.join_date
                ? moment(item?.join_date).format("DD-MMM-YYYY")
                : "- - -",
              {
                value: item?.is_verified,
                name: item?.is_verified ? "Verified" : "Not Verified",
                is_bool: true,
                color: item?.is_verified
                  ? defaultLabelColors.main_blue
                  : "gray",
              },
              numberFormatWithoutCurrency(Number(item?.total_orders ?? 0)) ||
                "- - -",
              "€ " +
                numberFormatWithoutCurrency(Number(item?.total_amount ?? 0)) ||
                "- - -",
              item?.last_order_date
                ? moment(item?.last_order_date).format("DD-MMM-YYYY")
                : "- - -",
              "€ " +
                numberFormatWithoutCurrency(
                  Number(item?.last_order_amount ?? 0)
                ),
            ]);
          }
          state.all_space_customers = _space_customer;
          state.all_space_customers_data = payload?.data;
          state.all_space_paginate_data = {
            current_count: payload?.current_count,
            current_page: payload?.current_page,
            previous_count: payload?.previous_count,
            total_pages: payload?.total_pages,
            total_count: payload?.total_count,
          };
          // console.log(_space_customer);
          // console.log(state.all_space_customers_data);
          state.activity = false;
        }
      )
      .addCase(
        allAppCustomerRequest.pending,
        (state: CustomerState, { payload }) => {
          state.activity = true;
        }
      )
      .addCase(
        allAppCustomerRequest.fulfilled,
        (state: CustomerState, { payload }) => {
          let customers = [] as any;
          for (let index = 0; index < payload?.data.length; index++) {
            const item = payload?.data[index];
            customers.push([
              // eslint-disable-next-line react-hooks/exhaustive-deps

              item?.id ?? "- - -",
              item?.full_name ?? "- - -",
              item?.locations ?? "- - -",
              item?.phone ?? "- - -",
              {
                name: item?.is_active ? "Active" : "Inactive",
                is_bool: true,
                color: item?.is_active
                  ? defaultLabelColors.green
                  : defaultLabelColors.red,
                style: {
                  color: item?.is_active
                    ? defaultLabelColors.green
                    : defaultLabelColors.red,
                },
              },
              item?.join_date
                ? moment(item?.join_date).format("DD-MMM-YYYY")
                : "- - -",
              numberFormatWithoutCurrency(Number(item?.total_orders ?? 0)),
              "€ " +
                numberFormatWithoutCurrency(Number(item?.total_amount ?? 0)),

              item?.last_order_date
                ? moment(item?.last_order_date).format("DD-MMM-YYYY")
                : "- - -",

              "€ " +
                numberFormatWithoutCurrency(
                  Number(item?.last_order_amount ?? 0)
                ),
            ]);
          }

          state.all_app_customers = customers;
          state.all_app_customers_data = payload?.data;
          state.all_app_pagnate_data = {
            current_count: payload?.current_count,
            current_page: payload?.current_page,
            previous_count: payload?.previous_count,
            total_pages: payload?.total_pages,
            total_count: payload?.total_count,
          };
          state.activity = false;
        }
      )
      .addCase(
        allBusinessCustomerRequest.pending,
        (state: CustomerState, { payload }) => {
          state.activity = true;
        }
      )
      .addCase(
        allBusinessCustomerRequest.fulfilled,
        (state: CustomerState, { payload }) => {
          let customers = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            customers.push([
              // eslint-disable-next-line
              item?.id || "- - -",
              `${item?.name ?? "- -"}`,
              `${item?.phone ?? "- -"}`,
              item?.email || "- - -",

              // item?.phone_code+' '+item?.phone ?? "- - -",
              {
                // name: item?.is_active ? "Active" : "Inactive",
                name: item?.otp_verified ? "Active" : "Inactive",
                is_bool: true,
                color: item?.otp_verified
                  ? defaultLabelColors.green
                  : defaultLabelColors.red,
                style: {
                  color: item?.otp_verified
                    ? defaultLabelColors.green
                    : defaultLabelColors.red,
                },
              },
              {
                name: item?.otp_verified ? "Yes" : "No",
                is_bool: true,
                color: item?.otp_verified
                  ? defaultLabelColors.green
                  : defaultLabelColors.red,
                style: {
                  color: item?.otp_verified
                    ? defaultLabelColors.green
                    : defaultLabelColors.red,
                },
              },

              item?.created_at
                ? moment(item?.created_at).format("DD/MM/YYYY HH:mm")
                : "- - -",
              item?.channel ? key_to_label[item?.channel] : "- -",

              item?.my_shipments
                ? numberFormatWithoutCurrencyFixed(item?.my_shipments)
                : 0,

              item?.shipment_as_transporter
                ? numberFormatWithoutCurrencyFixed(
                    item?.shipment_as_transporter
                  )
                : 0,

              //first name apply
              item?.is_shop
                ? item?.shop_name
                  ? item?.shop_name
                  : `${item?.first_name}'s shop`
                : {
                    name: "No",
                    is_bool: true,
                    color: defaultLabelColors.red,
                    style: {
                      color: defaultLabelColors.red,
                    },
                  },

              
              item?.frank_shop_orders_as_vendor
                ? numberFormatWithoutCurrencyFixed(
                    item?.frank_shop_orders_as_vendor
                  )
                : 0,
              item?.frank_shop_orders_as_customer
                ? numberFormatWithoutCurrencyFixed(
                    item?.frank_shop_orders_as_customer
                  )
                : 0,

              `${item?.machine_detail?.version ?? "- "} ${
                item?.machine_detail?.buildNumber
                  ? `(${item?.machine_detail?.buildNumber})`
                  : `-`
              }`,
              item?.machine_detail?.model_name
                ? item?.machine_detail?.model_name
                : `${item?.machine_detail?.deviceId ?? "- "}  ${
                    item?.machine_detail?.deviceName ?? " -"
                  }`,
            ]);
          }
          state.all_business_customer = customers;
          state.all_business_customer_data = payload?.data;
          state.all_business_pagnate_data = {
            current_count: payload?.current_count,
            current_page: payload?.current_page,
            previous_count: payload?.previous_count,
            total_pages: payload?.total_pages,
            total_count: payload?.total_count,
          };
          state.activity = false;
        }
      )

      .addCase(
        BusinessCustomerRequestById.pending,
        (state: CustomerState, { payload }) => {
          state.activity = true;
        }
      )
      .addCase(
        BusinessCustomerRequestById.fulfilled,
        (state: CustomerState, { payload }) => {
          // let customers = [] as any;

          // state.all_business_customer = customers;
          state.single_business_customer_data = payload?.data;

          state.activity = false;
        }
      )
      .addCase(
        BzCustomerAnnoucementAsSender.pending,
        (state: CustomerState, { payload }) => {
          state.activity = true;
        }
      )
      .addCase(
        BzCustomerAnnoucementAsSender.fulfilled,
        (state: CustomerState, { payload }) => {
          let customers = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            customers.push([
              // eslint-disable-next-line
              `${item?.id ?? "- -"}`,
              item?.created_at
                ? moment(
                    `${item?.created_at}`.endsWith("Z")
                      ? item?.created_at
                      : item?.created_at + "Z"
                  ).format("DD/MM/YYYY HH:mm")
                : "- - -",

              item?.delivery_date
                ? moment(item?.delivery_date).format("DD/MM/YYYY ")
                : "- - -",
              {
                name: getStatusLabel(item?.ship_status as string) ?? "-",
                is_bool: true,

                style: {
                  color:
                    getStatusLabelColor(item?.ship_status as string) ?? "black",
                },
              },
              `${item?.ship_price ?? "- -"} €`,
              `${item?.ship_delivery_type ?? "- -"}`,
            ]);
          }
          state.my_annc_business_customer = customers;
          state.my_annc__business_customer_data = payload?.data;
          state.my_annc__business_pagnate_data = {
            current_count: payload?.current_count,
            current_page: payload?.current_page,
            previous_count: payload?.previous_count,
            total_pages: payload?.total_pages,
            total_count: payload?.total_count,
          };
          state.activity = false;
        }
      )
      //
      // BzCustomerFrankShopAsCust
      .addCase(
        BzCustomerFrankShopAsCust.pending,
        (state: CustomerState, { payload }) => {
          state.activity = true;
        }
      )
      .addCase(
        BzCustomerFrankShopAsCust.fulfilled,
        (state: CustomerState, { payload }) => {
          let customers = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            customers.push([
              // eslint-disable-next-line react-hooks/exhaustive-deps

              `${item?.id ?? "- -"}`,
              item?.order_date
                ? // moment(item?.order_date).format("DD/MM/YYYY HH:mm")
                  moment(
                    `${item?.created_at}`.endsWith("Z")
                      ? item?.created_at
                      : item?.created_at + "Z"
                  ).format("DD/MM/YYYY HH:mm")
                : "- - -",

              item?.delivery_date
                ? moment(item?.delivery_date).format("DD/MM/YYYY ")
                : "- - -",
              {
                name: getStatusLabel(item?.order_status as string) ?? "-",
                is_bool: true,

                style: {
                  color:
                    getStatusLabelColor(item?.order_status as string) ??
                    "black",
                },
              },
              `${item?.order_amount ?? "- -"} €`,
            ]);
          }
          state.frnk_order_as_cust_business_customer = customers;
          state.frnk_order_as_cust_business_customer_data = payload?.data;
          state.frnk_order_as_cust_business_pagnate_data = {
            current_count: payload?.current_count,

            current_page: payload?.current_page,
            previous_count: payload?.previous_count,
            total_pages: payload?.total_pages,
            total_count: payload?.total_count,
          };
          state.activity = false;
        }
      )

      //BzCustomerAnnoucementAsTransporter

      .addCase(
        BzCustomerAnnoucementAsTransporter.pending,
        (state: CustomerState, { payload }) => {
          state.activity = true;
        }
      )
      .addCase(
        BzCustomerAnnoucementAsTransporter.fulfilled,
        (state: CustomerState, { payload }) => {
          let customers = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            customers.push([
              // eslint-disable-next-line react-hooks/exhaustive-deps

              `${item?.id ?? "- -"}`,
              item?.created_at
                ? moment(
                    `${item?.created_at}`.endsWith("Z")
                      ? item?.created_at
                      : item?.created_at + "Z"
                  ).format("DD/MM/YYYY HH:mm")
                : "- - -",

              item?.delivery_date
                ? moment(item?.delivery_date).format("DD/MM/YYYY")
                : "- - -",

              {
                name: getStatusLabel(item?.ship_status as string) ?? "-",
                is_bool: true,

                style: {
                  color:
                    getStatusLabelColor(item?.ship_status as string) ?? "black",
                },
              },
              `${item?.ship_price ?? "- -"} €`,
              `${item?.ship_delivery_type ?? "- -"}`,
            ]);
          }
          state.order_as_transporter_business_customer = customers;
          state.order_as_transporter_business_customer_data = payload?.data;
          state.order_as_transporter_business_pagnate_data = {
            current_count: payload?.current_count,

            current_page: payload?.current_page,
            previous_count: payload?.previous_count,
            total_pages: payload?.total_pages,
            total_count: payload?.total_count,
          };
          state.activity = false;
        }
      )

      .addCase(ParticularProds.pending, (state: CustomerState, { payload }) => {
        state.activity = true;
      })
      .addCase(
        ParticularProds.fulfilled,
        (state: CustomerState, { payload }) => {
          let _particular_product = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            _particular_product?.push([
              // eslint-disable-next-line react-hooks/exhaustive-deps

              item?.id || "- - -",
              item?.created_at
                ? moment(item?.created_at).format("DD/MM/YYYY")
                : "- - -",
              {
                ui: (
                  <div style={{ display: "flex",gap:10 }}>
                    <div>
                    <img
                      src={
                        item?.images[0] === ""
                          ? NoImgAvail
                          : item?.images[0] ?? NoImgAvail
                      }
                      alt="category.jpeg"
                      onError={(e: any) => {
                        e.target.onerror = null; // Prevent infinite loop
                        e.target.src = NoImgAvail; // Fallback image source
                      }}
                      height={50}
                      width={"100%"}
                      style={{ objectFit: "contain", cursor: "pointer" }}
                      className="banner-img"
                    />
                    </div>
                   
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {item?.product_title?.en ?? ""}
                    </div>
                  </div>
                ),
                alignment: "center",
              },
              {
                name: item?.is_sold_out ? "Sold" : "Not Sold",

                is_bool: true,
                color: item?.is_sold_out ? "green" : "red",

                style: {
                  color: item?.is_sold_out ? "green" : "red",
                },
              },

              // "€ " + numberFormatWithoutCurrency(Number(item?.shipping_rate ?? 0)),
            ]);
          }
          state.particular_product = _particular_product;
          state.particular_product_data = payload?.data;
          state.particular_product_paginate = {
            current_count: payload?.current_count,

            current_page: payload?.current_page,
            previous_count: payload?.previous_count,
            total_pages: payload?.total_pages,
            total_count: payload?.total_count,
          };
          state.activity = false;
        }
      )

      //paritcular order
      .addCase(
        ParticularProdOrder.pending,
        (state: CustomerState, { payload }) => {
          state.activity = true;
        }
      )
      .addCase(
        ParticularProdOrder.fulfilled,
        (state: CustomerState, { payload }) => {
          let particular_orders = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            particular_orders?.push([
              // eslint-disable-next-line
              `ODR-${item?.id}` || "- - -",
              item?.order_date
                ? moment(item?.order_date).format("DD/MM/YYYY")
                : "- - -",
              {
                name: getStatusLabel(item?.order_status as string) ?? "-",
                is_bool: true,
                color:
                  item?.order_status === "pending"
                    ? getStatusLabelColor(item?.order_status as string)
                    : "black",
                style: {
                  color:
                    getStatusLabelColor(item?.order_status as string) ??
                    "black",
                },
              },
              "€ " + numberFormatWithoutCurrency(Number(item?.unit_price ?? 0)),
            ]);
          }
          state.particular_product_order = particular_orders;
          state.particular_product_order_data = payload?.data;
          state.particular_product_order_paginate = {
            current_count: payload?.current_count,

            current_page: payload?.current_page,
            previous_count: payload?.previous_count,
            total_pages: payload?.total_pages,
            total_count: payload?.total_count,
          };
          state.activity = false;
        }
      );
  },
});

export const { setSelectedOrder, clearAllCustomers } = orderSlice.actions;

export default orderSlice.reducer;
