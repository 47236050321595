import { Component } from "react";
import { toast } from "react-toastify";

class Toast extends Component {
  showError(message: any) {
    toast.error(`Error: ${message?.replaceAll(`"`, "")}`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  showSuccess(message: any) {
    toast.success(`${message}`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
}
// eslint-disable-next-line
export default new Toast({} as any);
