import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NoImgAvail from "assets/images/no-avail-img.png";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { defaultLabelColors } from "utils/constants";
import { generateTrimmedID } from "utils/helper";
// import noImg from "assets/images/No_Image.jpg";
export interface Categorystate {
  all_Categories: any;
  all_Categories_data: any;
  all_Categories_main_data: any;
  all_Categories_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  all_attribute: any;
  all_attribute_data: any;
  all_attribute_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
  all_spec: any;
  all_spec_data: any;
  all_spec_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  all_brand: any;
  all_brand_data: any;
  all_brand_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  selected_category: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
}

const initialState: Categorystate = {
  all_Categories: [],
  all_Categories_data: [],
  all_Categories_main_data: [],

  all_Categories_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  all_attribute: [],
  all_attribute_data: [],
  all_attribute_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },
  all_spec: [],
  all_spec_data: [],
  all_spec_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  all_brand: [],
  all_brand_data: [],
  all_brand_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  selected_category: {},
  activity: true,
};

export const allCategory = createAsyncThunk("Cateogry", async (data: any) => {
  try {
    let response = await apiServices.postFromApi(
      data.end_point,
      {
        page: data.page,
        limit: data.limit,
        start_date: data?.start_date,
        end_date: data?.end_date,

        // ?page=${1}&=${50}
      },
      "category"
    );
    return response;
  } catch (err) {
    console.log(err);
  }
});
//

export const allMainCategory = createAsyncThunk(
  "Main-Cateogry",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,

        "Main-category"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const allAttribute = createAsyncThunk("Attribute", async (data: any) => {
  try {
    let response = await apiServices.postFromApi(
      data.end_point,
      {
        page: data.page,
        limit: data.limit,
        start_date: data?.start_date,
        end_date: data?.end_date,
      },
      "attribute"
    );
    return response;
  } catch (err) {
    console.log(err);
  }
});
export const allSpec = createAsyncThunk("Spec", async (data: any) => {
  try {
    let response = await apiServices.postFromApi(
      data.end_point,
      {
        page: data.page,
        limit: data.limit,
        start_date: data?.start_date,
        end_date: data?.end_date,

        // ?page=${1}&=${50}
      },
      "spec"
    );
    return response;
  } catch (err) {
    console.log(err);
  }
});

export const allBrand = createAsyncThunk("brand", async (data: any) => {
  try {
    let response = await apiServices.postFromApi(
      data.end_point,
      {
        page: data.page,
        limit: data.limit,
        start_date: data?.start_date,
        end_date: data?.end_date,
      },

      "brand"
    );
    return response;
  } catch (err) {
    console.log(err);
  }
});

export const orderSlice = createSlice({
  name: "calatlog",
  initialState,
  reducers: {
    setSelectedComments: (state: Categorystate, action: any) => {
      if (state.selected_category) {
        state.selected_category = action.payload;
      } else {
        state.selected_category = {};
      }
    },
    clearallCategory: (state: Categorystate) => {
      state.all_Categories = [];
      state.all_Categories_data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle allCategory actions
      .addCase(allCategory.pending, (state: Categorystate) => {
        state.activity = true;
      })
      .addCase(allCategory.fulfilled, (state: Categorystate, { payload }) => {
        let category = [] as any;
        const filteredAndSortedData = payload?.data
          ?.filter((item: any) => item?.parent_id === null) // Filter by parent_id === null
          ?.sort((a: any, b: any) => a.idx - b.idx);
          console.log(filteredAndSortedData,"filter");
          let last_index = filteredAndSortedData?.length - 1;

        for (let index = 0; index < filteredAndSortedData?.length; index++) {
          const item = filteredAndSortedData[index];
          if (item.is_deleted) continue;
          category.push([
            item?.id || "- - -",
            moment(
              `${item?.created_at}`.endsWith("Z")
                ? item?.created_at
                : item?.created_at + "Z"
            ).format("DD/MM/YYYY HH:mm"),
            {
              ui: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {item?.category_name?.en ?? "- - -"}
                  <div
                    title={`${item?.path_en?.join(">")}`}
                    style={{
                      fontSize: 12,
                      flex: 1,
                      display: "-webkit-box",
                      maxWidth: 400,
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                  >
                    {item?.path_en?.join(">")}
                  </div>
                </div>
              ),
            },
            {
              ui: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {item?.category_name?.fr ?? "- - -"}
                  <div
                    title={`${item?.path_fr?.join(">")}`}
                    style={{
                      fontSize: 12,
                      flex: 1,
                      display: "-webkit-box",
                      maxWidth: 400,
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                  >
                    {item?.path_fr?.join(">")}
                  </div>
                </div>
              ),
            },
            {
              ui: (
                <div>
                  <img
                    src={
                      item?.category_image?.web === ""
                        ? NoImgAvail
                        : item?.category_image?.web ?? NoImgAvail
                    }
                    alt="category.jpeg"
                    onError={(e: any) => {
                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = NoImgAvail; // Fallback image source
                    }}
                    height={50}
                    width={100}
                    style={{ objectFit: "contain", cursor: "pointer" }}
                    className="banner-img"
                  />
                </div>
              ),
              alignment: "center",
            },
            item?.added_by ?? "- - -",
            {
              ui: (
                <div
                  style={{ display: "flex" }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <ArrowCircleUpIcon
                    sx={{
                      color:
                        index === 0
                          ? defaultLabelColors?.color_g1
                          : defaultLabelColors?.main_orange,
                      cursor: index === 0 ? "not-allowed" : "pointer",
                      fontSize: 40,
                    }}
                    onClick={(e: any) => {
                      if (index !== 0) {
                        // SwapCategory(Number(item?.id), Number(1));
                      } else {
                        console.log("Upper limit exceed.");
                      }
                    }}
                  />

                  <ArrowCircleDownIcon
                    sx={{
                      color:
                        index === last_index
                          ? defaultLabelColors?.color_g1
                          : defaultLabelColors?.main_orange,
                      cursor:
                        index === last_index ? "not-allowed" : "pointer",
                      fontSize: 40,
                    }}
                    onClick={(e: any) => {
                      if (index !== last_index) {
                        // SwapCategory(Number(item?.id), Number(-1));
                      } else {
                        console.log("lower limit exceed.");
                      }
                    }}
                  />
                </div>
              ),
            },
            { name: JSON.stringify(item?.category_name), hidden: true },
          ]);
        }
        state.all_Categories = category;
        state.all_Categories_data = payload?.data;
        state.all_Categories_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      })
      //
      .addCase(allMainCategory.pending, (state: Categorystate) => {
        state.activity = true;
      })
      .addCase(
        allMainCategory.fulfilled,
        (state: Categorystate, { payload }) => {
          state.all_Categories_main_data = payload?.data;

          state.activity = false;
        }
      )
      // brand
      .addCase(allBrand.pending, (state: Categorystate) => {
        state.activity = true;
      })

      //
      .addCase(allBrand.fulfilled, (state: Categorystate, { payload }) => {
        let allBrand = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data[index];
          allBrand.push([
            { name: item?.id || "- - -", hidden: true },

            generateTrimmedID(item?.id || "- - -", ""),
            moment(
              `${item?.created_at}`.endsWith("Z")
                ? item?.created_at
                : item?.created_at + "Z"
            ).format("DD/MM/YYYY HH:mm"),

            item?.brand_name?.en ?? "- - -",
            item?.brand_name?.fr ?? "- - -",
            item?.brand_desc?.en ?? "- - -",
            item?.brand_desc?.fr ?? "- - -",
            {
              ui: (
                <div>
                  <img
                    // src={item?.brand_image.web}
                    src={
                      item?.brand_image?.web === ""
                        ? NoImgAvail
                        : item?.brand_image?.web ?? NoImgAvail
                    }
                    alt={"Frank.jpg"}
                    height={50}
                    width={100}
                    style={{ objectFit: "contain", cursor: "pointer" }}
                    className="banner-img"
                    onError={(e: any) => {
                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = NoImgAvail; // Fallback image source
                    }}
                  ></img>
                </div>
              ),
              alignment: "center",
            },

            item?.added_by ?? "- - -",
          ]);
        }
        state.all_brand = allBrand;
        state.all_brand_data = payload?.data;
        state.all_brand_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
        state.activity = false;
      })

      // Handle allAttribute actions
      .addCase(allAttribute.pending, (state: Categorystate) => {
        state.activity = true;
      })
      .addCase(allAttribute.fulfilled, (state: Categorystate, { payload }) => {
        let category = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data[index];
          if (item.is_deleted) continue;
          category.push([
            item?.id || "- - -",
            moment(
              `${item?.created_at}`.endsWith("Z")
                ? item?.created_at
                : item?.created_at + "Z"
            ).format("DD/MM/YYYY HH:mm"),
            item?.attribute_name?.en ?? "- - -",
            item?.attribute_name?.fr ?? "- - -",

            item?.attribute_count ?? "- - -",
            item?.added_by ?? "- - -",
            { name: item?.id, hidden: true },
            { name: JSON.stringify(item?.attribute_name), hidden: true },
          ]);
        }
        state.all_attribute = category;
        state.all_attribute_data = payload?.data;
        state.all_attribute_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      })
      // Handle spec actions
      .addCase(allSpec.pending, (state: Categorystate) => {
        state.activity = true;
      })
      .addCase(allSpec.fulfilled, (state: Categorystate, { payload }) => {
        let category = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data[index];
          if (item.is_deleted) continue;
          category.push([
            { name: item?.id || "- - -", hidden: true },

            generateTrimmedID(item?.id || "- - -", ""),

            moment(
              `${item?.created_at}`.endsWith("Z")
                ? item?.created_at
                : item?.created_at + "Z"
            ).format("DD/MM/YYYY HH:mm"),
            item?.specification_name?.en ?? "- - -",
            item?.specification_name?.fr ?? "- - -",

            item?.specification_values?.length ?? "0",
            item?.added_by ?? "- - -",
            { name: JSON.stringify(item?.specification_name), hidden: true },
          ]);
        }
        state.all_spec = category;
        state.all_spec_data = payload?.data;
        state.all_spec_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      });
  },
});

export const { setSelectedComments, clearallCategory } = orderSlice.actions;

export default orderSlice.reducer;
