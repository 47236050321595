export const defaultLabelColors = {
  color_e: "#181818",
  color_f: "#666666",
  color_g: "#eeeeee",
  color_h: "#f8f8f8",
  color_g1:"#D9D9D9",
  color_pink: "#FCE6DD",
  main_orange: "#ee6931",
  color_switch: "#E1D1D1",
  light_orange: "#f9c9b3",
  // sand: "#f9d9b6",
  light_sand:"#FEF5F1",
  sand:"#FCE6DD",
  color_white: "#ffff",
  main_blue: "#3026f1",
  dark_blue: "#191847",
  orange_yellow: "#d3be00",
  green: "green",
  red: "#f10000",
  color_odr_acp: "#0275d8",
  color_odr_plcd: "#f0ad4e",
  color_odr_pick: "#5bc0de",
  color_odr4_cncl: "#d9534f",
  color_odr_delv: "#5cb85c",

  light_gray: "lightgray",
};

export const dateRangeFilters = [
  { label: "All Time Records", value: "all_time" },
  { label: "This Year", value: "this_year" },
  { label: "This Quarter", value: "this_quarter" },
  { label: "This Month", value: "this_month" },
  { label: "This Week", value: "this_week" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Previous Week", value: "previous_week" },
  { label: "Previous Month", value: "previous_month" },
];

export const distance_units = [
  { label: "km (kilometer)", value: "KM" },
  { label: "mi (mile)", value: "MI" },
];
export const percent_fix = [
  { label: "% (of shipping amount)", value: "%", prefix: "%" },
  { label: "Fix amount", value: "fix", prefix: "€" },
];
export const entity_types = [
  { label: "All Customers", value: "all_customers" },
  // { label: "3P Customers", value: "3p_customers" },
  // { label: "App Customers", value: "app_customers" },
  // { label: "Business Customers", value: "business_customers" },
  { label: "All Transporters", value: "all_transporters" },
  // { label: "Fleet Managers", value: "fleetmanager" },
  // { label: "Riders", value: "riders" },
  // { label: "Sub Riders", value: "sub_riders" },
  // { label: "Air Riders", value: "air_riders" },

  { label: "All Vendors", value: "all_vendors" },
  // { label: "E-Merchants", value: "e_merchants" },
  // { label: "D2C Business", value: "d2c_business" },
  // { label: "Spaces", value: "spaces" },
];

export const platform_types = [
  { label: "All Platforms", value: null },
  { label: "Android", value: "android" },
  { label: "iOS", value: "ios" },
  { label: "HarmonyOS", value: "HMOS" },
  { label: "Website", value: "web" },
];

export const key_to_label: any = {
  all_transporters: "All Transporters",
  all_customers: "All Customers",
  all_vendors: "All Vendors",
  group: "Group",
  individual: "Individual",
  auto_push: "Auto Push",
  android: "Android",
  ios: "iOS",
  Android: "Android",
  iOS: "iOS",
  HMOS: "HMOS",
  web: "Web",
  Web: "Web",

  F: "Female",
  M: "Male",
  O: "Others",
  send_push: "Push Notification",
  send_email: "Email Notification",
  send_sms: "SMS Notification",
  INSERT: "created",
  click_and_collect: "Click & Collect ",
  express: "Express",
  regular: "Regular",
  relais: "Relais",
  "Mondial Relay": "Mondial Relay",
  "Mondial Relays": "Mondial Relays",

  self_delivery: "Shop Delivery",
  envelope: "En",
  small: "S",
  medium: "M",
  large: "L",
  extra_large: "XL",
  between_5_and_15_kg: "Medium",
  between_15_and_25_kg: "Large",
  less_than_5_kg: "Small",
  more_than_25_kg: "Extra Large",
  total: "Total Customer",
  new_user: "New User",
  frank_shop: "Frank Shop",
  storage: "Storage",
  shipment: "Annoucements",
  relocation: "Relocation",
  particular_order:"Particular",

  Regular: "Regular",
  Relais: "Relais",
  Express: "Express",
  Urgent: "Urgent",
  "I can wait": "I can wait",
  "Self Delivery": "Self Delivery",
  "I have time": "I have time",
  announcement: "Announcement",
  total_orders: "Total Orders",
  total_shipmets: "Total Annoucement",
  Picked: "Picked",

  Accepted: "Accepted",
  Ready: "Ready",
  Deliver: "Deliver",

  Placed: "Placed",
  Cancelled: "Cancelled",
  shop_order: "Frank Shop",
  pending: "Pending",
  in_transit: "In Transit",
  paid: "Paid",
  failed: "Failed",
  fixed_order: "Fixed Order",
  percentage: "Percentage",
  total_Sender: "Total Sender",
  total_transporter: "Total Transporter",
  emerchant: "E-merchant",
  customers: "Total Customers",
  sender: "Total Sender",
  transporter: "Total Transporter",
  "": "",
};

export const commission_types = [
  { label: "Rider", value: "Rider" },
  { label: "Fleet Manager", value: "Fleet Manager" },
  { label: "Air Rider", value: "Air Rider" },
  // { label: "Space Owner", value: "Space Owner" },
  // { label: "E-merchant & D2C", value: "E-merchant & D2C" },
];

export const shipping_rates_types = [
  { label: "Store Shipping", value: "Store Shipping" },
  { label: "C2C Shipping", value: "C2C Shipping" },
  { label: "Air Shipping", value: "Air Shipping" },
  // { label: "Space Storage", value: "Space Storage" },
];

export const maping_data = {
  from_distance: 0,
  to_distance: 0,
  prices: [
    {
      key: "Minimum",
      small_to: 0,
      small_from: 0,
      medium_to: 0,
      medium_from: 0,
      large_to: 0,
      large_from: 0,
      extra_large_to: 0,
      extra_large_from: 0,
      d_extra_large_to: 0,
      d_extra_large_from: 0,
    },
    {
      key: "Recommended",
      small_to: 0,
      small_from: 0,
      medium_to: 0,
      medium_from: 0,
      large_to: 0,
      large_from: 0,
      extra_large_to: 0,
      extra_large_from: 0,
      d_extra_large_to: 0,
      d_extra_large_from: 0,
    },
    {
      key: "Average",
      small_to: 0,
      small_from: 0,
      medium_to: 0,
      medium_from: 0,
      large_to: 0,
      large_from: 0,
      extra_large_to: 0,
      extra_large_from: 0,
      d_extra_large_to: 0,
      d_extra_large_from: 0,
    },
  ],
};

export const error_message =
  "Something went wrong. Please try again later. If the problem persists, contact support.";
export const GOOGLE_API_KEY = "AIzaSyDck8jd3P-F9dSPmSwzhKkIbCtvL4vzExs";
