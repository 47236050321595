/*
  Created By Muhammad Hassaan
  Date 15 April 2022
*/
import { TextField } from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";

export default function InputField(props: any) {
  const {
    input_label,
    required,
    value,
    classes,
    error,
    rows,
    multiline,
    disabled,
    // readOnly,
    input_label_2,
  } = props;
  return (
    <form
    // eslint-disable-next-line
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      style={{
        width: "100%",
        // display: "flex", alignItems: "center"
      }}
    >
      {input_label_2 ? (
        <div style={{ display: "flex" }}>
          <label
            className={classNames(
              classes.input_label_style,
              "RobotoCondensed-Regular",
              error && classes.color_red
            )}
            style={{ flex: 1 }}
          >
            {input_label}
            {required && !value ? <span style={{ color: "red" }}> *</span> : ""}
          </label>
          <span style={{ paddingRight: 15 }}>{input_label_2}</span>
        </div>
      ) : input_label ? (
        <label
          className={classNames(
            classes.input_label_style,
            "RobotoCondensed-Regular",
            error && classes.color_red
          )}
          style={{ paddingLeft: 10 }}
        >
          {input_label}
          {required && !value ? <span style={{ color: "red" }}> *</span> : ""}
        </label>
      ) : null}

      <TextField
        {...props}
        rows={rows}
        multiline={multiline}
        disabled={disabled}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius:3,
            // "& fieldset": {
            //   borderColor: defaultLabelColors?.main_orange, // Default color
            // },
            // // "&:hover fieldset": {
            // //   borderColor: "black", // Hover color
            // // },
            // "&.Mui-focused fieldset": {
            //   borderColor: defaultLabelColors?.sand, // Selected color
            // },
          },
        }}
        
        // aria-readonly={readOnly}
        // InputProps={{
        //   readOnly:readOnly,
        // }}

      />
    </form>
  );
}
InputField.defaultProps = {
  input_label: "",
  value: "",
  name: "",
  id: "",
  required: false,
  fullWidth: true,
  error: false,
  classes: {},
  size: "small",
  variant: "outlined",
  type: "text",
  placeholder: "",
  InputProps: null,
  inputProps: null,
  InputLabelProps: null,
  style: null,
  onChange: null,
  rows: 1,
  multiline: false,
  disabled: false,
  input_label_2: "",
  helperText: "",
  // readOnly: false,  // Added readOnly default prop

};

InputField.propTypes = {
  input_label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["outlined", "standard"]),
  required: PropTypes.bool,
  classes: PropTypes.object,
  InputProps: PropTypes.any,
  inputProps: PropTypes.any,
  InputLabelProps: PropTypes.any,
  style: PropTypes.any,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.any,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  input_label_2: PropTypes.any,
  helperText: PropTypes.string,
  // readOnly: PropTypes.bool,  // Added readOnly prop type

  onKeyPress: PropTypes.any,
};
