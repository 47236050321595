import { ExpandMore } from "@mui/icons-material";
import { Masonry } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import apiServices from "services/RequestHandler";
import { RootState } from "store";
import { setSubTitle, setTitle } from "store/breadcrumbReducer";
import { allMainCategory } from "store/catalogReducer";
import { C2COrderByShop } from "store/orderReducers";
import {
  clearSelectedShop,
  singleMiniShop,
  togglePanels,
} from "store/vendorReducers";
import ActivityLogs from "ui-components/ActivityLog";
import Button from "ui-components/Button";
import Comments from "ui-components/Comments";
import Datatable from "ui-components/Datatable";
import DraggableDialog from "ui-components/DraggableDialog";
import LoadingComponent from "ui-components/LoadinComponent";
import ReactHelmet from "ui-components/ReactHelmet";
import SwitchFeild from "ui-components/SwitchFeild";
import Toast from "ui-components/Toast";
import {
  global_error_message,
  numberFormatWithoutCurrency,
} from "utils/helper";
import Profile from "views/Vendors/MiniShop/MiniShopByID/Profile";
import ProfileInfo from "views/Vendors/MiniShop/MiniShopByID/ProfileInfo";
import Status from "views/Vendors/MiniShop/MiniShopByID/Status";
import Other from "./Other";
import ServiceMethod from "./ServiceMethod";
import ShopTiming from "./ShopTiming";
import DatePicker from "ui-components/DatePicker";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ height: 370, marginTop: value === 0 ? 30 : 5 }}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function MiniShopByID(props: any) {
  const { classes } = props;
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const vendorSlice = useSelector((state: RootState) => state.vendors);
  const OrderByShop = useSelector((state: RootState) => state.orders);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      e_merchant: null,
      manage_miniShop: false,
      product_detail: false,
      selected_tab: 0,
      is_changed: false,
      shop_edit: false,
      sponsor_box: false,
    }
  );

  let { id } = useParams();
  React.useEffect(() => {
    getMiniShop();
    dispatch(allMainCategory({ end_point: `/c2c-categories` }));

    getShopOrderById();
    getShopProduct();

    return () => {
      dispatch(setSubTitle(null as any));
      dispatch(togglePanels(true as any));
      setState({
        products: [],
        orders: [],
      });
      dispatch(clearSelectedShop(null as any));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setProductnOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(vendorSlice?.single_mini_shop_data)]);

  const getMiniShop = async () => {
    try {
      dispatch(
        singleMiniShop({
          end_point: `/c2c-shop-configure/get-by-id/${id}`,
        } as any)
      );
      dispatch(setTitle("Frank shop" as any));
    } catch (error) {
      console.log(error);
    }
  };
  const getShopOrderById = async () => {
    try {
      dispatch(
        C2COrderByShop({
          end_point: `/c2c-orders/admin/by-shop`,
          shop_id: Number(id),
          page: 1,
          limit: 50,
        })
      );
    } catch (error) {}
  };
  const getShopProduct = async () => {
    try {
      // dispatch(
      //   allProduct({
      //     end_point: `/c2c-product-masters/all-by-shop/${id}`,
      //     shop_id: Number(id),
      //     page: 1,
      //     limit: 50,
      //   })
      // );
      const resp = await apiServices.postFromApi(
        `/c2c-product-masters/all-by-shop/${id}`,
        {},
        ""
      );
      if (resp?.status === 200 || resp?.status === 201) {
        let _products = [];

        if (resp?.data) {
          for (let index = 0; index < resp?.data?.length; index++) {
            const item = resp?.data[index];

            _products.push([
              item?.id || "- - -",
              moment(
                `${item.created_at}`.endsWith("Z")
                  ? item.created_at
                  : item.created_at + "Z"
              ).format("DD/MM/YYYY HH:mm"),

              item?.product_title?.en || "- - -",

              item?.brand_name?.en || "- - -",
              item?.category_name?.en || "- - -",

              `${numberFormatWithoutCurrency(
                item?.product_variations[0]?.price ?? 0
              )} €` || "0",
              // eslint-disable-next-line

              {
                // eslint-disable-next-line

                ui: (
                  <div>
                    <img
                      src={item?.images[0]}
                      alt="frank.jpeg"
                      height={50}
                      width={100}
                      style={{ objectFit: "contain", cursor: "pointer" }}
                      className="banner-img"
                    ></img>
                  </div>
                ),
              },
            ]);
          }
        }

        setState({ products: _products, all_product: resp?.data });
      }
    } catch (error) {}
  };

  const setProductnOrder = (): void => {
    try {
      dispatch(
        setSubTitle(
          `${
            vendorSlice?.single_mini_shop_data?.shop_name ??
            `${vendorSlice?.single_mini_shop_data?.first_name ?? ""}'Shop` ??
            ""
          }` as any
        )
      );

      let _data = vendorSlice?.single_mini_shop_data;

      setState({
        mini_shop: {
          ..._data,
          selected_is_active: {
            label: _data?.is_active ? "Active" : "Inactive",
            value: _data?.is_active ?? false,
          },
          selected_is_verified: {
            label: _data?.is_verified ? "Verified" : "Not Verified",
            value: _data?.is_verified ?? false,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(state?.products)
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setState({
      selected_tab: newValue,
    });
  };

  const handleSelect = (name: any, event: any, values: any): void => {
    try {
      setState({
        mini_shop: {
          ...state.mini_shop,
          [name]: values?.value ?? null,
          [`selected_${[name]}`]: values ? values : null,
        },
        is_changed: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateStatus = async (): Promise<void> => {
    try {
      let final_obj = {
        id: Number(state.mini_shop?.id),
        is_active: state.mini_shop?.selected_is_active?.value ?? false,
        is_verified: state.mini_shop?.selected_is_verified?.value ?? false,
      };
      // console.log(final_obj);
      const resp = await apiServices.postFromApi(
        "c2c-shop-configure/activate",
        final_obj,
        ""
      );
      if (resp.status === 201) {
        Toast.showSuccess(`Status updated successfully`);
        setState({
          manage_miniShop: false,
        });
        getMiniShop();
      } else {
        Toast.showError(
          // "Unable perform action, please try again, if errors again then contact to our Support Team"
          resp.error.message ?? global_error_message
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickRow = (event: any, row: any, index: any) => {
    try {
      if (row[0]) {
        const selected_customer = Number(row[0].replace("ODR-", ""));
        navigate(`/oms/orders/${selected_customer}`);

        setState({
          selected_customer,
          prod_id: index,
        });
      } else {
        setState({
          selected_customer: null,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickRowProduct = (event: any, row: any, index: any) => {
    try {
      if (row[0]) {
        const p_id = row[0];
        navigate(`/vendors/frank-shops/manage/${id}/${p_id}`);

        setState({
          p_id,
          product_detail: true,
        });
      } else {
        setState({
          p_id: null,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getShopOrderByFilter = async (
    start_date: any,
    end_date: any,
    page_number: any,
    limit: any,
    searching: any
  ): Promise<void> => {
    try {
      let query = `/c2c-orders/admin/by-shop`;
      // end_point: `/c2c-orders/admin/by-shop`,

      if (start_date && end_date) {
        // query += `&start=${start_date}&end=${end_date}`;
      }
      dispatch(
        C2COrderByShop({
          end_point: query,
          shop_id: Number(id),
          page: page_number,
          limit: limit,
          start_date: start_date,
          end_date: end_date,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const reqSponsorShop= async()=>{
try {
  if(!state?.start_date){
    Toast.showError("Please select Start Date")
    return;
  }
  if(!state?.end_date){
    Toast.showError("Please select End Date")
    return;
  }
  const send_data:any={
    shop_id: Number(id),
    package_name: "sponsor",
    package_level: 1,
    start_date: state?.start_date,
    end_date:  state?.end_date,
  }
  // console.log(send_data)
  // return;
  const resp=await apiServices.postFromApi("/shop-sponsor-ships",send_data,"")
  if(resp?.status===201|| resp?.status===200){
    Toast.showSuccess("The shop has been successfully sponsored and activated.")
setState({sponsor_box:false})
  }
  else{
    Toast.showError(resp?.error??resp?.error.error??resp?.message??global_error_message)
  }
} catch (error) {
  console.log(error,"error");
  
}
  }
  return (
    <div className={classes.main_layout_padding}>
      <ReactHelmet title={"Frank Shop Detail | Frank "} />

      <LoadingComponent activity={vendorSlice.activity} />
      <Grid container spacing={2}>
        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            justifyContent: "space-between",
            padding: 10,
          }}
        >
          <Button
            value={"Back to All"}
            classesNames={classNames(
              classes.button_width,
              classes.outlined_button
            )}
            onClick={() => navigate(-1)}
          />

          <div style={{display:"flex",gap:20,}}>
            <Button
              value={"Sponsor Shop"}
              classesNames={classNames(
                classes.button_width,
                classes.contained_button
              )}
              onClick={() =>
                setState({
                  sponsor_box: true,
                })
              }
            />
            <SwitchFeild
              input_label="Edit"
              classes={classes}
              check={state?.shop_edit}
              onChange={(e: any) => {
                setState({ shop_edit: e?.target?.checked });
              }}
            />
          </div>
        </Grid>
        <Box sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
          <Masonry
            columns={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
            spacing={2}
            defaultHeight={100}
          >
            <div>
              <Profile
                {...props}
                {...state}
                panel_head={"Shop Detail"}
                shop_edit={state?.shop_edit}
                // from_vendors={true}
                details={state?.mini_shop}
                getMiniShop={() => {
                  setState({ shop_edit: false });
                  getMiniShop();
                }}
              />
            </div>

            <div>
              <Status
                // total order,is_verified,is_active dhekna a status me
                {...state}
                {...props}
                handleSelect={handleSelect}
                details={state.mini_shop}
                panel_head={"Status & Summary"}
                shop_edit={state?.shop_edit}
                handleUpdateStatus={handleUpdateStatus}
              />
            </div>
            <div>
              {" "}
              <Other
                {...props}
                {...state}
                panel_head={"Shop Address"}
                // from_vendors={true}
                details={vendorSlice?.single_mini_shop_data}
                shop_edit={state?.shop_edit}
                id={id}
                getMiniShop={() => {
                  setState({ shop_edit: false });
                  getMiniShop();
                }}
              />
            </div>

            <div>
              {" "}
              <ShopTiming
                {...props}
                {...state}
                panel_head={"Business Hours"}
                // from_vendors={true}
                details={vendorSlice?.single_mini_shop_data}
                getMiniShop={() => {
                  setState({ shop_edit: false });
                  getMiniShop();
                }}
              />
            </div>
            <div>
              {" "}
              <ServiceMethod
                // total order,is_verified,is_active dhekna a status me
                {...props}
                {...state}
                panel_head={"Service Method"}
                // from_vendors={true}
                details={vendorSlice?.single_mini_shop_data}
                shop_edit={state?.shop_edit}
                id={id}
                getMiniShop={() => {
                  setState({ shop_edit: false });
                  getMiniShop();
                }}
              />
            </div>
          </Masonry>
        </Box>

        {/* tables */}
        <Grid xs={12} item>
          <Accordion
            defaultExpanded
            // expanded={
            //   state?.from_vendors
            //     ? vendorSlice.panels.status
            //     : miniShop.panels.status
            // }
            className={classNames(classes.accordion_container)}
          >
            <AccordionSummary
              className={classNames(classes.accordion_head_container)}
              expandIcon={
                <ExpandMore
                  className={classes.accordion_icons}
                  style={{ fontSize: 24 }}
                />
              }
            >
              <span
                className={classNames(classes.accordion_heading)}
                style={{ flex: 1 }}
              >
                {"Products"}
              </span>
            </AccordionSummary>

            <AccordionDetails className={classes.accordion_detail_padding}>
              <Datatable
                columns={[
                  { name: " ID", alignment: "left" },
                  { name: " Created at", alignment: "left" },

                  { name: "Title", alignment: "left" },
                  { name: "Brand", alignment: "left" },
                  { name: "Category", alignment: "left" },
                  { name: "Price", alignment: "left" },

                  { name: "Image", alignment: "left" },
                  // { name: "Description", alignment: "left" },
                  // { name: "Color", alignment: "left" },
                  // { name: "Size", alignment: "left" },
                ]}
                // rows={productSlice?.all_product || []}

                rows={state?.products || []}
                onRowClick={handleClickRowProduct}
                min_width={"100%"}
                ariaLabel={"Product Table"}
                // searchSize={"none"}
                classes={classes}
                radioButton={false}
                show_header={true}
                // filterByType={false}
                // handleUpdateRecords={getShopOrderByFilter}
                // enablePagination
                // previous_count={Number(
                //   productSlice?.all_product_paginate_data?.previous_count ?? 0
                // )}
                // current_count={Number(
                //   productSlice?.all_product_paginate_data?.current_count ?? 0
                // )}
                // total_records={Number(
                //   productSlice?.all_product_paginate_data?.total_count ?? 0
                // )}
                // count={Number(
                //   productSlice?.all_product_paginate_data?.total_pages ?? 1
                // )}
                show_button
                btn_disable={false}
                // btn_classes2={classes}
                btn_label={"Add Product "}
                // btn_disable={true}
                onButtonClick={() =>
                  // setState({ product_detail: true })
                  //       shop_id={id}
                  // prod_id={state?.p_id}
                  navigate(`/vendors/frank-shops/create/${id}`)
                }
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid xs={12} item>
          <Accordion
            defaultExpanded
            // expanded={
            //   state?.from_vendors
            //     ? vendorSlice.panels.status
            //     : miniShop.panels.status
            // }
            className={classNames(classes.accordion_container)}
          >
            <AccordionSummary
              className={classNames(classes.accordion_head_container)}
              expandIcon={
                <ExpandMore
                  className={classes.accordion_icons}
                  style={{ fontSize: 24 }}
                />
              }
            >
              <span
                className={classNames(classes.accordion_heading)}
                style={{ flex: 1 }}
              >
                {"Orders"}
              </span>
            </AccordionSummary>

            <AccordionDetails className={classes.accordion_detail_padding}>
              <Datatable
                columns={[
                  { name: "Order No", alignment: "left" },
                  { name: "Order Date.", alignment: "left" },
                  { name: "Customer Name", alignment: "left" },
                  { name: "Total Product Price", alignment: "left" },
                  { name: "Shop Name", alignment: "left" },
                  { name: "Order Status ", alignment: "left" },

                  { name: "Service Method ", alignment: "left" },
                  { name: "Platform ", alignment: "left" },
                  { name: "Payment  Method", alignment: "left" },
                  { name: "Payment Status", alignment: "left" },
                  { name: "Expected Delivery Date", alignment: "left" },
                  { name: "Rider Name", alignment: "left" },
                  { name: "Products", alignment: "left" },
                  { name: "Completed At / Cancelled At", alignment: "left" },
                ]}
                rows={OrderByShop?.c2c_orders_by_shop || []}
                onRowClick={handleClickRow}
                min_width={"100%"}
                ariaLabel={"Product Table"}
                // searchSize={"none"}
                classes={classes}
                radioButton={true}
                show_header={true}
                filterByType={false}
                handleUpdateRecords={getShopOrderByFilter}
                enablePagination
                previous_count={Number(
                  OrderByShop?.c2c_orders_by_shop_paginate_data
                    ?.previous_count ?? 0
                )}
                current_count={Number(
                  OrderByShop?.c2c_orders_by_shop_paginate_data
                    ?.current_count ?? 0
                )}
                total_records={Number(
                  OrderByShop?.c2c_orders_by_shop_paginate_data?.total_count ??
                    0
                )}
                count={Number(
                  OrderByShop?.c2c_orders_by_shop_paginate_data?.total_pages ??
                    1
                )}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid xs={12} item>
          <Accordion
            defaultExpanded={false}
            // expanded={
            //   state?.from_vendors
            //     ? vendorSlice.panels.status
            //     : miniShop.panels.status
            // }
            className={classNames(classes.accordion_container)}
          >
            <AccordionSummary
              className={classNames(classes.accordion_head_container)}
              expandIcon={
                <ExpandMore
                  className={classes.accordion_icons}
                  style={{ fontSize: 24 }}
                />
              }
            >
              <span
                className={classNames(classes.accordion_heading)}
                style={{ flex: 1 }}
              >
                {"Comment"}
              </span>
            </AccordionSummary>

            <AccordionDetails
            // className={classes.accordion_detail_padding}
            >
              {id && <Comments id={id} margin={0} classes={classes} />}
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Activity log */}
        <Grid xs={12} item>
          <Accordion
            defaultExpanded={false}
            className={classNames(classes.accordion_container)}
          >
            <AccordionSummary
              className={classNames(classes.accordion_head_container)}
              expandIcon={
                <ExpandMore
                  className={classes.accordion_icons}
                  style={{ fontSize: 24 }}
                />
              }
            >
              <span
                className={classNames(classes.accordion_heading)}
                style={{ flex: 1 }}
              >
                {"Activity Logs"}
              </span>
            </AccordionSummary>

            <AccordionDetails>
              <ActivityLogs id={id} margin={0} classes={classes} />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
              
      <DraggableDialog
        open={state?.sponsor_box}
        handleClose={() => setState({ sponsor_box: false, p_id: null })}
        dialog_title={"Sponsor Shop"}
        classes={classes}
        maxWidth={"sm"}
        dialogBody={
          <div >
            <div id="parent_date"
            style={{display:"flex",justifyContent:"center",alignItems:"center",gap:20,padding:20}}
            >
              <div style={{ width: "33%" }}>
                <DatePicker
                  fullWidth
                  size={"small"}
                  classes={classes}
                  variant={"outlined"}
                  input_label={"Start Date"}
                  minDate={moment("2020-12-12")}
                  maxDate={moment(state?.end_date)}
                  placeholder={"DD/MM/YYYY"}
                  value={
                    state.start_date
                      ? moment(state.start_date)?.format("YYYY/MM/DD")
                      : null
                  }
                  textFeildClasses={classNames(
                    classes?.input_feild,
                    "Roboto-Medium"
                  )}
                  handleDateChange={(date: any) => {
                    setState({
                      start_date: date
                        ? moment(date)?.format("YYYY-MM-DD")
                        : null,
                    });
                  }}
                  height={43}
                  // disabled={!state?.editable}
                />
              </div>
              <div style={{ width: "33%" }}>
                <DatePicker
                  fullWidth
                  size={"small"}
                  classes={classes}
                  variant={"outlined"}
                  minDate={moment(state?.start_date)}
                  value={state.end_date ? new Date(state.end_date) : null}
                  // minDate={state?.start_date}
                  textFeildClasses={classNames(
                    classes?.input_feild,
                    "Roboto-Medium"
                  )}
                  handleDateChange={(date: any) => {
                    setState({
                      end_date: date ? moment(date).format("YYYY-MM-DD") : null,
                    });
                  }}
                  height={43}
                  input_label={"End Date"}
                  // disabled={!state?.editable}
                  placeholder={"DD/MM/YYYY"}
                />
              </div>
            </div>
            <div id="Parent_btn"
            style={{
              display:"flex",justifyContent:"flex-end",gap:20,padding:10
            }}
            >
            <Button
              value={"Cancel"}
              classesNames={classNames(
                classes.button_width,
                classes.outlined_button
              )}
              onClick={() =>
                setState({
                  sponsor_box: false,
                })
              }
            />
            <Button
              value={"Confirm"}
              classesNames={classNames(
                classes.button_width,
                classes.contained_button
              )}
              onClick={() =>
                reqSponsorShop()
              }
            />
             
            </div>
          </div>
        }
        dialogFooter={false}
      />

      <DraggableDialog
        open={state.manage_miniShop}
        handleClose={() => {
          setState({ manage_miniShop: false });
        }}
        dialog_title={"Manage Frank shop"}
        classes={classes}
        maxWidth={"lg"}
        dialogBody={
          <DialogContent dividers>
            <Tabs
              value={state.selected_tab}
              onChange={handleChangeTab}
              textColor="inherit"
              style={{ borderBottom: "2px solid lightgray" }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#ee6931",
                },
              }}
              sx={{
                "& .MuiTabs-scroller": {
                  "&. MuiTabs-flexContainer": {
                    zIndex: 2,
                    position: "relative",
                  },
                },
              }}
            >
              <Tab
                className={classNames(
                  state.selected_tab === 0
                    ? classes?.active_tab
                    : classes?.inactive_tab
                )}
                label={`Frank shop Profile`}
                {...a11yProps(0)}
              />
              {/* <Tab
                    className={classNames(
                      state.selected_tab === 1
                        ? classes?.active_tab
                        : classes?.inactive_tab
                    )}
                    label="Space Documents"
                    {...a11yProps(1)}
                  /> */}
            </Tabs>
            <SwipeableViews
              index={state.selected_tab}
              onChangeIndex={(val) => handleChangeTab(null as any, val)}
              style={{ marginTop: 15 }}
            >
              <TabPanel value={state.selected_tab} index={0}>
                <ProfileInfo
                  {...state}
                  {...props}
                  handleSelect={handleSelect}
                  details={state.mini_shop}
                />
              </TabPanel>
            </SwipeableViews>
          </DialogContent>
        }
        dialogFooter={
          <DialogActions style={{ padding: "20px" }}>
            <div style={{ flex: 1 }}>
              {state.selected_tab === 0 ? (
                <Button
                  classesNames={classNames(
                    classes.button_width,
                    classes.outlined_button
                  )}
                  disable={state.is_changed ? false : true}
                  onClick={handleUpdateStatus}
                  style={{ marginRight: 20 }}
                  value={"Update"}
                />
              ) : (
                <span className={classes.radio_label} style={{ color: "gray" }}>
                  Space Shop - Essence Discovers
                </span>
              )}
            </div>
            {state.selected_tab === 0 && (
              <Button
                classesNames={classNames(
                  classes.button_width,
                  classes.outlined_button
                )}
                style={{ marginRight: 20 }}
                value={"Copy as Text"}
              />
            )}
            <Button
              classesNames={classNames(
                classes.button_width,
                classes.outlined_button
              )}
              value="Print"
              style={{ marginRight: 20 }}
            />
            <Button
              classesNames={classNames(
                classes.button_width,
                classes.contained_button
              )}
              value="Close"
              onClick={() => {
                setState({ manage_miniShop: false });
                let _data = vendorSlice?.single_mini_shop_data;
                setState({
                  mini_shop: {
                    ..._data,
                    selected_is_active: {
                      label: _data?.is_active ? "Active" : "Inactive",
                      value: _data?.is_active ?? false,
                    },
                    selected_is_verified: {
                      label: _data?.is_verified ? "Verified" : "Not Verified",
                      value: _data?.is_verified ?? false,
                    },
                  },
                });
              }}
            />
          </DialogActions>
        }
      />
    </div>
  );
}
