import {
  ExpandMore,
  LockOpenOutlined,
  LockOutlined
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  StyledEngineProvider,
} from "@mui/material";
import classNames from "classnames";
import { useReducer } from "react";
import Button from "ui-components/Button";
import SelectField from "ui-components/SelectField";
import InputField from "ui-components/TextFeild";

export default function Status(props: any) {
  const { classes, panel_head, details, handleSelect,handleUpdateStatus,shop_edit } = props;
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      enable_status: false,
      enable_verification: false,
    }
  );

  // const dispatch = useDispatch<any>();
  // const miniShop = useSelector((state: RootState) => state.transporterSlice);
  // const vendorSlice = useSelector((state: RootState) => state.vendors);
  // React.useEffect()
  return (
    <StyledEngineProvider injectFirst>
      <Accordion
        defaultExpanded
        // expanded={
        //   from_vendors ? vendorSlice.panels.status : miniShop.panels.status
        // }
        className={classNames(classes.accordion_container)}
      >
        <AccordionSummary
          className={classNames(classes.accordion_head_container)}
          expandIcon={
            <ExpandMore
              className={classes.accordion_icons}
              style={{ fontSize: 24 }}
              // onClick={() =>
              //   dispatch(
              //     from_vendors
              //       ? toggleStatus(!vendorSlice.panels.status as any)
              //       : handleChangeStatus(!miniShop.panels.status as any)
              //   )
              // }
            />
          }
        >
          <span
            // onClick={() =>
            //   dispatch(
            //     from_vendors
            //       ? toggleStatus(!vendorSlice.panels.status as any)
            //       : handleChangeStatus(!miniShop.panels.status as any)
            //   )
            // }
            className={classNames(classes.accordion_heading)}
            style={{ flex: 1 }}
          >
            {panel_head}
          </span>
          {/* <ContentCopyOutlined
            style={{ marginRight: 10 }}
            className={classNames(classes.accordion_icons)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <PictureAsPdfOutlined
            style={{ marginRight: 10 }}
            className={classNames(classes.accordion_icons)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          /> */}
        </AccordionSummary>

        <AccordionDetails className={classes.accordion_detail_padding}>
          <Grid container spacing={4}>
            <Grid xs={12} md={6} item 
            
            className={
              !props.shop_edit?classes.disable_box:""
            }
            >
              

              <div
                style={{ flex: 1, display: "flex" }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                <div style={{ flex: 1 }}>
                  <SelectField
                    disableClearable
                    isDisabled={!state.enable_status}
                    input_label={"Active"}
                    name={"is_active"}
                    value={
                      
                      {
                        label: details?.is_active ? "Active" : "Inactive",
                        value: details?.is_active ?? false,
                      }
                    }
                    handleSelect={handleSelect}
                    options={[
                      { label: "Active", value: true },
                      { label: "Inactive", value: false },
                    ]}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 30,
                  }}
                >
                  {state.enable_status ? (
                    <LockOpenOutlined
                      style={{ color: "gray" }}
                      onClick={() =>
                        setState({ enable_status: !state.enable_status })
                      }
                    />
                  ) : (
                    <LockOutlined
                      style={{ color: "gray" }}
                      onClick={() =>
                        setState({ enable_status: !state.enable_status })
                      }
                    />
                  )}
                </div>
              </div>
            </Grid>
            <Grid xs={12} md={6} item
             className={
              !props.shop_edit?classes.disable_box:""
            }
            >
              {/* <InputField
                fullWidth
                value={details?.is_active ? "Active" : "Inactive"}
                input_label={"Active"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              /> */}

              <div
                style={{ flex: 1, display: "flex", alignItems: "center" }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                <div style={{ flex: 1 }}>
                  <SelectField
                    disableClearable
                    isDisabled={!state.enable_verification}
                    handleSelect={handleSelect}
                    input_label={"Verification"}
                    value={
                      // details?.is_verified
                      {
                        label: details?.is_verified
                          ? "Verified"
                          : "Not Verified",
                        value: details?.is_verified,
                      }
                    }
                    name={"is_verified"}
                    options={[
                      { label: "Verified", value: true },
                      { label: "Not Verified", value: false },
                    ]}
                    inputProps={{
                      className: classNames(classes?.input_label_style),
                      }}
                  />
                </div>
                <div
                 style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 30,
                }}
                >

                {state.enable_verification ? (
                  <LockOpenOutlined
                  style={{ color: "gray" }}
                    onClick={() =>
                      setState({
                        enable_verification: !state.enable_verification,
                      })
                    }
                    />
                  ) : (
                    <LockOutlined
                    style={{ color: "gray" }}
                    onClick={() =>
                      setState({
                        enable_verification: !state.enable_verification,
                      })
                    }
                    />
                  )}
                  </div>
              </div>
            </Grid>

            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={details?.orders?.length ?? "0"}
                input_label={"Total Orders"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={`€ 0`}
                input_label={"Total Sale"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={12} item>
              <div className={classes.justify_end}>
               
                 <Button
                                value={"Update"}
                                classesNames={classNames(
                                                    "Raleway-Bold",
                                                    shop_edit
                                                      ? classes.contained_button
                                                      : classes.disabled_button,
                                
                                                    classes.button_width
                                                  )}
                                                  disable={shop_edit ? false : true}
                                onClick={() => {
                                  handleUpdateStatus();
                                }}
                              />
              </div>
            </Grid>
            {/* {details?.service_method?.map((item:any)=>{
              // return()
              <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={ item?.owner?? "0"}
                input_label={"Total Orders"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            })} */}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </StyledEngineProvider>
  );
}
