/*
  Created By Muhammad Hassaan
  Date 12 April 2023
*/
import { useTheme } from "@emotion/react";
import React from "react";
import styled from "@emotion/styled";
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Outlet } from "react-router";
import { RootState } from "store";
import { drawerWidth } from "store/constants";
import { handleClose, handleOpen } from "store/drawerReducer";
import Breadcrumbs from "ui-components/extended/Breadcrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
import { useNavigate, useLocation } from "react-router-dom";
import { defaultLabelColors } from "utils/constants";
// styles
const Main = styled("main", {
  shouldForwardProp: (prop: any) => prop !== "open",
})(({ theme, open }: any) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -drawerWidth,
      // width: `calc(100% - ${drawerWidth}px)`,
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      // width: `calc(100% - ${drawerWidth}px)`,
      width: "100%",
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: "100%",
      // width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginRight: "10px",
    },
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: "100%",
    },
  }),
  marginTop: 48,
}));

export default function MainLayout(props: any) {
  const { classes } = props;
  const theme = useTheme() as any;
  const navigate = useNavigate();
  const location = useLocation();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const leftDrawerOpened = useSelector(
    (state: RootState) => state.drawer.opened
  );
  // useSelector(
  //   (state: any) => state.customization.opened
  // );
  const dispatch = useDispatch();

  const handleLeftDrawerToggle = () => {
    dispatch(leftDrawerOpened ? handleClose() : handleOpen());
  };
  React.useEffect(() => {
    checkUser();
    // eslint-disable-next-line
  }, []);

  const checkUser = () => {
    try {
      let user = getDataFromLocalStorage("user");
      if (user) {
        navigate(location.pathname);
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: defaultLabelColors?.sand,//sand
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
        }}
      >
        <Toolbar style={{ padding: 0, }}>
          <Header
            handleLeftDrawerToggle={handleLeftDrawerToggle}
            drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
          />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
        classes={classes}
      />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        <Breadcrumbs />
        <Outlet />
      </Main>
    </Box>
  );
}
