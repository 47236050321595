import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  StyledEngineProvider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import classNames from "classnames";
import { useEffect, useReducer } from "react";
import apiServices from "services/RequestHandler";
import SwitchFeild from "ui-components/SwitchFeild";
import Toast from "ui-components/Toast";
import { defaultLabelColors } from "utils/constants";

export default function ServiceMethod(props: any) {
  const { classes, panel_head, details, id, shop_edit } = props;
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),

    {}
  );

  const getServiceMethod = async () => {
    setState({ loader: true });

    try {
      const resp = await apiServices.getFromApi("/c2c-service-methods", "");
      if (resp?.status === 200) {
        setState({ api_service_method: resp?.data, loader: false });
        // console.log(details?.service_methods?.length, "length");
        const service_method_avail = resp?.data.map((item2: any) => {
          const match = details?.service_methods?.find(
            (item1: any) => String(item1.service_method_id) === item2.id
          );

          return {
            id: item2.id,
            name: item2.service_method_name.en,
            available: match ? true : false,
          };
        });
        // console.log(service_method_avail);

        setState({ service_method_avail });
      } else {
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setState({ loader: false });
    }
  };
  useEffect(() => {
    setState({ shop_detail: details });
    getServiceMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.details]);
  const activeService = async (idd?: any) => {
    try {
      // if()/
      const resp = await apiServices.patchFromApi(
        "/c2c-shop-configure/service-methods/admin",
        {
          shop_id: id,
          service_method_id: idd,
          is_default: true,
        },
        ""
      );
      if (resp?.status === 201) {
        Toast.showSuccess("Service method updated successfully.");
        getServiceMethod();

        props.getMiniShop();
      } else {
        Toast.showError("Something went wrong while updating Service method.");
        getServiceMethod();

        props.getMiniShop();
      }
    } catch (err) {
      console.error("Error Updating Service method :", err);
      Toast.showError("Something went wrong while processing your request.");
    }
  };
  return (
    <StyledEngineProvider injectFirst>
      <Accordion
        defaultExpanded
        className={classNames(classes.accordion_container)}
      >
        <AccordionSummary
          className={classNames(classes.accordion_head_container)}
          expandIcon={
            <ExpandMore
              className={classes.accordion_icons}
              style={{ fontSize: 24 }}
            />
          }
        >
          <span
            className={classNames(classes.accordion_heading)}
            style={{ flex: 1 }}
          >
            {panel_head}
          </span>
        </AccordionSummary>

        <AccordionDetails className={classes.accordion_detail_padding}>
          <TableContainer>
            <Table
              style={{
                minWidth: "max-content",
                width: "100%",
                pointerEvents: !shop_edit ? "none" : "auto",
                opacity: !shop_edit ? 0.6 : 1,
              }}
            >
              <TableHead>
                <TableRow
                // className={classes?.background_light_gray}
                >
                  <div
                    style={{
                      color: defaultLabelColors?.main_orange,
                      fontFamily: "Raleway-Bold",
                      fontSize: 22,
                    }}
                  >
                    Service Method
                  </div>
                </TableRow>
              </TableHead>
              <TableBody>
                {state?.service_method_avail?.map(
                  (item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell
                        className={classNames("padding-10")}
                        style={{
                          fontSize: 18,
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          color: defaultLabelColors?.main_orange,
                          font: "Raleway-Bold !important",
                        }}
                      >
                        {item?.name}
                      </TableCell>
                      <TableCell
                        className={classNames(
                          "padding-10"
                        )}
                      >
                        <SwitchFeild
                          input_label={item?.available ? "Available" : ""}
                          check={item?.available}
                          onChange={(event: any) => {
                            event.stopPropagation();
                            activeService(item?.id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </StyledEngineProvider>
  );
}
