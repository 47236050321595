import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { key_to_label } from "utils/constants";
import { capitalizeFirstWord, numberFormatWithoutCurrency } from "utils/helper";
export interface PayoutState {
  all_payout: any;
  all_payout_data: any;
  payout_order_id: any;
  all_payout_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
  all_payout_hist: any;
  all_payout_data_hist: any;
  all_payout_hist_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
  selected_customer: any;
  activity: boolean;
}
const initialState: PayoutState = {
  all_payout: [],
  all_payout_data: [],
  payout_order_id: null,
  all_payout_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  all_payout_hist: [],
  all_payout_data_hist: [],
  all_payout_hist_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  selected_customer: {},
  activity: false,
};

export const allPayouts = createAsyncThunk(
  "/c2c-payouts",
  async (data: any) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        {
          page: data.page,
          limit: data.limit,
          start_date: data?.start_date,
          end_date: data?.end_date,
          order_date_from: data?.order_date_from,
          order_date_to: data?.order_date_to,

          delivery_date_from: data?.delivery_date_from,
          delivery_date_to: data?.delivery_date_to,

          total_amount_from: data?.total_amount_from,
          total_amount_from_operator: data?.total_amount_from_operator,
          total_amount_to: data?.total_amount_to,
          total_amount_to_operator: data?.total_amount_to_operator,
          order_type: data?.order_type,
          service_method: data?.service_method,
          name: data?.name,
          resource_id: data?.resource_id,
        },
        "/payout"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);
export const allPayoutsHist = createAsyncThunk(
  "/c2c-payouts-hist",
  async (data: any) => {
    try {
      const { end_point, ...payload } = data;

      let response = await apiServices.postFromApi(
        data.end_point,
        payload,
        "payoutHistory"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);


export const orderSlice = createSlice({
  name: "payouts",
  initialState,
  reducers: {
    setSelectedpayout: (state: PayoutState, action: any) => {
      if (state.selected_customer) {
        state.selected_customer = action.payload;
      } else {
        state.selected_customer = {};
      }
    },
    clearAllpayout: (state: PayoutState) => {
      state.all_payout = [];
      state.all_payout_data = [];
    },
   
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(allPayouts.pending, (state: PayoutState, { payload }) => {
        state.activity = true;
      })
      .addCase(allPayouts.fulfilled, (state, { payload }) => {
        try {
          let payouts = [] as any;
          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];

            payouts.push([
              // eslint-disable-next-line react-hooks/exhaustive-deps

              // {
              //   ui: (
              //     <div
              //       onClick={(event: any) => {
              //         // state?.payout_order_id=Number(item?.id)??NaN,

              //         // event.preventDefault()
              //         // event.stopPropagation();
              //         alert("BHAR");
              //       }}
              //     >
              //       {`${item?.id ?? "-"}`}
              //     </div>
              //   ),
              // },
              // eslint-disable-next-line

              item?.id ?? "-",
              item?.created_at
                ? moment(
                    `${item?.created_at}`.endsWith("Z")
                      ? item?.created_at
                      : item?.created_at + "Z"
                  ).format("DD/MM/YYYY HH:mm")
                : "- - -",
              item?.name ?? "-",

              item?.delivery_date
                ? moment(
                    `${item?.delivery_date}`.endsWith("Z")
                      ? item?.delivery_date
                      : item?.delivery_date + "Z"
                  ).format("DD/MM/YYYY HH:mm")
                : "- - -",

              // capitalizeFirstWord(item?.recource_type || "- - -"),
              item?.recource_type ? key_to_label[item?.recource_type] : "- - -",

              item?.service_method
                ? key_to_label[item?.service_method]
                : "- - -",

              // item?.total_price || "- - -",

              `€ ${numberFormatWithoutCurrency(item?.total_price ?? 0)}` || "0",

              `€ ${numberFormatWithoutCurrency(
                Number(item?.total_price) -
                  Number(item?.commission_amount + item?.tax_amount)
              )}`,
                          // item?.delivery_fee || "- - -",

              // item?.commission_amount || "- - -",

              `€ ${numberFormatWithoutCurrency(
                item?.commission_amount ?? 0
              )}` || "0",
              // item?.tax_amount || "- - -",

              `€ ${numberFormatWithoutCurrency(item?.tax_amount ?? 0)}` || "0",
              // item?.tax_amount || "- - -",
              // eslint-disable-next-line
              // { ui: <Button value={"Pay"} /> },

              { name: item?.resource_id, hidden: true },
            ]);
          }
          state.all_payout = payouts;
          state.all_payout_data = payload?.data;
          state.all_payout_paginate_data = {
            current_count: payload?.current_count,
            current_page: payload?.current_page,
            previous_count: payload?.previous_count,
            total_pages: payload?.total_pages,
            total_count: payload?.total_count,
          };
          state.activity = false;
        } catch (error) {
          console.log(error)
        }
      })
      .addCase(allPayoutsHist.pending, (state: PayoutState, { payload }) => {
        state.activity = true;
      })
      .addCase(allPayoutsHist.fulfilled, (state, { payload }) => {
        let payouts = [] as any;
        
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data[index];
          payouts.push([
            // eslint-disable-next-line
            item?.payout_id ?? "-",
            item?.created_at
              ? moment(
                  `${item?.created_at}`.endsWith("Z")
                    ? item?.created_at
                    : item?.created_at + "Z"
                ).format("DD/MM/YYYY HH:mm")
              : "- - -",

            capitalizeFirstWord(item?.resource_name || "- - -"),
            item?.recource_type ? key_to_label[item?.recource_type] : "-",
            // item?.service_method || "- - -",
            item?.total_orders ?? 0,
            `€ ${numberFormatWithoutCurrency(item?.amount_paid ?? 0)}` || "0",
            item?.added_by ?? "- - -",
            // eslint-disable-next-line
          ]);
        }
        state.all_payout_hist = payouts;
        state.all_payout_data_hist = payload?.data;
        state.all_payout_hist_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      });
  },
});

export const { setSelectedpayout, clearAllpayout } = orderSlice.actions;

export default orderSlice.reducer;
